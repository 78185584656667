import styled from "styled-components";
import { StatisticItem } from "./StatisticItem";
import { Title } from "./Title";
import likeIcon from '../../assets/icons/like-filled.svg';
import dislikeIcon from '../../assets/icons/dislike.svg';
import bookMarkIcon from '../../assets/icons/bookmark.svg';
import { About } from "./About";

export const ProfileStatisticCard = ({ likes, dislikes, favorites, onOpenAboutStatusModal, isCurrentUser }) => (
  <StyledProfileStatisticCard>
    <Title />
    <StyledContent>
      <StatisticItem number={likes ?? 0} icon={likeIcon} />
      <StatisticItem number={dislikes ?? 0} icon={dislikeIcon} />
      <StatisticItem number={favorites ?? 0} icon={bookMarkIcon} />
    </StyledContent>
    {isCurrentUser && <About onOpenAboutStatusModal={onOpenAboutStatusModal} />}
  </StyledProfileStatisticCard>
)

const StyledProfileStatisticCard = styled.div`
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(153px);
    border-radius: 10px;
    padding: 20px 30px 24px 20px;
    display: flex;
    flex-direction: column;
    @media(min-width: 768px) {
        padding: 20px 35px 24px 35px;
        justify-content: space-between;
    }
`;

const StyledContent = styled.div`
    display: flex;
    justify-content: space-between;
    @media(min-width: 768px) {
        flex-direction: column;
    }
`;