import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { ChangePassword } from "../components/ChangePassword/ChangePassword";
import { Login } from "../components/Login/Login";
import { Logo } from "../components/Logo/Logo";
import { Registration } from "../components/Registration/Registration";
import { ResetPassword } from "../components/ResetPassword/ResetPassword";
import SEO from "../helpers/SEO";

export const Autorization = ({ onPopupMessage }) => {
  const { authType } = useParams();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  // if user uthorized go to dashboard
  useEffect(() => {
    token && navigate('/');
    // eslint-disable-next-line
  }, [])

  // go to dashboard on press esc button
  const handleCloseAuth = (e) => {
    e.key === "Escape" && navigate('/')
  }
  useEffect(() => {
    window.addEventListener('keydown', handleCloseAuth, false);
    return () => window.removeEventListener('keydown', handleCloseAuth, false);
  })

  return (
    <>
      <SEO />
      <StyledAutorization>
        {
          !token &&
          <>
            <Logo classes="logo" />
            <StyledContent>
              {(() => {
                switch (authType) {
                  case 'registration': return <Registration onPopupMessage={onPopupMessage} />;
                  case 'forgot-password': return <ResetPassword />;
                  case 'changePassword': return <ChangePassword />;
                  default: return <Login onSuccessAuth={onPopupMessage} />;
                }
              })()}
            </StyledContent>
          </>
        }
      </StyledAutorization >
    </>
  )
}

const StyledAutorization = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .logo {
    margin: 60px 0 60px;
  }
  @media (min-width: 480) {
    .logo {
      margin: 60px 0 54px;
    }
  @media (min-width: 768px) {
    .logo {
      margin: 60px 0 65px;
    }
  }
  }
`;


const StyledContent = styled.div`
  width: 90%;
  display: flex;
  @media (min-width: 360px) {
    width: 320px;
  }
  @media (min-width: 480px) {
    width: 340px;
  }
`;