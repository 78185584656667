import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Author = ({ name, userId }) => (
    <NavLink to={`/profile/${userId}`}>
        <StyledAuthor>
            {name}
        </StyledAuthor>
    </NavLink>
)

const StyledAuthor = styled.div`
    position: absolute;
    bottom: 40px;
    background:#2b2b2b;;
    border: 1px solid #393939;
    backdrop-filter: blur(153px);
    border-radius: 10px;
    padding: 16px 24px;
    transition: all .5s;
    transform: translateY(10px);
    visibility: 'hidden';
    opacity: 0;
    z-index: -10;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 130%;
    text-align: right;
    color: #7DD060;
`;