import axios from "axios"
import { useQueryClient } from "react-query";
import { baseUrl } from "../../constants/api"
import { USER } from "../../constants/types";

export const useLoginByTelegramData = () => {
    const client = useQueryClient();
    const loginByTelegram = (data, noLogin) => {
        return axios.post(`${baseUrl}/api/auth/telegram-login`, data)
            .then(resp => {
              if(!noLogin) {
                const userData = resp?.data?.response?.user;
                const token = resp?.data?.response?.access_token;
                const userId = btoa(userData?.id);

                localStorage.setItem('CUID', userId);
                localStorage.setItem('token', token)
                client.setQueriesData(USER, userData);
              }
                return resp;
            })
            .catch(error => error.response)
    }

    return { loginByTelegram }
}