import styled from "styled-components";
import { getUserColor } from "../../helpers/user";


export const Title = ({ likes, profileRole }) => (
  <StyledTitle color={getUserColor(profileRole, Number(likes))}>
    <span>Всего лайков – </span>
    <span className="likes-number">{likes}</span>
  </StyledTitle>
)

const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  color: #F4F7A1;
  margin-bottom: 20px;
  span {
    font-family: 'Montserrat' !important;
  }
  .likes-number {
    color: ${props => props.color ?? "#8D69F2"};
  }
  @media(min-width: 480px) {
    font-size: 32px;
  }
`;