import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { ADMIN } from "../../constants/roles";
import { useUser } from "../../hooks/user/useUser";
import { SideBar } from "../SideBar/SideBar";
import { AddButton } from "./AddButton";
import { LoginButton } from "./LoginButton";
import { Logo } from "./Logo";
import { Navigation } from "./Navigation";
import { RandomTerm } from "./RandomTerm";
import { Search } from "./Search";

export const Header = ({ onPopupMessage }) => {
  const navigate = useNavigate();
  const location = useLocation();
  // user data for check is login
  const { user } = useUser();
  const token = localStorage.getItem('token');
  const isDashboard = location.pathname === '/';
  const [fixed, setFixed] = useState(false);
  const [loader, setLoader] = useState(false);


  const handleCheckAuth = (func) => {
    if (user || token) {
      func && func();
    } else {
      const currentPathName = window.location.href.split('#')[1];
      localStorage.setItem('prevUrl', currentPathName);
      navigate('/auth/registration');
      onPopupMessage('Чтобы добавить пост, нужно зарегистрироваться', true);
    }
  }
  const handleToggleCreatingTerm = () => navigate(user?.role_id === ADMIN ? '/create' : '/create-term');

  const handleScroll = (e) => {
    const topOffset = window.pageYOffset || document.documentElement.scrollTop;
    setFixed(topOffset > 5)
  }

  useEffect(() => {

    if (window.location.pathname === '/') {
      setLoader(true)
    }

    window.addEventListener("scroll", handleScroll, false);
    return () => window.addEventListener("scroll", handleScroll);
  }, [])

  return (
    <>
      <StyledHeader fixed={fixed}>
        <div className="container">
          <StyledTop fixed={fixed}>
            <Logo />
            <Navigation />
            <StyledButtons>
              <RandomTerm isDashboard={isDashboard} />
              <AddButton onCreate={() => handleCheckAuth(handleToggleCreatingTerm)} />
              <LoginButton />
            </StyledButtons>
            <SideBar onAuthCheck={handleCheckAuth} />
          </StyledTop>
          <Search />
        </div>
      </StyledHeader>
      <StyledDivider />
    </>
  )
}


const StyledHeader = styled.div`
  position: fixed;
  background: ${props => props.fixed && "linear-gradient(180deg, #232323 50%, rgba(0,0,0,0) 100%)"};
  z-index: 10;
  width: 100%;
  padding: 0 0 40px;
`;


const StyledTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 20px 20px;
    @media (min-width: 480px) {
        padding: 25px 21px 16px;
    }
    @media (min-width: 768px) {
        padding: 25px 0px 24px;
    }
    @media (min-width: 870px) {
        justify-content: space-between;
    }
    @media (min-width: 1000px) {
        padding: 10px 0px 19px;
    }
    @media (min-width: 1200px) {
        padding: 20px 0px 19px;
    }
`;

const StyledButtons = styled.div`
    display: none;
    align-items: center;
    @media (min-width: 870px) {
        display: flex;
    }
`;

const StyledDivider = styled.div`
  margin-bottom: 160px;
`;
