import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import searchIcon from '../../assets/icons/search.svg';
import { useSearch } from "../../hooks/search/useSearch";
import { UserIcon } from "./UserIcon";

export const Search = () => {
  const { search } = useSearch();
  const location = useLocation();
  const navigate = useNavigate();
  // search input
  const [searching, setSearching] = useState();
  // check input is focus
  // eslint-disable-next-line
  const [searchFocus, setSearchFocus] = useState(false);
  // found by search terms
  const [results, setResults] = useState([]);
  // selected result item
  const [selectedResult, setSelectedResult] = useState(-1);

  // change search input value
  const handleChangeSearchingInput = (e) => {
    setSearching(e.target.value);
    setSelectedResult(-1);
  }

  // serching terms and set it to foundTerms
  const handleSearching = async () => {
    const resp = await search(searching);
    setResults(resp);
  }

  // reset search values
  const handleReset = () => {
    setSearching('');
    setResults([]);
  }

  // on change search input serching terms
  useEffect(() => {
    handleSearching();
    // eslint-disable-next-line
  }, [searching])

  const handleGoToResult = () => {
    if (searching.length > 0) {
      // if selected result item - select index, or first item
      const selectedResultIndex = selectedResult !== -1 ? selectedResult : 0;
      const selectedItem = results[selectedResultIndex];
      const url = selectedItem ? `${selectedItem?.type === 1 ? `/profile/${selectedItem.slug}` : `/opredelenie-slova/${selectedItem.slug}`}` : `not-found/${searching}`;
      navigate(url);
      handleReset();
    }
  }

  // on press btn 
  const handleGoToTerm = (e) => {
    const keyCode = e.code;
    if (keyCode === 'Enter') {
      handleGoToResult()
    } else if (keyCode === 'ArrowDown') {
      setSelectedResult(selectedResult >= results.length - 1 ? selectedResult : selectedResult + 1)
    } else if (keyCode === 'ArrowUp') {
      setSelectedResult(selectedResult === -1 ? -1 : selectedResult - 1)
    }
  };

  // close search on change page
  useEffect(() => {
    setSearchFocus(false);
  }, [location])

  // scroll into selected item view
  useEffect(() => {
    if (selectedResult !== -1) {
      const item = document.querySelector(`.search-result-item-${selectedResult}`)
      item.scrollIntoView({ block: "center", behavior: "smooth" })
    }
  }, [selectedResult])


  return (
    <StyledSearch>
      <input
        placeholder="Поиск"
        value={searching}
        onChange={handleChangeSearchingInput}
        onFocus={() => setSearchFocus(true)}
        onBlur={() => setSearchFocus(false)}
        onKeyDown={(e) => handleGoToTerm(e)}
      />
      <img
        src={searchIcon}
        alt="search icon"
        onClick={handleGoToResult}
      />
      <StyledFoundTerms
        visible={results?.length > 0 && true}
        selectedResult={selectedResult}
      >
        {
          results?.length > 0 &&
          results.map((result, i) => (
            <NavLink
              to={result?.type === 1 ? `/profile/${result.slug}` : `/opredelenie-slova/${result.slug}`}
              key={i}
              onClick={handleReset}
            >
              <StyledResultItem
                isSelected={selectedResult === i}
                className={`search-result-item-${i}`}
              >
                {result?.value}
                {result?.type === 1 && <UserIcon />}
              </StyledResultItem>
            </NavLink>
          ))
        }
      </StyledFoundTerms>
    </StyledSearch>
  )
}

const StyledSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.52);
  border-radius: 10px;
  width: 92%;
  margin: 0 auto;
  position: relative;
  img {
    margin: 17px;
    cursor: pointer;
  }
  input {
    width: 80%;
    height: 100%;
    border: none;
    outline: none !important;
    padding-left: 20px;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    border-radius: 10px 0 0 10px;
  }
  input::placeholder {
    color: #C2C2C2;
  }
  @media (min-width: 480px) {
    width: 100%;
  }
  @media (min-width: 768px) {
    img {
      height: 24px;
      margin: 20px 23px 16px;
    }
    input {
      padding-left: 40px;
    }
    }
    @media (min-width: 1000px) {
      img {
        height: 16px;
        margin: 18px 27px 18px ;
      }
    }
`;

const StyledFoundTerms = styled.div`
  position: absolute;
  background: #4D4A48F0;
  border: 1px solid #4D4A48;
  width: 100%;
  top: 55px;
  max-height: 40vh;
  z-index: 10;
  border-radius: 10px;
  color: #232323;
  overflow: hidden;
  transition: all .5s;
  visibility: ${props => props.visible ? 'visible' : 'hidden'};
  overflow-y: auto;
  opacity: ${props => props.visible ? '1' : '0'};
`;

const StyledResultItem = styled.div`
  padding: 10px;
  cursor: pointer;
  color: #D5D2D0;
  transition: all .5s;
  svg {
    margin-left: 5px;
  }
  svg,path {
    fill: #D5D2D0;
  }
  &:hover {
    background:  #f4f7a1;
    color: #232323;
    svg,path {
      fill: #232323;
    }
  }
  ${props => props.isSelected && `
    background:  #f4f7a1;
    color: #232323;
    svg,path {
      fill: #232323;
    }
  `}
`;