import styled from "styled-components";
import { Card } from "./Card";
import logoIcon from "../../assets/icons/logo.png";
import { BackgroundGradient } from "./BackgroundGradient";

export const TermCard = ({ title, descripton, owner, ownerLikesCount, mainCard }) => (
  <StyledTermCard>
    <Card
      title={title}
      descripton={descripton}
      owner={owner}
      ownerLikesCount={ownerLikesCount}
    />
    {
      mainCard &&
      <>
        <div className="siteLink">speach.uk</div>
        <img className="logo" src={logoIcon} alt="logo" />
      </>
    }
    <BackgroundGradient mainCard={mainCard} />
  </StyledTermCard >
)


const StyledTermCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 250px;
  max-height: 100%;
  width: 250px;
  background: #232323;
  padding: 13px 12px 15px;
  overflow: hidden;
  .siteLink {
    font-weight: 400;
    font-size: 8.33333px;
    line-height: 130%;
    color: #FFFFFF;
    opacity: 0.5;
    margin-top: 1px;
    margin-left: auto;
  }
  .logo {
    position: absolute;
    bottom: 5px;
    left: 5px;
    width: 49px;
    opacity: 0.2;
  }
  @media(min-width: 480px) {
    width: 204px;
    min-height: 204px;
    max-height: 100%;
    padding: 11px 15px;
  }
  @media(min-width: 768px) {
    width: 250px;
    height: 250px;
    padding: 18px 18px 21px;
  }
`;