import { useState } from "react";
import styled from "styled-components";
import { AcceptButton } from "./AcceptButton";
import { CancelButton } from "./CancelButton";
import { Cookie } from "./Cookie";
import { Description } from "./Description";

export const CookiesModal = () => {
  const [visible, setVisible] = useState(!localStorage.getItem('acceptedCookie'));
  const deviceData = navigator.userAgent.toLowerCase();
  const isAndroid = deviceData.indexOf("android") > -1;

  const handleAcceptingCookies = () => {
    localStorage.setItem('acceptedCookie', 'true');
    setVisible(false);
  };

  return (
    <StyledCookiesModal visible={visible} isAndroid={isAndroid}>
      <Cookie />
      <Description />
      <AcceptButton onAccept={handleAcceptingCookies} />
      <CancelButton onCancel={handleAcceptingCookies} />
    </StyledCookiesModal>
  )
}

const StyledCookiesModal = styled.div`
    position: fixed;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%) translateY( ${props => props.visible ? '0' : '100px'});
    z-index: 100;
    min-height: 50px;
    background: ${props => props.isAndroid ? '#4D4A48F0' : 'rgba(255, 255, 255, 0.03)'};
    border: 1px solid #4D4A48;
    backdrop-filter: blur(247px);
    border-radius: 24px;
    padding: 10px;
    display: flex;
    align-items: center;
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    opacity: ${props => props.visible ? '1' : '0'};
    transition: all .5s;
    max-width: 90%;
    @-moz-document url-prefix() {
        background: #4D4A48F0;
    }
    @media (min-width: 600px) {
        margin: 0 0 0 18px;
        min-height: 111px;
        padding: 24px;
    }
`;
