import styled from "styled-components";
import { MY_TERMS, FAVORITES, SUGGESTIONS, MODERATORS, ARTICLES, BAGS, COMPLAINS, RECOMENDATIONS } from "../../constants/profileTermsSection";
import { useLogout } from "../../hooks/auth/useLogout";
import { Step1 } from "../InfoSteps/Step1";

export const Footer = ({
  activeSection,
  onSectionChange,
  moderator,
  admin,
  infoStep,
  onNextInfoStep
}) => {
  const { logout } = useLogout();

  return (
    <StyledFooter className="not-select">
      <StyledItem
        active={activeSection === MY_TERMS}
        onClick={() => onSectionChange(MY_TERMS)}
      >
        Мои посты
      </StyledItem>
      {
        admin ?
          <StyledItem
            active={activeSection === ARTICLES}
            onClick={() => onSectionChange(ARTICLES)}
          >
            статьи
          </StyledItem>
          : null
      }
      <StyledItem
        active={activeSection === FAVORITES}
        onClick={() => onSectionChange(FAVORITES)}
      >
        Сохраненное
      </StyledItem>
      {
        (moderator || admin) &&
        <StyledItem
          active={activeSection === SUGGESTIONS}
          onClick={() => onSectionChange(SUGGESTIONS)}
          className={infoStep === 1 && "info-step-1"}
          id="step-1"
        >
          ПРЕДЛОЖКА
          <Step1 visible={infoStep === 1} onNextInfoStep={onNextInfoStep} />
        </StyledItem>
      }
      {
        admin &&
        <StyledItem
          active={activeSection === MODERATORS}
          onClick={() => onSectionChange(MODERATORS)}
          id="step-1"
        >
          МОДЕРАТОРЫ
        </StyledItem>
      }
      {
        admin &&
        <StyledItem
          active={activeSection === BAGS}
          onClick={() => onSectionChange(BAGS)}
        >
          Баги
        </StyledItem>
      }
      {
        admin &&
        <StyledItem
          active={activeSection === COMPLAINS}
          onClick={() => onSectionChange(COMPLAINS)}
        >
          Жалобы
        </StyledItem>
      }
      {
        admin &&
        <StyledItem
          active={activeSection === RECOMENDATIONS}
          onClick={() => onSectionChange(RECOMENDATIONS)}
        >
          Рекомендации
        </StyledItem>
      }
      <StyledItem onClick={logout}            >
        Выход
      </StyledItem>

    </StyledFooter>
  )
}

const StyledFooter = styled.div`
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

const StyledItem = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 130%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin: 0 10px 20px 0;
  cursor: pointer;
  transition: all .5s;
  position: relative;
  color: ${props => props.active ? '#FFFFFF' : '#F28569'};
  &:hover {
    color: #FFFFFF;
  }
  &:last-child {
    margin: 0 0 20px;
  }
  @media(min-width: 360px) {
    font-size: 13px;
  }
  @media(min-width: 480px) {
    font-size: 15px;
    margin: 0 20px 0 0;
    &:last-child {
      margin: 0px;
    }
  }
`;