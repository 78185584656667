import styled from "styled-components";

export const Button = ({ disabled, onCreateLink }) => (
    <StyledButton
        disabled={disabled}
        onClick={() => !disabled && onCreateLink()}
    >
        Добавить
    </StyledButton>
)

const StyledButton = styled.button`
    height: 50px;
    transition: all .5s;
    padding: ${props => props.disabled ? '0px' : '5px 10px'};
    width: ${props => props.disabled ? '0px' : '150px'};
    visibility: ${props => props.disabled ? 'hidden' : 'visible'};
    opacity: ${props => props.disabled ? '0' : '1'};
    overflow: hidden;
    outline: none !important;
    border: none !important;
    cursor: pointer;
    background: #F28569;
    border-radius:  0 10px 10px 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    letter-spacing: 0.04em;
    color: #FFFFFF; 
    text-align: center;
`;