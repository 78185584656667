import styled from "styled-components";
import { useDeleteUser } from "../../hooks/user/useDeleteUser";
import { Button } from "./Button";
import { CloseIcon } from "./CloseIcon";
import { Title } from "./Title";

export const WhatRegistration = ({ visible, onClose, onPopupMessage }) => {
    const { deleteUser } = useDeleteUser();
    const handleDeleteUser = () => {
        const href = window.location.href;
        const hash = href?.split('?')[1]?.split('=')[1];
        if (hash) {
            deleteUser({ hash });
            onClose();
            onPopupMessage("Email-адрес удален из базы");
        }
    }
    return (
        <StyledWhatRegistrationWrapper visible={visible}>
            <StyledModal>
                <CloseIcon onClose={onClose} />
                <Title />
                <Button onClick={handleDeleteUser} />
            </StyledModal>
        </StyledWhatRegistrationWrapper>
    )
}

const StyledWhatRegistrationWrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(35, 35, 35, 0.94);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .5s;
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    opacity: ${props => props.visible ? '1' : '0'};
    .select{
        margin: 16px 0;
    }
    .input-desription {
        margin-bottom: 16px;
        height: 170px;
    }
    .input-example {
        margin-bottom: 18px;
        textarea {
            height: 95px;
            font-style: italic;
        }
    }
`;

const StyledModal = styled.div`
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid #4D4A48;
    backdrop-filter: blur(236px);
    border-radius: 24px;
    padding: 30px;
    box-sizing: content-box;
    width: 70vw;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 80%;
    position: relative;
    .input {
        textarea {
            height: 239px;
        }
    }
    @media(min-width: 660px) {
        width: 340px;
        max-height: 80vh;
    }
`;
