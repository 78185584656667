export const getDate = (date) => {
    const months = [
        'января', 'февраля', 'марта', 'апреля',
        'мая', 'июня', 'июля', 'августа',
        'сентября', 'октября', 'ноября', 'декабря'
    ];
    const parsedDate = new Date(date);
    const monthDate = parsedDate.getDate();
    const month = months[parsedDate.getMonth()];
    const year = parsedDate.getFullYear();

    return `${monthDate < 10 ? `0${monthDate}` : monthDate} ${month} ${year}`
}