import styled from "styled-components";
import closeIcon from '../../assets/icons/closeGold.svg';

export const DeleteButton = ({ onDelete }) => (
  <StyledDeleteButton
    onClick={onDelete}
    src={closeIcon}
    alt="delete termin to determine"
  />
)

const StyledDeleteButton = styled.img`
  cursor: pointer;
  margin-right: 15px;
`;