import { useModerators } from "../../hooks/admin/useModerators";
import { Spinner } from "../Spinner/Spinner";
import { EmptyMessage } from "./EmptyMessage";
import { ModeratorCard } from "./ModeratorCard";

export const ModeratorsTable = () => {
  const { moderators } = useModerators();
  return (
    <div>
      {
        moderators
          ? <>
            {
              moderators?.length > 0
                ? <>
                  {
                    moderators.map((moderator, i) => (
                      <ModeratorCard
                        slug={moderator?.slug}
                        key={i}
                        login={moderator?.login}
                        username={moderator?.username}
                      />
                    ))
                  }
                </>
                : <EmptyMessage />
            }
          </>
          : <Spinner />
      }
    </div>
  )
}
