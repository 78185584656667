import { useState } from "react";
import styled from "styled-components";
import { Menu } from ".//Menu";
import { Button } from "./Button";


export const Dropdown = ({ options, classes }) => {
    const [active, setActive] = useState(false);
    const toggleActive = () => setActive(!active);

    return (
        <StyledDropdown className={classes}>
            <Button active={active} onToggle={toggleActive} />
            <Menu
                visible={active}
                options={options}
                onClose={toggleActive}
            />
        </StyledDropdown>
    )
}

const StyledDropdown = styled.div`
    position: relative;
`;