import axios from "axios";
import { useQueryClient } from "react-query";
import { baseUrl, headers } from "../../constants/api";
import { TERMS_TO_DETERMINE } from "../../constants/types";

export const useDeleteTermToDetermine = () => {
    const client = useQueryClient();

    const handleDeleteTermToDetermine = async ({ termId }) => {
        const resp = await axios.delete(`${baseUrl}/api/admin/delete-term-determine`, {
            headers: headers(),
            params: { term_id: termId }
        })

        if (resp?.status === 200) {
            let termsToDetermineData = client.getQueryData(TERMS_TO_DETERMINE);
            let updatedTermsToDetermineData = termsToDetermineData;
            updatedTermsToDetermineData = updatedTermsToDetermineData.filter(t => t.id !== termId);
            client.setQueryData(TERMS_TO_DETERMINE, updatedTermsToDetermineData);
            return resp;
        }
    }

    return { deleteTermToDetermine: handleDeleteTermToDetermine }
}