import styled from "styled-components";

export const Description = () => (
    <StyledDescription>
        Этот сайт использует
        <a href="/#/privacy-policy" target="_blank">
            cookies
        </a>
        <br />
        для хранения данных.
    </StyledDescription>
)

const StyledDescription = styled.div`
    font-weight: 600;
    font-size: 8px;
    line-height: 130%;
    color: #FFFFFF;
    margin: 0 10px 0 0;
    text-align: left;
    a {
        color: #FFFFFF;
        text-decoration: underline;
        margin-left: 5px;
    }
    @media (min-width: 350px) {
        font-size: 10px;
    }
    @media (min-width: 600px) {
        font-size: 17px;
        margin: 0 15px 0 0;
    }
`;