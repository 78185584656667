import styled from "styled-components";

export const Number = ({ number = 0 }) => (
    <StyledNumber>
        {`${number > 150 ? `150+` : number} Терминов`}
    </StyledNumber>
)

const StyledNumber = styled.span`
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: #989897;
    transition: all .5s;
    @media(min-width: 768px) {
        font-size: 24px;
    }
`;