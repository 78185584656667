import axios from "axios"
import { baseUrl } from "../../constants/api"

export const useSearch = () => {
    const handleSearch = async (text) => {
        const resp = await axios.get(`${baseUrl}/api/search`, {
            params: { q: text }
        })

        return resp?.data?.response?.data ?? []
    }

    return { search: handleSearch }
}