import styled from "styled-components";
import closeIcon from "../../assets/icons/close.svg";
import { EditArticle } from "../EditArticle/EditArticle";
import { useState } from "react";
import { useDeleteArticle } from "../../hooks/article/useDeleteArticle";
import { IsSafari } from "../../helpers/checkDevice";

export const ArticleCard = ({ id, image, description, link, styles, noClose, onPopupMessage }) => {
  const [edit, setEdit] = useState(false);
  const { deleteArticle } = useDeleteArticle();

  const handleDeleteCard = () => {
    deleteArticle({ articleId: id })
  }

  const handleClick = (e) => {
    const clickedElement = e.target.classList[0];
    if (clickedElement === 'close') {
      handleDeleteCard();
    } else if (clickedElement === 'description') {
      setEdit(true);
    } else {
      window.open(link, '_blank');
    }
  }

  return (
    <>
      {
        edit &&
        <EditArticle
          id={id}
          initImage={image}
          initDescription={description}
          initLink={link}
          onClose={() => setEdit(false)}
          onPopupMessage={onPopupMessage}
        />
      }
      <StyledArticleCard
        image={image}
        className={styles}
        onClick={handleClick}
        isSafari={!IsSafari()}
      >
        {
          !noClose &&
          <img
            src={closeIcon}
            alt=""
            className="close"
          />
        }
        <span className="description">{description}</span>
      </StyledArticleCard>
    </>
  )
}
const StyledArticleCard = styled.div`
  display: block;
  background:${props => !props.image && '#f4f7a168'};
  background-image: linear-gradient(180deg, rgba(35, 35, 35, 0) 0%, #232323 100%), url(${props => props.image});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  padding: 9px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  word-break: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 130%;
  color: #FFFFFF;
  cursor: pointer;
  opacity: 0.75;
  transition: ${props => props.isSafari ? "none" : "all .3s"};
  img {
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
    padding: 14px;
    z-index: 15;
  } 
  span {
    transition: ${props => props.isSafari ? "none" : "all .3s"};
  }
  &:hover {
    opacity: 1;
    span {
      transform: translateY(-5px) translate3d(0, 0, 0);
    }
  }
`;  