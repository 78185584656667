import styled from "styled-components";
import { Title } from "./Title";
import { Description } from "./Description";
import { StatusCard } from "./StatusCard";
import { Close } from "./Close";

export const Modal = ({ onClose, likes, profileRole }) => (
  <StyledModal>
    <Close onClose={onClose} />
    <Title likes={likes} profileRole={profileRole} />
    <Description />
    <StatusCard likeNumber="0-19" />
    <StatusCard likeNumber="20-99" color="color-1" />
    <StatusCard likeNumber="100-499" color="color-2" />
    <StatusCard likeNumber="500-999" color="color-3" />
    <StatusCard likeNumber="1000-1999" color="color-4" />
    <StatusCard likeNumber="2000+" color="color-5" />
    <StatusCard
      likeNumber="Модератор"
      color="moderator-color"
      info='Модератор, помимо стандартных функций, имеет возможность учавствовать в голосованиях по одобрению/отклонению свеже-предложенных постов, а также добавлять термины в категорию "нужно определить" и прочие плюшки. Получить роль модератора вы можете путём создания портфолио из качественнейших постов; тогда админ оценит ваш вклад в развитие Speach и вышлет приглашение на новую роль.'
    />
  </StyledModal>
)

const StyledModal = styled.div`
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid #4D4A48;
  border-radius: 24px;
  padding: 30px 20px;
  width: 90%;
  max-width: 640px;
  position: relative;
  height: max-content;
  @media(min-width:768px) {
    padding: 30px 40px;
  }
`;
