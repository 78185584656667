import axios from "axios";
import { useQuery } from "react-query";
import { baseUrl, headers } from "../../constants/api";
import { MODERATORS } from "../../constants/types";

export const useModerators = () => {
    const { data: moderators = null } = useQuery(
        [MODERATORS],
        async () => (await axios.get(`${baseUrl}/api/admin/get-moderators`, {
            headers: headers(),
        }))?.data?.response?.data,
    );

    return { moderators }
}
