import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useToggleFavoriteTerm } from "../../hooks/term/useToggleFavoriteTerm";
import { useUser } from "../../hooks/user/useUser";

export const Bookmark = ({
  classes,
  termId,
  isFavorite,
  onTermRemove,
  currentUserId,
  onPopupMessage,
  onRefreshData
}) => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const { toggleFavoriteTerm } = useToggleFavoriteTerm();
  // user data for check is login
  const { user } = useUser();
  const token = localStorage.getItem('token');

  // toggle favorite
  const handleFavoriteTerm = async () => {
    // checking is login if not redirect to login page
    if (user || token) {
      const resp = await toggleFavoriteTerm(termId);
      onRefreshData && onRefreshData();
      if ((resp === 'success') && (currentUserId === Number(userId))) {
        onTermRemove();
      }
    } else {
      const currentPathName = window.location.href.split('#')[1];
      localStorage.setItem('prevUrl', currentPathName);
      navigate('/auth/login');
      onPopupMessage('Для сохранения поста нужна аутентификация')
    }
  };

  return (
    <StyledBookmark
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="25"
      viewBox="0 0 20 25"
      fill="none"
      onClick={handleFavoriteTerm}
      active={isFavorite}
      className={classes}
    >
      <path
        d="M20 25L10 18.0556L0 25V2.77778C0 2.04107 0.301019 1.33453 0.836838 0.813592C1.37266 0.292658 2.09938 0 2.85714 0H17.1429C17.9006 0 18.6273 0.292658 19.1632 0.813592C19.699 1.33453 20 2.04107 20 2.77778V25Z"
        fill="#F4F7A1"
      />
    </StyledBookmark>
  )
}


const StyledBookmark = styled.svg`
  transition: all .5s;
  cursor: pointer;
  opacity: ${props => props.active ? 1 : 0.11};
`;