import axios from "axios";
import { useQuery } from "react-query";
import { baseUrl, headers } from "../../constants/api";
import { ARTICLES } from "../../constants/types";

export const useArticles = () => {
    const { data: articles = [] } = useQuery(
        [ARTICLES],
        async () => (await axios.get(`${baseUrl}/api/articles/get-articles`, {headers: headers()})
        )?.data?.response?.data
    );
    
    return { articles }
}

