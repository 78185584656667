import { useState } from "react";
import styled from "styled-components";
import { Label } from "./Label";
import { ShowPassword } from "./ShowPassword";
import { MaxLength } from "./MaxLength";

export const Input = ({
    label,
    placeholder,
    value = '',
    onValueChange,
    error,
    disabled,
    viewOnly,
    classes,
    password,
    notShow,
    textarea,
    maxLength,
    onBlur,
    onFocus
}) => {
    const [showMore, setShowMore] = useState(false);
    const handleToggleShowMore = () => setShowMore(!showMore);

    return (
        <StyledInputWrapper
            className={classes}
            autocomplete="off"
            disabled={disabled}
        >
            <Label label={label} error={error} />
            <div className="wrapper">
                {
                    maxLength && !viewOnly &&
                    <MaxLength
                        maxLength={maxLength}
                        currentLength={value.length}
                    />
                }
                {
                    textarea
                        ? <StyledTextArea
                            placeholder={placeholder ?? ''}
                            value={value}
                            onChange={(e) => !disabled && onValueChange(e.target.value)}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            maxLength={maxLength ?? null}
                            viewOnly={viewOnly}
                            disabled={viewOnly}
                        />
                        : <StyledInput
                            type={(password && !showMore) ? 'password' : 'text'}
                            placeholder={placeholder ?? ''}
                            value={value}
                            onChange={(e) => !disabled && onValueChange(e.target.value)}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            error={error}
                            maxLength={maxLength ?? null}
                            viewOnly={viewOnly}
                            disabled={viewOnly}
                        />
                }
                {
                    password && !notShow &&
                    <ShowPassword
                        onToggleActive={handleToggleShowMore}
                        active={showMore}
                    />
                }
            </div>
        </StyledInputWrapper>
    )
}

const StyledInputWrapper = styled.div`
    width: 100%;
    ${props => props.disabled && `
        cursor: no-drop !important; 
        opacity: 0.3;
        input {
            cursor: no-drop !important; 
        }
    `}
    .wrapper {
        position: relative;
    }
`;

const StyledInput = styled.input`
    width: 100%;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.03) !important;
    border: 1px solid #4D4A48;
    border-radius: 10px;
    outline: none;
    transition: all .3s;
    padding: 14px 9px;
    font-weight: 400;
    font-size: 17px;
    line-height: 130%;
    color: ${props => props.error ? '#F28569' : '#FFFFFF'};
    ${props => props.error && 'border: 1px solid #F28569 !important;'}
    &::placeholder {
        font-weight: 400;
        font-size: 17px;
        line-height: 130%;
        color: rgba(255, 255, 255, 0.16);
    }
    &:focus {
        border: 1px solid #F4F7A1;
        ${props => props.viewOnly && 'border: 1px solid #4D4A48 !important;'}
    }
    ${props => props.viewOnly && 'cursor: default !important;'}
    @media (min-width: 360px) {
        padding: 14px 19px;
    }
`;

const StyledTextArea = styled.textarea`
    width: 100%;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.03) !important;
    border: 1px solid #4D4A48;
    border-radius: 10px;
    outline: none;
    transition: all .3s;
    padding: 14px 9px;
    font-weight: 400;
    font-size: 17px;
    line-height: 130%;
    color: ${props => props.error ? '#F28569' : '#FFFFFF'};
    ${props => props.error && 'border: 1px solid #F28569 !important;'}
    resize: none;
    &:focus {
        border: 1px solid #F4F7A1;
        ${props => props.viewOnly && 'border: 1px solid #4D4A48 !important;'}
    }
    &::placeholder {
        font-weight: 400;
        font-size: 17px;
        line-height: 130%;
        color: rgba(255, 255, 255, 0.16);
    }
    ${props => props.viewOnly && 'cursor: default !important;'}
    @media (min-width: 360px) {
        padding: 14px 19px;
    }
`;