import styled from "styled-components";
import closeIcon from '../../assets/icons/close.svg';

export const PopupModal = ({ visible, text, htmlText, onClose }) => (
  <StlyedPopupModal visible={visible}>
    <StyledModal>
      <img
        src={closeIcon}
        alt=""
        onClick={onClose}
      />
      {text || htmlText}
    </StyledModal>
  </StlyedPopupModal>
)

const StlyedPopupModal = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(35, 35, 35, 0.94);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .5s;
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    opacity: ${props => props.visible ? '1' : '0'};
`;

const StyledModal = styled.div`
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid #4D4A48;
    backdrop-filter: blur(236px);
    border-radius: 24px;
    padding: 28px 27px;
    font-weight: 700;
    font-size: 12px;
    line-height: 130%;
    color: #FFFFFF;
    position: relative;
    max-width: 400px;
    img {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
    }
    a {
        color: #F28569 !important;
    }
    @media(min-width: 360px){
        font-size: 17px;
    }
`;