import axios from "axios";
import { useQueryClient } from "react-query";
import { baseUrl, headers } from "../../constants/api";
import { PROFILE } from "../../constants/types";
import { checkIsDataExist } from "../../helpers/checkIsDataExist";

export const useBlockUser = () => {
  const client = useQueryClient();

  const handleBlockUser = ({ profileId, status }) => {
    axios.post(`${baseUrl}/api/admin/block_user`, null, {
      headers: headers(),
      params: { user_id: profileId, status }
    })
      .then(resp => {
        if (resp.status === 200) {
          const updatedUserData = resp?.data?.response?.user;
          // update user favorites terms
          let userData = client.getQueriesData(PROFILE);
          userData = checkIsDataExist(userData);
          if (userData) {
            let updateUserData = userData;
            updateUserData = { ...updateUserData, ...updatedUserData };
            client.setQueriesData(PROFILE, updateUserData);
          }
        }
      })
  }
  return { blockUser: handleBlockUser }
}