import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BackgroundGradient } from "../components/BackgroundGradient/BackgroundGradient";
import { Logo } from "../components/SideBar/Logo";
import { hideModalOnClickOutside } from "../helpers/hideModalOnClickOutside";
import SEO from "../helpers/SEO";

export const Create = () => {
  const navigate = useNavigate();
  const goingBack = useRef(false);

  const handleCloseCreateTerm = () => {
    if (!goingBack.current) {
      goingBack.current = true;
      navigate(-1);
    }
  }

  // cheking click outside modal 
  useEffect(() => {
    window.addEventListener("click", (e) => {
      hideModalOnClickOutside(e, "create-wrapper", handleCloseCreateTerm);
    }, false);

    return () => window.removeEventListener("click", (e) => {
      hideModalOnClickOutside(e, "create-wrapper", handleCloseCreateTerm);
    }, false);
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <SEO />
      <StyledCreate className="create-wrapper">
        <Logo />
        <div className="content-wrapper">
          <StyledNavigate onClick={() => navigate('/create-article')}>
            Статья
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="13"
              viewBox="0 0 20 13"
              fill="none"
            >
              <path
                d="M1 6.5H19M19 6.5L13.5 1M19 6.5L13.5 12"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </StyledNavigate>
          <StyledNavigate onClick={() => navigate('/create-term')}>
            Термин
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="13"
              viewBox="0 0 20 13"
              fill="none"
            >
              <path
                d="M1 6.5H19M19 6.5L13.5 1M19 6.5L13.5 12"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </StyledNavigate>
        </div>
        <BackgroundGradient />
      </StyledCreate>
    </>
  )
}

const StyledCreate = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #232323;
  height: 100vh;
  z-index: 1000;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 60px;
  overflow: scroll;
  transition: all .5s;
  width: 100%;
  overflow-x: hidden;
  .content-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20vh;
    gap: 16px;
  }
`;

const StyledNavigate = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 860px;
  width: 90vw;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(153px);
  border-radius: 10px;
  padding: 30px 40px;
  font-weight: 700;
  font-size: 32px;
  line-height: 130%;
  color: #FFFFFF;
  transition: all .3s;
  cursor: pointer;
  path {
    stroke: #FFFFFF;
  }
  &:hover {
    border: 1.5px solid #F4F7A1;
    color: #F4F7A1;
    path {
      stroke: #F4F7A1;
    }
  }
`;