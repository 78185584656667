import axios from "axios";
import { useQueryClient } from "react-query";
import { baseUrl, headers } from "../../constants/api";
import {  COMPLAINS } from "../../constants/types";

export const useRemoveComplaint = () => {
    const client = useQueryClient();

    const handleRemoveComplaint = async (id,successPopup) => {
        const resp = await axios.delete(`${baseUrl}/api/complaints/delete-complaint`, {
            headers: headers(),
            params: { сomplaint_id: id }
        })

        if (resp?.status === 200) {
          const complaintsData = client.getQueryData(COMPLAINS);
          if (complaintsData) {
              const updatedAllComplaintsData = complaintsData;
              updatedAllComplaintsData.data = updatedAllComplaintsData.data.filter(complaint => complaint.id !== id);
              client.setQueriesData(COMPLAINS, updatedAllComplaintsData);
              successPopup("Жалоба успешно удалена")
          }
            return resp;
        }
    }

    return { removeComplaint: handleRemoveComplaint}
}