import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Author } from "./Author";
import { DeleteButton } from "./DeleteButton";

export const Terms = ({ admin, termsToDetermine, onDelete }) => (
  <StyledTerms>
    {
      termsToDetermine?.length > 0 &&
      termsToDetermine.map((term, i) => (
        <span key={i}>
          {admin && <DeleteButton onDelete={() => onDelete(term?.id)} />}
          <NavLink to={`/create-term/${term?.title}`}>
            <span className="term-name">{term?.title}</span>
            {
              admin &&
              <Author
                name={term?.login}
                userId={term?.login}
              />
            }
          </NavLink>
        </span>
      ))
    }
  </StyledTerms>
)

const StyledTerms = styled.div`
  padding: 16px 39px 30px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-weight: 400;
  font-size: 17px;
  line-height: 130%;
  color: #D5D2D0;
  gap: 5px 0px;
  .term-name {
    position: relative;
  }
  span, a {
    transition: all .5s;
    cursor: pointer;
    position: relative;
    color: #D5D2D0;
    &:hover {
      color: #F4F7A1;
      div {
        visibility: visible;
        opacity: 1;
        transform: translateY(0px);
        z-index: 10;
      }   
    }
  }
  @media (min-width: 480px) {
    padding: 20px 89px 30px 20px;
    font-size: 20px;
  }
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 24px 20px 42px 43px;
    font-size: 24px;
    gap: 40px 0px;
  }
  @media (min-width: 1000px) {
    padding: 24px 84px 42px 43px;
    gap: 20px 0px;
  }
`;
