import styled from "styled-components";

export const Info = ({ visible, text }) => (
    <StyledInfo className="not-select" visible={visible}>
        {text}
    </StyledInfo>
)

const StyledInfo = styled.div`
    position: absolute;
    right: 0;
    font-size: 12px;
    color: #FFFFFF;
    background: #232323d6;
    border: 1px solid #4D4A48;
    border-radius: 10px;
    bottom: 20px;
    transform: translate(50px, -10px);
    padding: 10px;
    -webkit-box-shadow: 3px 1px 15px 1px rgba(0,0,0,0.1); 
    box-shadow: 3px 1px 15px 1px rgba(0,0,0,0.1);
    transition: all .3s;
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    opacity: ${props => props.visible ? '1' : '0'};
    -webkit-font-smoothing: always;
    @media(min-width: 480px) {
        background: #2323237a;
        transform: translate(131px, -10px);
    }
`;