import styled from "styled-components";
import closeIcon from '../../assets/icons/close.svg';
import { APPROVE, EXPECTED } from "../../constants/suggestionStatus";

export const ModerationInfo = ({ type, moderators, onClose }) => (
    <StyledModerationInfo type={type} visible={moderators?.length > 0}>
        <StyledCloseButton src={closeIcon} onClick={onClose} />
        {
            moderators?.length > 0 &&
            moderators.map((moderator, i) => (
                <span key={i}>
                    {moderator}
                </span>
            ))
        }
    </StyledModerationInfo>
)

const StyledModerationInfo = styled.div`
    position: absolute;
    background:  #4D4A48F0;;
    border: 1px solid #4D4A48;
    backdrop-filter: blur(236px);
    border-radius: 24px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    z-index: 10;
    right: 0;
    width: 28%;
    top: 90%;
    max-height: 300px;
    overflow-y: scroll;
    transition: all .3s;
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    opacity:${props => props.visible ? '1' : '0'};
    span {
        font-weight: 700;
        font-size: 15px;
        line-height: 130%;
        color:  ${props => props.type === APPROVE ? '#7DD060'
        : props.type === EXPECTED ? '#A4A4A4' : '#F28569'
    };
        margin-bottom: 10px;
        &:last-child{
            margin-bottom: 0;
        }
    }
`;

const StyledCloseButton = styled.img`
    width: 14px;
    height: 14px;
    position: absolute;
    top: 29px;
    right: 29px;
    cursor: pointer;
`;