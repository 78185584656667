import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const NewProfile = () => (
    <StyledExistProfile>
        <div>Новый пользователь?</div>
        <NavLink to='/auth/registration'>
            <span>Зарегистрируйтесь</span>
        </NavLink>
    </StyledExistProfile>
)

const StyledExistProfile = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 140%;
    margin: 32px 0 125px;
    justify-content: center;
    div {
        margin-right: 12px;
        color: #767782;
    }
    span {
        font-weight: 600;
        text-decoration-line: underline;
        color: #FFFFFF;
        cursor: pointer;
    }
    @media (min-width: 480px) {
        justify-content: flex-start;
    }
`;