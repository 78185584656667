import styled from "styled-components";

export const Title = () => (
    <StyledTitle> Комментарий</StyledTitle>
)

const StyledTitle = styled.div`
    font-weight: 700;
    font-size: 32px;
    line-height: 130%;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 21px;
    text-align: left;
`;