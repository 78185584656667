import { useEffect } from "react";
import styled from "styled-components";
import { BackgroundGradient } from "../BackgroundGradient/BackgroundGradient";
import { Modal } from "./Modal";

export const AboutStatus = ({ visible, onClose, profileLikes, profileRole }) => {

  const handleCloseModal = (e) => {
    const classList = e.target.classList
    classList.forEach(c => c === 'about-status-wrapper' && onClose());
  }

  useEffect(() => {
    window.addEventListener('click', handleCloseModal, false);
    return () => {
      window.removeEventListener('click', handleCloseModal, false);
    }
  })

  return (
    <StyledAboutStatus visible={visible} className="about-status-wrapper">
      <Modal
        onClose={onClose}
        likes={profileLikes}
        profileRole={profileRole}
      />
      <BackgroundGradient />
    </StyledAboutStatus>
  )
}
const StyledAboutStatus = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #232323;
  transition: all .5s;
  visibility: ${props => props.visible ? 'visible' : 'hidden'};
  opacity: ${props => props.visible ? '1' : '0'};
  padding: 20px 0 20px;
  overflow-x: hidden;
  scrollbar-width: none;  /* Firefox */
  overflow-y: scroll;
  @media (max-width: 800px) {
    align-items: flex-start;
  }
`;
