import { NavLink } from "react-router-dom";
import styled from "styled-components";
import logoIcon from '../../assets/icons/logo-hq.png';

export const Logo = () => (
  <StyledLogo>
    <NavLink to="/">
      <img src={logoIcon} alt="logo" />
      <span>peach</span>
    </NavLink>
  </StyledLogo>
)

const StyledLogo = styled.div`
    a {
        display: flex;
        align-items: center;
    }
    img {
        height: 37px;
        image-rendering: -webkit-optimize-contrast;
    }
    span {
        font-family: 'Montserrat', sans-serif !important;
        margin-left: 2px;
        font-weight: 700;
        font-size: 30px;
        line-height: 24px;
        color: #F4F7A1;
    }
    @media (max-width: 870px) {
        position: absolute;
    }
`;
