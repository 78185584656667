import styled from "styled-components";
import { MODERATOR } from "../../constants/roles";
import { useBlockUser } from "../../hooks/admin/useBlockUser";
import { useGiveModaratorRole } from "../../hooks/admin/useGiveModaratorRole";
import { Dropdown } from "../Dropdown/Dropdown";
import { Info } from "./Info";


export const Header = ({
    adminView,
    onAdmining,
    profileName,
    profileRole,
    profileId,
    profileLikes,
    isProfileBlocked,
    registrationDate,
    onComment
}) => {
    const { blockUser } = useBlockUser();
    const { giveModeratorRole } = useGiveModaratorRole();

    const dropdownOptions = [
        {
            label: profileRole === MODERATOR ? "Удалить модератора" : "Дать модератора",
            func: () => {
                profileRole === MODERATOR  && giveModeratorRole({ id: profileId, status: profileRole === MODERATOR ? '0' : '1' });
                profileRole !== MODERATOR && onComment({ id: profileId, status: profileRole === MODERATOR ? '0' : '1', type: 'givingModerator' })
            },
            confirm: true
        },
        {
            label: isProfileBlocked ? "Розблокировать" : "Заблокировать",
            func: () => blockUser({profileId, status: isProfileBlocked ? "1" : "0"}),
            confirm: true
        },
    ]

    return (
        <StyledHeader>
            <Info
                profileName={profileName}
                profileLikes={profileLikes}
                registrationDate={registrationDate}
                profileRole={profileRole}
            />
            {adminView &&
                <Dropdown options={dropdownOptions} classes="header-dropdown" />
            }
        </StyledHeader>
    )
}

const StyledHeader = styled.div`
    margin-bottom: 15px;
    display: flex;
    align-items: flex-start;
    .header-dropdown {
        margin-left: auto;
    }
`