import styled from "styled-components";

export const CursorInfoText = ({ position }) => (
    <StyledCursorInfoText position={position}>
        Ожидает подтверждения
    </StyledCursorInfoText>
)

const StyledCursorInfoText = styled.div`
    position: absolute;
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    color: #FFFFFF;
    left: ${props => props.position.x}px;
    top: ${props => props.position.y}px;
    z-index: 1000;
`;