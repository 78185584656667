import { useState } from "react";
import styled from "styled-components";
import { useAddTermToDetermine } from "../../hooks/termsToDetermine/useAddTermToDetermine";
import { BackgroundGradient } from "../BackgroundGradient/BackgroundGradient";
import { Input } from "../Input/Input";
import { Logo } from "../Logo/Logo";
import { Button } from "./Button";
import { CloseIcon } from "./CloseIcon";
import { Title } from "./Title";

export const CreateTermToDeterm = ({ visibility, onClose, onPopupMessage }) => {
    const { addTermToDetermine } = useAddTermToDetermine();
    const [title, setTitle] = useState('');
    const [error, setError] = useState(null);

    const handleChangeTitle = (value) => {
        setTitle(value);
        setError(null);
    }
    const handleCreateTerm = async () => {
        // onClose()
        const resp = await addTermToDetermine({ title });
        if (resp.status === 200) {
            setTitle('');
            onClose();
            onPopupMessage("Термин успешно добавлено")
        }
        setError(resp?.error)
    };

    // close modal on overlay click
    const handleCloseModal = (e) => {
        setTitle('');
        onClose();
        setError(null);
    }


    return (
        <StyledCreateTerm
            visibility={visibility}
            className="create-term-to-determ-wrapper"
        >
            <Logo />
            <CloseIcon onClick={handleCloseModal}/>
            <StyledContent>
                <Title title="Добавить термин" />
                <Input
                    label="Название"
                    value={title}
                    error={error}
                    onValueChange={handleChangeTitle}
                />
                <Button
                    text="Добавить"
                    onCreate={handleCreateTerm}
                    disabled={title.length === 0 || error}
                />
            </StyledContent>
            <BackgroundGradient />
        </StyledCreateTerm>
    )
}

const StyledCreateTerm = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #232323;
    height: 100vh;
    z-index: 1000;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 60px;
    overflow: scroll;
    transition: all .5s;
    visibility: ${props => props.visibility ? 'visible' : 'hidden'};
    opacity: ${props => props.visibility ? '1' : '0'};
    width: 100%;
    overflow-x: hidden;
    ${props => props.visibility && ` 
        ::-webkit-scrollbar {
            display: none;
        }`
    }

    .select{
        margin: 16px 0;
    }
    .input-desription {
        margin-bottom: 16px;
        textarea {
            height: 184px;
        }
    }
    .input-example {
        margin-bottom: 18px;
        textarea {
            height: 95px;
            font-style: italic;
        }
    }
`;

const StyledContent = styled.div`
    width: 90%;
    @media (min-width: 360px) {
        width: 320px;
    }
    @media (min-width: 480px) {
        width: 340px;
    }
`;