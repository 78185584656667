import axios from "axios"
import { baseUrl, headers } from "../../constants/api";

export const useGetRandomTerm = () => {
  const handleGetRandomTerm = async () => {
    const resp = await axios.get(`${baseUrl}/api/terms/get-random-term`, {
      headers: headers(),
    })
    return resp;
  }

  return {
    getRandomTerm : handleGetRandomTerm
  }
}