import styled from "styled-components";
import { Description } from "./Description";
import { Header } from "./Header";

export const FaqCard = ({ index, active, onToggleActive, title, children }) => (
    <StyledFaqCard onClick={() => onToggleActive(index)}>
        <Header active={active === index} title={title} />
        <Description active={active === index} text={children} />
    </StyledFaqCard>
)

const StyledFaqCard = styled.div`
    padding: 40px;
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(153px);
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 24px;
`;