import styled from "styled-components";

export const Title = ({title}) => (
    <StyledTitle>
        {title}
    </StyledTitle>
)

const StyledTitle = styled.div`
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: #F4F7A1;
    transition: all .5s;
    @media(min-width: 768px) {
        font-size: 32px;
    }
`;