import styled from "styled-components";

export const Title = () => (
  <StyledTitle>
    Не найдено 🔎
  </StyledTitle>
)

const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 130%;
  color: #FFFFFF;
  margin-bottom: 10px;
  text-align: center;
`;