import styled from "styled-components";
import { Label } from "./Label";
import { Editor, EditorState, RichUtils } from 'draft-js';
import { stateToHTML } from "draft-js-export-html";
import { useRef, useState } from "react";
import { LinkInput } from "./LinkInput";
import { decorator } from "./linkDecorator";
import { useEffect } from "react";
import { convertFromHTML } from "draft-js";
import { ContentState } from "draft-js";

export const TextEditor = ({
  label,
  classes,
  onChange,
  placeholder,
  defaultValue,
  error,
  linkEditor
}) => {
  // text editor value
  const [value, setValue] = useState(EditorState.createEmpty(decorator));
  const editorRef = useRef();
  // add link input value
  const [link, setLink] = useState('');
  // is selected text length in the editor more then 0
  const [isSelectedText, setIsSelectedText] = useState(false);
  // checking text editor focus status for styles
  const [focus, setFocus] = useState(false);

  // change link input value
  const handleChangeLink = (value) => setLink(value);

  // get selecting editor text
  const getCurrentTextSelection = (editorState) => {
    const selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const currentContent = editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(anchorKey);
    const start = selectionState.getStartOffset();
    const end = selectionState.getEndOffset();
    const selectedText = currentContentBlock.getText().slice(start, end);
    return selectedText
  };

  // checking selected text length
  const handleCheckSelectedTextLength = () => {
    // geting selected text length
    const selectedText = getCurrentTextSelection(value);
    // toggle adding link input, if selected text length more then 0
    setIsSelectedText(selectedText.length > 0);
  }

  // parse editor value to html 
  const handleParseValueToHtml = () => {
    const content = value.getCurrentContent();
    // value to html 
    const htmlValue = stateToHTML(content);
    const textValue = value.getCurrentContent().getPlainText('');
    onChange && onChange({ htmlValue, textValue });
  }

  // on value change
  useEffect(() => {
    // checking selected text on change
    handleCheckSelectedTextLength();
    handleParseValueToHtml();
    // eslint-disable-next-line
  }, [value])

  // create new link
  const handleCreateLink = () => {
    const editorState = value;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      { url: link, target: '_blank' }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
    setValue(RichUtils.toggleLink(
      newEditorState,
      newEditorState.getSelection(),
      entityKey
    ))
    // reset link values
    setLink('');
    setIsSelectedText(false);
  }

  const handleSetDefaultValue = () => {
    const blocksFromHTML = convertFromHTML(defaultValue);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    setValue(EditorState.createWithContent(state, decorator));
  }
  useEffect(() => {
    defaultValue?.length > 0 && handleSetDefaultValue()
    // eslint-disable-next-line
  }, [defaultValue])
  return (
    <div>
      {
        linkEditor &&
        <LinkInput
          value={link}
          onChange={handleChangeLink}
          disabled={link.length === 0}
          onCreateLink={handleCreateLink}
          visible={isSelectedText}
        />
      }
      <Label label={label} placeholder={placeholder} error={error} />
      <StyledTextEditor
        className={classes}
        focus={focus}
        onClick={() => editorRef.current.focus()}
        error={error}
      >
        <Editor
          editorState={value}
          onChange={(e) => setValue(e)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          ref={editorRef}
        />
      </StyledTextEditor>
    </div>
  )
}


const StyledTextEditor = styled.div`
    width: 100%;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.03) !important;
    border: 1px solid #4D4A48;
    border-radius: 10px;
    transition: all .3s;
    padding: 14px 9px;
    font-weight: 400;
    font-size: 17px;
    line-height: 130%;
    color: ${props => props.error ? '#F28569' : '#FFFFFF'};
    ${props => props.error && 'border: 1px solid #F28569 !important;'}
    overflow: auto;
    resize: none;
    ${props => props.focus && 'border: 1px solid #F4F7A1;'}
    &::placeholder {
        font-weight: 400;
        font-size: 17px;
        line-height: 130%;
        color: rgba(255, 255, 255, 0.16);
    }
    @media (min-width: 360px) {
        padding: 14px 19px;
    }
    a {
        color: #FDFDFD;
        text-decoration: underline;
    }
`;