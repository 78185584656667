import styled from "styled-components";

export const CloseIcon = ({ onClick }) => (
    <StyledCloseIcon
        width="24"
        height="24"
        viewBox="0 0 14 14"
        fill="#F4F7A1"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.292894 0.292894C0.683418 -0.097631 1.31658 -0.097631 1.70711 0.292894L7.00001 5.5858L12.2929 0.292894C12.6834 -0.0976312 13.3166 -0.0976312 13.7071 0.292894C14.0976 0.683418 14.0976 1.31658 13.7071 1.70711L8.41422 7.00001L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L7.00001 8.41422L1.70711 13.7071C1.31658 14.0976 0.683419 14.0976 0.292894 13.7071C-0.097631 13.3166 -0.097631 12.6834 0.292894 12.2929L5.5858 7.00001L0.292894 1.70711C-0.0976312 1.31658 -0.0976312 0.683419 0.292894 0.292894Z"
            fill="#F4F7A1"
        />
    </StyledCloseIcon>
)

const StyledCloseIcon = styled.svg`
    position: absolute;
    top: 76px;
    right: 30px;
    cursor: pointer;
`;