import styled from "styled-components";
import { Message } from "./Message";
import arrowIcon from '../../assets/icons/step-arrow-down.svg';

export const Step2 = ({ visible, onNextInfoStep }) => (
    <StyledStep2 visible={visible} onClick={onNextInfoStep}>
        <Message>
            <StyledContent>
                Все максимально просто: если вы считаете,
                что пост имеет место быть на сайте – ✅. Нет – ❌.
                Если же вы не посвящены в этом термине либо просто не уверены - ❔
            </StyledContent>
        </Message>
        <StyledArrow src={arrowIcon} />
    </StyledStep2>

)

const StyledStep2 = styled.div`
    position: absolute;
    flex-shrink: 0;
    max-width: 90vw;
    right: 0;
    bottom: 54px;
    width: 90vw;
    transition: all .5s;
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    opacity: ${props => props.visible ? '1' : '0'};
    z-index:  ${props => props.visible ? '100' : '-100'};
    transform: translateY(${props => props.visible ? 0 : -100}px);
    @media(min-width: 480px) {
        width: 442px;
        right: -30px;
    }
    @media(min-width: 1030px) {
        right: 50%;
        transform: translateX(50%);
    }
`;

const StyledContent = styled.div`
    line-height: 150%;
    img {
        height: 15px;
        margin-right: 10px;
    }
`;


const StyledArrow = styled.img`
    margin:  -10px  0 0 75%;
    height: auto !important;
    @media(min-width: 1030px) {
        margin:  -10px  0 0 45%;
    }
`;