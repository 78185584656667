import axios from "axios"
import { useQueryClient } from "react-query";
import { baseUrl } from "../../constants/api"
import { USER } from "../../constants/types";

export const useRegistrationByGoogle = () => {
    const client = useQueryClient();
    const registrationByGoogle = (data) => {
        return axios.post(`${baseUrl}/api/auth/register-google`, data)
            .then(resp => {
                const userData = resp?.data?.response?.user;
                const userId = btoa(userData?.id);
                localStorage.setItem('CUID', userId);
                client.setQueriesData(USER, userData);
                return resp;
            })
            .catch(error => error.response)
    }

    return { registrationByGoogle }
}