import styled from "styled-components";

export const About = ({ onOpenAboutStatusModal }) => {
    return (
        <StyledAbout onClick={onOpenAboutStatusModal}>
            О статусах
        </StyledAbout>
    )
}

const StyledAbout = styled.div`
    margin-top: 34px;
    font-weight: 500;
    font-size: 15px;
    line-height: 130%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #F28569;
    transition: all .5s;
    cursor: pointer;
    &:hover {
        color: #FFFF;
    }
`;