import { useState } from "react";
import styled from "styled-components";
import { useEditArticle } from "../../hooks/article/useEditArticle";
import { ArticleCard } from "../ArticleCard/ArticleCard";
import { Input } from "../Input/Input";
import { Button } from "./Button";
import { CloseIcon } from "./CloseIcon";
import { DownloadFile } from "./DownloadFile";
import { Title } from "./Title";

export const EditArticle = ({
  id,
  initImage,
  initDescription,
  initLink,
  onPopupMessage,
  onClose
}) => {
  const { editArticle } = useEditArticle();
  const [errors, setErros] = useState({});
  const [imageInput, setImageInput] = useState();
  const [image, setImage] = useState(initImage);
  const [description, setDescription] = useState(initDescription);
  const [link, setLink] = useState(initLink);
  const [loading, setLoading] = useState(false);

  const handleChangeImageInput = e => {
    setImageInput(e);
    const reader = new FileReader();
    reader.readAsDataURL(e?.files[0]);
    reader.onload = function () {
      setImage(reader.result);
    }
  }
  const handleChangeDescription = value => setDescription(value);
  const handleChangeLink = value => setLink(value);

  const handleSaveArticle = async () => {
    setLoading(true);
    const resp = await editArticle({ article_id: id, description, link, image });
    if (resp === 'success') {
      onPopupMessage('Успешно сохранено');
      onClose();
      setErros({});
      setLoading(false)
    }
  }

  return (
    <StyledEditArticleWrapper>
      <StyledModal>
        <Title title="Редактировать статью" />
        <CloseIcon onClose={onClose} />
        <DownloadFile
          imageValue={imageInput}
          onChangeValue={handleChangeImageInput}
        />
        {
          (image || description?.length > 0) &&
          <ArticleCard
            image={image}
            description={description}
            styles="edit-article-card"
            noClose
          />
        }
        <Input
          label="Описание"
          value={description}
          onValueChange={handleChangeDescription}
          error={errors?.descripton}
          textarea
          classes="description-textarea"
        />
        <Input
          label="Ссылка"
          value={link}
          onValueChange={handleChangeLink}
          error={errors?.link}
        />
        <Button
          text="Добавить"
          onCreate={handleSaveArticle}
          disabled={!image || description?.length === 0 || link?.length === 0 || loading || errors?.name}
        />
      </StyledModal>
    </StyledEditArticleWrapper>
  )
}


const StyledEditArticleWrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(35, 35, 35, 0.94);
    z-index: 30;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .5s;
    .description-textarea {
      textarea {
        height: 184px;
        margin-bottom: 16px;
      }
    }
    .edit-article-card {
      height: 340px;
    }
`;

const StyledModal = styled.div`
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid #4D4A48;
  backdrop-filter: blur(236px);
  border-radius: 24px;
  padding: 30px 40px;
  box-sizing: content-box;
  /* width: 80vw; */
  overflow-y: auto;
  max-height: 80%;
  position: relative;
  .input {
    textarea {
      height: 239px;
    }
  }
  @media(min-width: 660px) {
    padding: 24px 100px 29px;
    width: 340px;
    /* max-width: 50vw;
    max-width: 60vw; */
  }
  /* @media (min-width: 1280px) {
    max-width: 1100px;
  } */
`;
