import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Label = ({ label, disabled }) => (
  <StyledLabel disabled={disabled}>
    {
      label?.length > 0 &&
      label.map((t, i) => {
        if (t.type === 'link') {
          if (t?.linkType && t?.linkType === 'navLink') {
            return <NavLink to={t.href}>{t.text}</NavLink>
          } else {
            return <a href={t.href} target="_blank" rel="noreferrer">{t.text}</a>
          }
        } else if (t.type === 'space') {
          return <div className="space" />
        } else {
          return t.text
        }
      })
    }
  </StyledLabel>
)

const StyledLabel = styled.label`
    margin-left: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: ${props => props.disabled ? '#696969' : '#FFFFFF'};
    a {
        margin: 0 5px;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        display: flex;
        align-items: center;
        color: #FFFFFF;
        text-decoration: underline;
    }
    .space {
        width: 10px;
    }
`;