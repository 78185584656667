import styled from "styled-components";
import { Arrow } from "./Arrow";
import { Title } from "./Title";

export const Header = ({ active, title }) => (
    <StyledHeader>
        <Title title={title}  active={active} />
        <Arrow  active={active}/>
    </StyledHeader>
)

const StyledHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
