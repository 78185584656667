import { useState } from "react";
import styled from "styled-components";
import { FaqCard } from "../components/FaqCard/FaqCard";
import { Logo } from "../components/Logo/Logo";
import SEO from "../helpers/SEO";

export const Faq = () => {
  const [active, setActive] = useState(null);

  // change active faq item
  const handleToggleActive = (value) => setActive(value === active ? null : value);

  return (
    <>
      <SEO title="Speach – FAQ" />
      <StyledTermsOfUse className="container">
        <Logo classes="logo" />
        <StyledTitle>FAQ</StyledTitle>
        <FaqCard
          index={1}
          active={active}
          onToggleActive={handleToggleActive}
          title="Какое отношение имеют ники юзеров к ЛГБТ?"
        >
          <span>
            Как вы могли заметить, никнеймы пользователей на сайте могут иметь разные окрасы, хотя по дефолту они белые. Цвета имеют особую значимость – они играют своего рода статус пользователя и идут в ногу с количеством лайков на совокупности всех его постов. Выглядит эта последовательность следующим образом:
            <br /> <br />
            <span> dmleontiev – 0-19 ❤️</span> <br />
            <span className="color-1"> dmleontiev – 20-99 ❤️</span> <br />
            <span className="color-2"> dmleontiev – 100-499 ❤️</span> <br />
            <span className="color-3"> dmleontiev – 500-999 ❤️</span>< br />
            <span className="color-4"> dmleontiev – 1000-1999 ❤️</span> <br />
            <span className="color-5">   dmleontiev – 2000+ ❤️</span> <br />
            <br /> <br />
            Самые бдительные задроты смогли заметить схожести с классификацией скинов в играх от Valve (CS:GO, Dota и тд). Да, такая вот пасхалочка. Также есть отдельная каста пользователей – модераторы. За них в следующем фак ю.
          </span>
        </FaqCard>
        <FaqCard
          index={2}
          active={active}
          onToggleActive={handleToggleActive}
          title="Что может модератор и как им стать?"
        >
          <span>
            Помимо функций стандартного пользователя, модератор имеет возможность учавствовать в голосованиях по одобрению/отклонению свеже-предложенных постов, а также добавлять термины в категорию "Нужно определить". <br /> <br />
            Также у нас есть свой чат в Телеграме, где мы собираемся в формате "Тайная вечеря" и обсуждаем утопический марксизм и способы оцифровки игры "Печенька". <br /> <br />
            Получить роль модератора вы можете путём создания портфолио из качественнейших постов; тогда админ оценит ваш вклад в развитие Speach и вышлет приглашение на новую роль.
          </span>
        </FaqCard>
        <FaqCard
          index={3}
          active={active}
          onToggleActive={handleToggleActive}
          title="Влияют ли дерьмовые оценки на мой статус?"
        >
          <span>
            Вообще да, влияют. <br /><br />  Но если речь идет о цвете ника, то нет; ни какашки, ни сохранения на статус пользователя не влияют
          </span>
        </FaqCard>
        <FaqCard
          index={4}
          active={active}
          onToggleActive={handleToggleActive}
          title="Что по политкорректности?"
        >
          <span>
            Вопреки общепринятым установкам о словарях, мы не собираемся вас кормить плоскими определениями с синтаксическим разбором на пылью покрытые слова. <br /><br />
            Здесь каждый рассказывает историю, и для того чтобы автор смог должным образом донести читателю свою мысль и при возможности выбить из него пару ха-ха, мы отвечаем всем либеранутым то же, что и в последнем пункте нашего FAQ.
          </span>
        </FaqCard>
        <FaqCard
          index={5}
          active={active}
          onToggleActive={handleToggleActive}
          title="А по копипастингу?"
        >
          <span>
            Прямое копирование информации из других источников нежелательно, но если уж очень чешется, то в конце текста ссылайтесь на изначального автора с символом цитаты (с). <br /><br />
            С информацией, взятой со Speach - аналогично: textextext (c) dmleontiev, speach.uk
          </span>
        </FaqCard>
        <FaqCard
          index={6}
          active={active}
          onToggleActive={handleToggleActive}
          title="Как я могу оставить фидбек для Speach?"
        >
          <span>
            Воспользуйтесь нашим Telegram-ботом, либо же, если вы всё еще гасите копьями мамонтов и занимаетесь пещерной живописью – feedback@speach.uk
          </span>
        </FaqCard>
        <FaqCard
          index={7}
          active={active}
          onToggleActive={handleToggleActive}
          title="“Это всё неправильно – русский язык наоборот нужно избавлять от англицизмов! Почему не блокируете маты? Там должна стоять буква ё вместо е!!!”"
        >
          <span>
            Иди нахуй.
          </span>
        </FaqCard>
      </StyledTermsOfUse>
    </>
  )
}
const StyledTermsOfUse = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  width: 90%;
  margin: 0 auto;
  .logo {
    margin: 0 auto 113px;
  }
`;

const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 30px;
  @media(min-width: 480px) {
    font-size: 32px;
    margin-bottom: 60px;
  }
`;