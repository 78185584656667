import { useEffect } from "react";
import { useRef } from "react";
import styled from "styled-components";
import { getUserColor } from "../../helpers/user";

export const Card = ({ title, descripton, owner, ownerLikesCount }) => {
  const descriptionRef = useRef();

  useEffect(() => {
    if (descriptionRef.current) {
      descriptionRef.current.innerHTML = descripton;
    }
  }, [descripton])

  return (
    <StyledCard>
      {title && <div className="title">{title}</div>}
      <div ref={descriptionRef} />
      {
        owner &&
        <StyledUser color={getUserColor(owner?.role_id, ownerLikesCount)}>
          @{owner?.name}
        </StyledUser>
      }
    </StyledCard>
  )
}

const StyledCard = styled.div`
  width: 100%;
  height: auto;
  max-height: 100%;
  background: rgba(255, 255, 255, 0.03);
  opacity: 0.9;
  backdrop-filter: blur(53.9459px);
  border-radius: 3.52588px;
  padding: 9px;
  font-weight: 400;
  font-size: 13px;
  line-height: 130%;
  color: #D5D2D0;
  z-index: 2;
  word-break: normal;
  .title {
    font-weight: 700;
    font-size: 15px;
    line-height: 130%;
    color: #F4F7A1;
    margin-bottom: 4px;
  }
  a {
    color: #D5D2D0;
  }
  p {
    margin: 5px 0;
  }
  @media(min-width: 480px) {
    padding: 11px;
    font-size: 11px;
    .title {
      font-size: 16px;
      margin-bottom: 6px;
    }
  }
  @media(min-width: 768px) {
    padding: 13px;
    .title {
      font-size: 16px;
      margin-bottom: 6px;
    }
  }
`;

const StyledUser = styled.div`
  font-weight: 400;
  font-size: 6.94444px;
  line-height: 130%;
  color: ${props => props.color ?? "#FFFFFF"};
  margin-top: 5px;
`;