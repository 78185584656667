import styled from "styled-components";

export const AddTerm = ({ onCreateTermToDeterm }) => (
    <StyledAddTerm onClick={onCreateTermToDeterm}>
        Добавить термин
    </StyledAddTerm>
)

const StyledAddTerm = styled.div`
    padding: 5px 10px;
    border: 1px solid #F4F7A1;
    border-radius: 10px;
    font-weight: 700;
    font-size: 10px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    cursor: pointer;
    transition: all .5s;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    &:hover {
        background: #F4F7A1;
        color: #232323;
    }
    @media(min-width: 400px) {
        padding: 5px 10px;
    }
    @media(min-width: 468px) {
       height: 40px;
       font-size: 12px;
    }
    @media(min-width: 660px) {
        padding: 8px 16px;
        font-size: 16px;
        height: 50px;
    }
`;