import styled from "styled-components";

export const Description = ({ sended, email }) => (
    <StyledDescription>
        {
            sended ? <>
                Вам была выслана инструкция по сбросу <br /> пароля, проверьте <span>{email}</span>
            </>
                : <>
                    Инструкция будет выслана на вашу <br />
                    электронную почту
                </>
        }

    </StyledDescription>
)

const StyledDescription = styled.div`
    font-weight: 400;
    font-size: 17px;
    line-height: 140%;
    color: #818181;
    margin-bottom: 40px;
    text-align: center;
    span {
        color: #F4F7A1;
    }
`;