import axios from "axios";
import { useQuery } from "react-query";
import { baseUrl } from "../../constants/api";
import { TERMS_TO_DETERMINE } from "../../constants/types";

export const useTermsToDetermine = () => {
    const { data: termsToDetermine = null, refetch } = useQuery(
        [TERMS_TO_DETERMINE],
        async () => (await axios.get(`${baseUrl}/api/moder/get-all-terms-determine`, {
        }))?.data?.response?.terms,
    );

    return { termsToDetermine, refetch }
}
