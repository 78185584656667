import styled from "styled-components";

export const Divider = () => (
    <StyledDivider/>
)

const StyledDivider = styled.div`
    background: #474747;
    height: 1px;
    width: auto;
    margin: 0 18px 0 24px;
    @media(min-width: 480px) {
        margin: 0 20px;
    }
    @media(min-width: 768px) {
        margin: 0 0 0 48px;
    }
    @media(min-width: 1000px) {
        margin: 0 40px 0 41px;
    }
`;