import axios from "axios";
import { useQueryClient } from "react-query";
import { baseUrl, headers } from "../../constants/api";
import { BAGS } from "../../constants/types";

export const useRemoveBag = () => {
    const client = useQueryClient();

    const handleRemoveBag = async (id, successPopup) => {
        const resp = await axios.delete(`${baseUrl}/api/bags/delete-bag`, {
            headers: headers(),
            params: { bag_id: id }
        })

        if (resp?.status === 200) {
          const bagsData = client.getQueryData(BAGS);
          if (bagsData) {
              const updatedAllBagsData = bagsData;
              updatedAllBagsData.data = updatedAllBagsData.data.filter(bag => bag.id !== id);
              client.setQueriesData(BAGS, updatedAllBagsData);
              successPopup("Баг успешно удален")
          }
            return resp;
        }
    }

    return { removeBag: handleRemoveBag}
}