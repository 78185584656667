import styled from "styled-components";
import { getDate } from "../../helpers/getDate";

export const RegistrationDate = ({ registrationDate }) => (
    <StyledDate>
        Дата Регистрации: {getDate(registrationDate)}
    </StyledDate>
)

const StyledDate = styled.div`
    font-size: 13px;
    line-height: 130%;
    color: rgba(213, 210, 208, 0.24);
    @media(min-width: 480px) {
        font-size: 15px;
    }
`;