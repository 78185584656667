import styled from "styled-components";

export const Title = ({ title, active }) => (
    <StyledTitle active={active}>
        {title}
    </StyledTitle>
)

const StyledTitle = styled.div`
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    transition: all .5s;
    width: 70%;
    color: ${props => props.active ? '#F4F7A1' : '#FFFFFF'};
    word-break: break-all;
    @media(min-width: 380px) {
        word-break: unset;
    }
    @media(min-width: 480px) {
        font-size: 32px;
    }
`;