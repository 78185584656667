import styled from "styled-components";

export const Title = () => (
    <StyledTitle>Статистика:</StyledTitle>
)

const StyledTitle = styled.div`
    margin-bottom: 25px;
    font-weight: 400;
    font-family: 'Inter';
    font-size: 17px;
    line-height: 130%;
    color: #D5D2D0;
`;