import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { SUGGESTIONS } from "../../constants/profileTermsSection";
import { ADMIN, MODERATOR } from "../../constants/roles";
import { IsSafari } from "../../helpers/checkDevice";
import { Content } from "./Content";
import { CursorInfoText } from "./CursorInfoText";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { ModerationInfo } from "./ModerationInfo";

export const TermCard = ({
  title,
  description,
  termId,
  slug,
  sample,
  lastUpdateDate,
  owner,
  ownerId,
  ownerLikesCount,
  currentUser,
  isTermOfDay,
  isFavorite,
  likeStatus,
  moderatorsRating,
  verificationStatus,
  onEdit,
  notAproved,
  fullText,
  adminView,
  activeSection,
  infoStep,
  onAdmining,
  onNextInfoStep,
  onPopupMessage,
  onComment,
  onRefreshData,
  mainCard
}) => {
  const termRef = useRef();
  const [contentHeight, setContentHeight] = useState(null);
  // term is removed (for removing animation)
  const [removed, setRemoved] = useState(false);
  // show message next to mouse if term is'not approved
  const [mousePosition, setMousePosition] = useState(null);
  const [cursorInfoVisible, setCursotInfoVisible] = useState(false);
  // show moderation info
  const [moderationInfo, setModerationInfo] = useState(null);

  const toggleModerationInfo = (data) => setModerationInfo(data)
  const handleCloseModerationInfo = () => setModerationInfo(null);

  // checking mouse position
  const handleChangeMousePosition = (e) => {
    if (notAproved) {
      setCursotInfoVisible(true);
      setMousePosition({ y: e.pageY, x: e.pageX + 20 })
    } else {
      setCursotInfoVisible(false);
    }
  }

  // removing term animation
  const handleRemoving = () => {
    setContentHeight(termRef.current?.offsetHeight);
    setTimeout(() => { setRemoved(termId); setContentHeight(0) }, 100);
  }

  useEffect(() => {
    setContentHeight(null)
  }, [termId])

  return (
    <>
      {cursorInfoVisible && <CursorInfoText position={mousePosition} />}
      <StyledTermCardWrapper
        ref={termRef}
        removed={removed && removed === termId}
        contentHeight={contentHeight}
        isSafari={IsSafari()}
      >
        <ModerationInfo
          type={moderationInfo?.type}
          moderators={moderationInfo?.moderators}
          onClose={handleCloseModerationInfo}
        />
        <StyledTermCard
          notAproved={notAproved}
          onMouseMove={handleChangeMousePosition}
          onMouseLeave={() => setCursotInfoVisible(false)}
          className="no-backdrop-filter"
          isSafari={IsSafari()}
        >
          <Header
            title={title}
            description={description}
            sample={sample}
            owner={owner}
            ownerLikesCount={ownerLikesCount}
            termId={termId}
            slug={slug}
            isFavorite={isFavorite}
            isOwner={currentUser?.id === ownerId}
            onEdit={onEdit}
            isTermOfDay={isTermOfDay}
            user={currentUser?.role_id !== ADMIN && ownerId === currentUser?.id} // If current user's role is user and this is they past
            currentUserId={currentUser?.id}
            moderator={currentUser?.role_id === MODERATOR && activeSection === SUGGESTIONS}
            admin={currentUser?.role_id === ADMIN && !adminView}
            adminView={adminView}
            infoStep={infoStep}
            onAdmining={onAdmining}
            verificationStatus={verificationStatus}
            onTermRemove={handleRemoving}
            onNextInfoStep={onNextInfoStep}
            onPopupMessage={onPopupMessage}
            onComment={onComment}
            notAproved={notAproved}
            mainCard={mainCard}
          />
          <Content
            fullText={fullText}
            description={description}
            sample={sample}
            termId={termId}
            slug={slug}
          />
          <Footer
            title={title}
            description={description}
            sample={sample}
            owner={owner}
            ownerId={ownerId}
            ownerLikesCount={ownerLikesCount}
            isFavorite={isFavorite}
            onTermRemove={handleRemoving}
            termId={termId}
            slug={slug}
            lastUpdateDate={lastUpdateDate}
            notAproved={notAproved}
            moderator={currentUser?.role_id === MODERATOR && activeSection === SUGGESTIONS}
            admin={currentUser?.role_id === ADMIN && !adminView}
            currentUserId={currentUser?.id}
            likeStatus={likeStatus}
            moderatorsRating={moderatorsRating}
            onShowModerationInfo={toggleModerationInfo}
            adminView={adminView}
            onPopupMessage={onPopupMessage}
            onRefreshData={onRefreshData}
          />
        </StyledTermCard>
      </StyledTermCardWrapper>
    </>
  )
}

const StyledTermCardWrapper = styled.div`
    position: relative;
    width: 100%;
    transition: all ${props => props.removed ? '1s' : '0s'};
    height: ${props => props.contentHeight && `${props.contentHeight}px`};
    transform: ${props => props.removed && 'scale(0)'};
    opacity: ${props => props.removed ? '0' : '1'};
    overflow: ${props => props.removed && 'hidden'};
    margin-bottom: 10px;
    border-radius: 10px;
`;

const StyledTermCard = styled.div`
    width: 100%;
    padding: 36px 10px;
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(153px);
    border-radius: 10px;
    opacity: ${props => props.notAproved ? '0.3' : '1'};
    ${props => props.notAproved && 'cursor: help;'}
    z-index: 0 !important;
    -webkit-transition: all 10ms ease;
   -moz-transition: all 10ms ease;
   -ms-transition: all 10ms ease;
   transition: all 10ms ease;
    ${props => !props.notAproved && `
        &:hover {
            &:before {
                content: "";
                position: absolute;
                border-radius: 10px;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border: 2px  solid #F4F7A1;
                z-index: -1;
            }
        }
    `}
    ${props => props.notAproved && `
        &:before {
          content: "";
          position: absolute;
          border-radius: 10px;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 8;
        }
    `}
    @media (min-width: 480px) {
        padding: 36px 45px 36px 40px;

    }
    @media (min-width: 768px) {
        padding: 36px  40px;
    }
`;
