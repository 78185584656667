import styled from "styled-components";

export const Label = ({ label, error, placeholder }) => (
    <StyledLabel error={error} className="not-select">
        {error ?? label}
        {placeholder && !error && <span>( {placeholder} )</span>}
    </StyledLabel>
)

const StyledLabel = styled.label`
    display: block;
    margin-bottom: 8px;
    transition: all .5s;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: ${props => props.error ? '#F28569' : '#D5D2D0'};
    span {
        margin-left: 10px;
        opacity: .3;
    }
`;