import axios from "axios";
import { useQuery } from "react-query";
import { baseUrl } from "../../constants/api";
import { TOP_TERMS } from "../../constants/types";

export const useTopTerms = () => {
    const { data: topTerms = [] } = useQuery(
        [TOP_TERMS],
        async () => (await axios.get(`${baseUrl}/api/terms/get-top-terms`))?.data?.response.data,
    );
    return {topTerms}

}