import styled from "styled-components";
import copyIcon from "../../assets/icons/copy.svg";

export const Link = ({ onPopupMessage, termSlug }) => {

    const handleCopyLink = () => {
        const link = document.createElement("input");
        link.value = `https://speach.uk/opredelenie-slova/${termSlug}`;
        document.body.appendChild(link);
        link.select();
        document.execCommand("copy");
        document.body.removeChild(link);
        onPopupMessage("Ссылка скопирована")
    }

    return (
        <StyledLink>
            <span>https://speach.uk/opredelenie-slova/{termSlug}</span>
            <img
                src={copyIcon}
                alt=""
                onClick={handleCopyLink}
            />
        </StyledLink>
    )
}

const StyledLink = styled.div`
  display: grid;
  grid-template-columns: 1fr 20px;
  gap: 10px;
  align-items: center;
  height: 50px;
  width: 90%;
  max-width: 542px;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid #4D4A48;
  border-radius: 10px;
  padding: 17px 22px 16px 16px;
  font-weight: 600;
  font-size: 17px;
  line-height: 130%;
  text-align: center;
  color: #FFFFFF;
  margin-top: 24px;
  span {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    text-align: left;
  }
  img {
    cursor: pointer;
  }
  @media(min-width: 480px) {
    padding: 14px 20px 14px 21px;
  }
`;