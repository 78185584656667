import styled from "styled-components";
import cookieIcon from '../../assets/icons/cookie.svg';

export const Cookie = () => (
  <StyledCookie>
    <img src={cookieIcon} alt="" />
  </StyledCookie>
)

const StyledCookie = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 33px;
    background: #F4F7A1;
    border-radius: 100%;
    margin: 0 10px 0 0;
    flex-shrink: 0;
    img {
        height: 20px;
    }
    @media (min-width: 600px) {
        margin: 0 24px 0 0;
        width: 63px;
        height: 63px;
        img {
        height: auto;
    }
    }
`;