import styled from "styled-components";

export const AcceptButton = ({onAccept}) => (
    <StyledAcceptButton onClick={()=> onAccept(true)}>
        Принять
    </StyledAcceptButton>
)

const StyledAcceptButton = styled.button`
    padding: 10px;
    height: 30px;
    border: 1px solid #F4F7A1;
    border-radius: 53px;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 10px;
    line-height: 130%;
    color: #FFFFFF;
    cursor: pointer;
    transition: all .5s;
    &:hover {
        background: #F4F7A1;
        color: #232323;
    }
    @media (min-width: 350px) {
        font-size: 11px;
        padding: 2px 10px;
        height: 30px;
    }
    @media (min-width: 600px) {
        font-size: 17px;
        padding: 10px 22px;
        height: 42px;
    }
`;