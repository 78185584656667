import axios from "axios";
import { baseUrl, headers } from "../../constants/api";
import { useQueryClient } from "react-query";
import { PROFILE } from "../../constants/types";
import { checkIsDataExist } from "../../helpers/checkIsDataExist";
import { useParams } from "react-router-dom";

export const useUpdateProfileStatistics = () => {
  const client = useQueryClient();
  const { userId } = useParams();
  const currentUserId = atob(localStorage.getItem('CUID'));

  const handleUpdateProfileStatistics = async () => {
    if (userId) {
      const resp = await axios.get(`${baseUrl}/api/users/get-user-by-id`, {
        headers: headers(),
        params: { slug: userId, id: currentUserId }
      })
      if (resp?.status === 200) {
        const updatedStatistic = resp?.data?.response?.statistic;
        let profileData = client.getQueriesData(PROFILE);
        profileData = checkIsDataExist(profileData);
        if (profileData) {
          profileData = ({ ...profileData, statistic: updatedStatistic });
          client.setQueriesData(PROFILE, profileData);
        }
      }
    }
  }

  return {
    updateProfileStatics: handleUpdateProfileStatistics
  }
}