import { useState } from "react";
import styled from "styled-components";
import { ADMIN, MODERATOR } from "../../constants/roles";
import { useDeleteTermToDetermine } from "../../hooks/admin/useDeleteTermToDetermine";
import { useTermsToDetermine } from "../../hooks/termsToDetermine/useTermsToDetermine";
import { useUser } from "../../hooks/user/useUser";
import { CreateTermToDeterm } from "../CreateTermToDeterm/CreateTermToDeterm";
import { Step4 } from "../InfoSteps/Step4";
import { Divider } from "./Divider";
import { Info } from "./Info";
import { Terms } from "./Terms";

export const TermsToDetermined = ({ onPopupMessage, infoStep, onNextInfoStep }) => {
  const { user } = useUser();
  const { termsToDetermine } = useTermsToDetermine();
  const { deleteTermToDetermine } = useDeleteTermToDetermine();
  const [createTermToDeterm, setCreateTermToDeterm] = useState(false);

  const handleCreateTermToDeterm = (value) => setCreateTermToDeterm(value);

  const handleDeleteTermToDeterm = async (termId) => {
    const resp = await deleteTermToDetermine({ termId })
    if (resp.status === 200) {
      onPopupMessage("Термин успешно удалено")
    }
  }
  return (
    <>
      <CreateTermToDeterm
        visibility={createTermToDeterm}
        onClose={() => handleCreateTermToDeterm(false)}
        onPopupMessage={onPopupMessage}
      />
      <StyledTermsToDetermined className={infoStep === 4 && "no-backdrop-filter info-step-4"}>
        <Info
          onCreateTermToDeterm={() => handleCreateTermToDeterm(true)}
          isAddButton={(user?.role_id === ADMIN) || (user?.role_id === MODERATOR)}
        />
        <Divider />
        <Terms
          admin={user?.role_id === ADMIN}
          termsToDetermine={termsToDetermine}
          onDelete={handleDeleteTermToDeterm}
        />
        <Step4 visible={infoStep === 4} onNextInfoStep={onNextInfoStep} />
      </StyledTermsToDetermined>
    </>
  )
}

const StyledTermsToDetermined = styled.div`
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(153px);
    border-radius: 10px;
`;