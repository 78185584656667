import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function SEO({
  title = "Speach",
  description = "Объяснение терминов, англицизмов и сленга. Словарь сленга - Speach.uk",
  name = "speach.uk",
  type = "website",
  url = window.location.href,
  twitterUrl = window.location.href,
  ogUrl = window.location.href
}) {
  return (
    <Helmet>
      { /* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="image" content="http://backend.speach.uk/cover_template.png" />
      { /* End standard metadata tags */}
      { /* Facebook tags */}
      <meta property="og:url" content={ogUrl} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content="http://backend.speach.uk/cover_template.png" />
      { /* End Facebook tags */}
      { /* Twitter tags */}
      <meta name="twitter:card" content={type} />
      <meta property="twitter:domain" content="speach.uk" />
      <meta property="twitter:url" content={twitterUrl} />
      <meta name="twitter:image" content="http://backend.speach.uk/cover_template.png" />
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      { /* End Twitter tags */}
    </Helmet>
  )
}