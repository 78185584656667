import styled from "styled-components";
import { ScrollTop } from "./ScrollTop";

export const Aside = () => {
    return (
        <StyledAside>
            <ScrollTop/>
        </StyledAside>
    )
}

const StyledAside = styled.div`
    position: fixed;
    bottom: 5%;
    right: 13px;
    z-index: 10;
    @media (min-width: 768px) {
        right: 14px;
    }
    @media (min-width: 1000px) {
        right: 50px;
    }
    @media (min-width: 1200px) {
        right: 30px;
    }
`;

