import styled from "styled-components";
import infoIcon from "../../assets/icons/info.png";

export const Warning = () => (
  <StyledWarning>
    <img src={infoIcon} alt="" />
    К сожалению, пользователи IOS-устройств могут скачать только первую фотографию,
    так как Apple блокирует несколько одновременных загрузок на мобильное устройство.
    Если у вас нет компьютера, то просто сделайте скриншот карточек, сгенерированных выше.
  </StyledWarning>
)

const StyledWarning = styled.div`
  display: flex;
  align-items: center;
  color: #FFFF;
  border: 1px solid #f63939;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 30px;
  width: 90%;
  img {
    width: 30px;
    margin-right: 20px;
  }
`;