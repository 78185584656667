import axios from "axios";
import { useQueryClient } from "react-query";
import { baseUrl, headers } from "../../constants/api";
import { USER } from "../../constants/types";

export const useRejectComment = () => {
    const client = useQueryClient();

    const handleRejectComment = async ({ termId, comment, status }) => {
        const resp = await axios.post(`${baseUrl}/api/admin/add-term-comment`, null, {
            headers: headers(),
            params: { term_id: termId, comment, status }
        })

        if (resp?.status === 200) {
            let userData = client.getQueryData(USER);
            userData = userData ?? {};
            setTimeout(() => {
                let updatedUserData = userData;
                // updated terms
                updatedUserData.suggestion.expected.data = updatedUserData?.suggestion?.expected?.data?.filter(termin => termin?.id !== termId);
                updatedUserData.suggestion.rejected.data = updatedUserData?.suggestion?.rejected?.data?.filter(termin => termin?.id !== termId);
                updatedUserData.suggestion.approve.data = updatedUserData?.suggestion?.approve?.data?.filter(termin => termin?.id !== termId);
                client.setQueryData(USER, updatedUserData)
            }, 1000)
            return 'success'
        }
    }
    return { rejectComment: handleRejectComment }
}