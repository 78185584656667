import styled from "styled-components";

export const StatisticItem = ({ number, icon }) => (
  <StyledStatisticItem>
    <span>{number}</span>
    <img src={icon} alt="" />
  </StyledStatisticItem>
)

const StyledStatisticItem = styled.div`
    display: flex;
    align-items: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: #F4F7A1;
    margin-right: 8px;
    &:last-child {
        margin-right: 0;
    }
    span {
        margin-right: 16px;
    }
    @media(min-width: 480px) {
        font-size: 32px;
    }
    @media(min-width: 768px) {
        margin-bottom: 8px;
        &:last-child {
            margin-bottom: 0;
        }
    }
`;