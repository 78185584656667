import axios from "axios";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { baseUrl } from "../../constants/api";
import { TERM } from "../../constants/types";

export const useGetTermById = () => {
    const { termId } = useParams();
    const currentUserId = atob(localStorage.getItem('CUID'));

    const { data: term = null } = useQuery(
        [TERM, { termId }],
        async () => (await axios.get(`${baseUrl}/api/terms/get-term-by-id`, {
            params: {
                slug: termId,
                user_id: currentUserId
            }
        }))?.data?.response,
    );

    return {
        term
    }
}

export default useGetTermById;