import styled from "styled-components";
import { Message } from "./Message";
import arrowIcon from '../../assets/icons/step-arrow.svg';
import approveIcon from '../../assets/icons/approve.svg';
import questionIcon from '../../assets/icons/question.svg';
import notAprroveIcon from '../../assets/icons/not-approve.svg';

export const Step3 = ({ visible, onNextInfoStep }) => (
    <StyledStep3 visible={visible} onClick={onNextInfoStep}>
        <StyledArrow src={arrowIcon} />
        <Message>
            <StyledContent>
                Оценка постов работает по системе очков следующим образом: <br /> <br />
                <img src={approveIcon} alt="approved icon" />  +1 очко <br />
                <img src={questionIcon} alt="approved icon" /> 0 очков <br />
                <img src={notAprroveIcon} alt="approved icon" /> -1 очко <br /> <br />
                Пост подтверждается, когда набирает 6 очков при условии не больше 10 голосов. Модератор имеет право на один голос для каждого поста.
            </StyledContent>
        </Message>
    </StyledStep3>

)

const StyledStep3 = styled.div`
    position: absolute;
    z-index: 100;
    flex-shrink: 0;
    max-width: 90vw;
    top: 60px;
    right: 0;
    width: 90vw;
    transition: all .5s;
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    opacity: ${props => props.visible ? '1' : '0'};
    z-index:  ${props => props.visible ? '100' : '-100'};
    transform: translateY(${props => props.visible ? 0 : 100}px);
    @media(min-width: 480px) {
        width: 442px;
        right: -30px;
    }
    @media(min-width: 1030px) {
        /* right: 50%; */
    }
    @media(min-width: 1400px) {
        right: -300px;
        top: 50px;
    }
`;

const StyledContent = styled.div`
    line-height: 150%;
    img {
        height: 15px;
        margin-right: 10px;
    }
`;


const StyledArrow = styled.img`
    margin:  10px  0 0 75%;
    height: auto !important;
    @media(min-width: 1030px) {
        margin:  10px  0 0 65%;
    }
    @media(min-width: 1400px) {
        margin:  10px  0 0 15%;

    }
`;