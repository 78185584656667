import styled from "styled-components";


export const Button = ({ active, onToggle }) => (
  <StyledButton
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    active={active}
    onClick={onToggle}
  >
    <rect x="1" y="1" width="38" height="38" rx="19" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 18C11.9 18 11 18.9 11 20C11 21.1 11.9 22 13 22C14.1 22 15 21.1 15 20C15 18.9 14.1 18 13 18ZM27 18C25.9 18 25 18.9 25 20C25 21.1 25.9 22 27 22C28.1 22 29 21.1 29 20C29 18.9 28.1 18 27 18ZM18 20C18 18.9 18.9 18 20 18C21.1 18 22 18.9 22 20C22 21.1 21.1 22 20 22C18.9 22 18 21.1 18 20Z"
    />
  </StyledButton>
)

const StyledButton = styled.svg`
    cursor: pointer;
    transition: all .5s;
    rect {
        transition: all .5s;
        stroke: ${props => props.active ? '#F4F7A1' : '#6F6F6F'};
    }
    path {
        transition: all .5s;
        fill: ${props => props.active ? '#F4F7A1' : '#6F6F6F'};
    }
    &:hover {
        rect {
        stroke: #F4F7A1;
        }
        path {
            fill: #F4F7A1;
        }
    }
`;