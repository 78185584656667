import styled from "styled-components";

export const Divider = () => (
    <StyledDivider>
        Или зарегистрируйтесь с
    </StyledDivider>
)

const StyledDivider = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.5);
    margin: 34px 0;
    position: relative;
    &::before {
        content: '';
        width: 25%;
        height: 1.5px;
        background: #4D4A48;
        position: absolute;
        left: 0;
    }
    &::after {
        content: '';
        width: 25%;
        height: 1.5px;
        background: #4D4A48;
        position: absolute;
        right: 0;
    }
`;