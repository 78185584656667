import axios from "axios"
import { baseUrl } from "../../constants/api"

export const useResetPassword = () => {
    const resetPassword = (data) => {
        return axios.post(`${baseUrl}/api/auth/reset-password`, null, {
            params: data
        })
            .then(resp => resp)
            .catch(error => error.response)
    }

    return { resetPassword }
}