import axios from "axios"
import { baseUrl, headers } from "../../constants/api"

export const useSaveModeratorTelegram = () => {
    const saveModeratorTelegram = (data) => {
        return axios.post(`${baseUrl}/api/moder/save-username`, null, {
            headers: headers(),
            params: data
        })
            .then(resp => resp)
            .catch(error => error.response)
    }

    return { saveModeratorTelegram }
}