import styled from "styled-components";

export const Button = ({ onClick }) => (
  <StyledButton onClick={onClick}>
    Скачать фото
  </StyledButton>
)

const StyledButton = styled.div`
  height: 60px;
  width: 90%;
  max-width: 339px;
  background: #F28569;
  border-radius: 10px;
  padding: 19px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 700;
  font-size: 17px;
  line-height: 130%;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  margin: 24px 0 47px;
  @media(min-width: 480px) {
    margin: 24px 0 40px;
  }
  @media(min-width: 768px) {
    margin: 24px 0 60px;
  }
  @media(min-width: 1280x) {
    margin: 24px 0 72px;
  }
`;