import { useState } from "react";
import styled from "styled-components";
import { Input } from "../Input/Input";
import { Message } from "./Message";
import closeIcon from '../../assets/icons/close.svg';
import { useSaveModeratorTelegram } from "../../hooks/moderator/useSaveModeratorTelegram";
import { useParams } from "react-router-dom";

export const Step5 = ({ visible, onClose }) => {
    const { saveModeratorTelegram } = useSaveModeratorTelegram();
    const [telegram, setTelegram] = useState('');
    const { userId: activeUser } = useParams();

    const handleChangeTelegram = (value) => setTelegram(value);

    const handleSubmit = async () => {
        await saveModeratorTelegram({ username: telegram, user_id: activeUser });
        onClose();
    }

    return (
        <StyledStep5 visible={visible}>
            <Message>
                <StyledContent>
                    <StyledClose
                        src={closeIcon}
                        alt="close info modal"
                        onClick={onClose}
                    />
                    <span>
                        Введите свой Telegram username,
                        чтобы мы добавили вас в наш
                        админско-модераторский сходняк
                    </span>
                    <Input
                        placeholder="Необязательно"
                        value={telegram}
                        onValueChange={handleChangeTelegram}
                        classes="input"
                    />
                    {
                        telegram.length > 0 &&
                        <StyledButton onClick={handleSubmit}>Добавить</StyledButton>
                    }
                </StyledContent>
            </Message>
        </StyledStep5>
    )
}

const StyledStep5 = styled.div`
    position: fixed;
    z-index: 100;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 300px;
    max-width: 389px;
    transition: all 1s;
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    opacity: ${props => props.visible ? '1' : '0'};
    z-index:  ${props => props.visible ? '100' : '-100'};
    transform: translateY(${props => props.visible ? '-50%' : -100}px);
`;

const StyledContent = styled.div`
    line-height: 150%;
    width: 100%;
    position: relative;
    .input {
        margin: 16px 0;
        input, input::placeholder {
            font-size: 12px !important;
        }
    }
    @media(min-width: 360px){
        .input {
            input, input::placeholder {
                font-size: 17px !important;
            }
        }
    }
`;

const StyledClose = styled.img`
    position: absolute;
    top: -15px;
    right: -10px;
    cursor: pointer;
`;

const StyledButton = styled.div`
    padding: 17px 74px;
    width: 100%;
    height: 60px;
    background: ${props => props.disabled ? 'rgba(242, 133, 105, 0.5)' : '#F28569'};
    border-radius: 10px;
    margin: 24px 0 0;
    font-weight: 600;
    font-size: 17px;
    line-height: 130%;
    letter-spacing: 0.04em;
    color: ${props => props.disabled ? 'rgba(255, 255, 255, 0.5);' : '#FFFFFF'}; 
    text-align: center;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'}; 
`;