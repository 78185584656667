import axios from "axios";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { baseUrl, headers } from "../../constants/api";
import { PROFILE, USERT_TERMS } from "../../constants/types";
import { checkIsDataExist } from "../../helpers/checkIsDataExist";

export const useGiveModaratorRole = () => {
    const { userId } = useParams();
    const client = useQueryClient();

    const handleGiveModeratorRole = async ({ id, status, comment }) => {
        const resp = await axios.post(`${baseUrl}/api/admin/add-moderator`, null, {
            headers: headers(),
            params: { user_id: id, status, comment }
        })

        if (resp?.status === 200) {
            const updateProfile = resp?.data?.response?.user;
            // update user terms
            let profileData = client.getQueriesData(PROFILE);
            profileData = checkIsDataExist(profileData);
            if (profileData && updateProfile && (Number(userId) === id)) {
                let updatedProfileData = ({ ...profileData, ...updateProfile });
                client.refetchQueries(USERT_TERMS)
                client.setQueriesData(PROFILE, updatedProfileData);
            }
        }
        return resp;
    }
    return { giveModeratorRole: handleGiveModeratorRole }
}