import styled from "styled-components";

export const NoTermins = ({ text }) => (
  <StyledNoTermins className="not-select">
    {text ?? "Нет терминов"}
  </StyledNoTermins>
)

const StyledNoTermins = styled.div`
    margin: 30px auto;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: #D5D2D0;
    opacity: 0.5;
    text-align: center;
    @media(min-width: 468px) {
        font-size: 28px;
    }
    @media(min-width: 660px) {
        font-size: 32px;
    }
`;