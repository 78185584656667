import { useState } from "react";
import styled from "styled-components";
import { Label } from "./Label";
import arrowDownIcon from '../../assets/icons/arrow-down.svg';
import { Menu } from "./Menu";
import { SelectedOption } from "./SelectedOption";

export const Select = ({ label, options, value, onValueChange, classes }) => {
  const [toggle, setToggle] = useState(false);

  const handleSelect = (selectedValue) => {
    onValueChange(selectedValue);
    setToggle(false);
  }

  return (
    <div className={`not-select ${classes}`}>
      <Label label={label} />
      <StyledSelect
        active={toggle}
        onClick={() => setToggle(!toggle)}
      >
        <SelectedOption value={value?.label} isActive={toggle} />
        <img src={arrowDownIcon} alt="" />
        <Menu
          options={options}
          active={toggle}
          onSelectOption={handleSelect}
          selected={value?.value}
        />
      </StyledSelect>
    </div>
  )
}

const StyledSelect = styled.div`
  height: 50px;
  width: 100%;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid #4D4A48;
  border-radius: 10px;
  padding: 14px 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  img {
    transition: all .5s;
    cursor: pointer;
    transform: rotate(${props => props.active ? '180deg' : '0deg'});
  }
  @media (min-width: 360px) {
    padding: 14px 19px;
  }
`;

