import axios from "axios";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { baseUrl, headers } from "../../constants/api";
import { USERT_TERMS } from "../../constants/types";

export const useGetUserTerms = () => {
    const client = useQueryClient();
    // active profile id
    const { userId } = useParams();
    // current userId
    const currentUserId = atob(localStorage.getItem('CUID'));
    // handle loading more terms
    const [loadingMore, setLoadingMore] = useState(false);

    const { data: userTerms = null } = useQuery(
        [USERT_TERMS, { userId }],
        async () => (await axios.get(`${baseUrl}/api/users/${userId?.toLowerCase()}/get-terms`, {
            params: {
                user_id: currentUserId
            }
        }))?.data?.response,
    );


    const handleLoadMoreUserTerms = async () => {
        setLoadingMore(true);
        let userTerms = client.getQueriesData(USERT_TERMS)[0][1];
        userTerms = userTerms ?? {};
        const resp = await axios.get(`${baseUrl}/api/users/${userId?.toLowerCase()}/get-terms`, {
            headers: headers(),
            params: { page: userTerms?.current_page + 1 },
            user_id: currentUserId
        })
        const nextPage = resp?.data?.response;
        let updatedUserTerms = nextPage;
        updatedUserTerms.data = [...userTerms.data, ...nextPage.data];
        client.setQueriesData(USERT_TERMS, updatedUserTerms);
        setLoadingMore(false);
    }

    return {
        userTerms,
        onLoadMoreUserTerms: handleLoadMoreUserTerms,
        loadingMoreUserTerms: loadingMore
    }
}

export default useGetUserTerms;