import styled from "styled-components";

export const Description = ({ termTitle }) => (
    <StyledDescription>
        За термин <span>{termTitle}</span> ещё никто не <br /> раскидал, но вы всегда можете быть <br /> первыми 🥇
    </StyledDescription>
)

const StyledDescription = styled.div`
    font-weight: 600;
    font-size: 17px;
    line-height: 140%;
    text-align: center;
    color: #818181;
    margin-bottom: 64px;
    span {
        color: #F4F7A1;
    }
`;  