import styled from "styled-components";
import closeIcon from '../../assets/icons/close.svg';

export const Close = ({ onClose }) => (
    <StyledClose
        className="not-select"
        src={closeIcon}
        alt="close icon"
        onClick={onClose}
    />
)

const StyledClose = styled.img`
    position: absolute;
    top: 40px;
    right: 20px;
    opacity: 0.3;
    transition: all .3s;
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
`;