import styled from "styled-components";
import { Message } from "./Message";
import smileIcon from '../../assets/icons/selebrate-smile.svg';
import arrowIcon from '../../assets/icons/step-arrow-down.svg';

export const Step1 = ({ visible, onNextInfoStep }) => (
    <StyledStep1 visible={visible} onClick={onNextInfoStep}>
        <Message>
            <StyledContent>
                <StyledIcon>
                    <img src={smileIcon} alt="" />
                </StyledIcon>
                <div>
                    Теперь вы можете отдавать <br />
                    голоса за предложенные  <br />
                    другими юзерами посты!
                </div>
            </StyledContent>
        </Message>
        <StyledArrow src={arrowIcon} />
    </StyledStep1>

)

const StyledStep1 = styled.div`
    position: absolute;
    z-index: 100;
    bottom: 20px;
    flex-shrink: 0;
    max-width: 90vw;
    transition: all .5s;
    left: 0%;
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    opacity: ${props => props.visible ? '1' : '0'};
    z-index:  ${props => props.visible ? '100' : '-100'};
    transform: translateY(${props => props.visible ? 0 : -100}px);
    text-transform: none !important;
    @media(min-width: 460px) {
        left: -70%;
    }
    @media(min-width: 480px) {
        left: 0%;
    }
    @media(min-width: 540px) {
        left: -60%;
    }
    @media(min-width: 768px) {
        width: 442px;
        left: 0%;
    }
`;

const StyledContent = styled.div`
    display: flex;
    align-items: center;
`;

const StyledIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 63px;
    height: 63px;
    border-radius: 100%;
    background: #F4F7A1;
    margin-right: 24px;
    flex-shrink: 0;
`;

const StyledArrow = styled.img`
    margin:  0  0 27px 20%;
    @media(min-width: 460px) {
        margin:  0  0 27px 40%;
    }
    @media(min-width: 480px) {
        margin:  0  0 27px 20%;
    }
    @media(min-width: 540px) {
        margin:  0  0 27px 50%;
    }
    @media(min-width: 768px) {
        margin:  0  0 27px 20%;
    }
`;