import styled from "styled-components";

export const SocMediaButton = ({ logo, label, link, classes, onClick, id }) => (
  <StyledSocMediaButton
    href={link}
    className={classes}
    onClick={onClick}
  >
    <StyledHideBtn id={id} />
    <img src={logo} alt="" />
    <span>{label}</span>
  </StyledSocMediaButton>
)

const StyledSocMediaButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 100%;
    border: 1.5px solid #4D4A48;
    border-radius: 10px;
    background: none;
    transition: all .5s;
    position: relative;
    cursor: pointer;
    img {
        margin-right: 16px;
    }
    span {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #FFFFFF;
    }
    &:hover {
        border: 1.5px solid #F4F7A1;
    }
`;

const StyledHideBtn = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.001;
    cursor: pointer;
`;