import axios from "axios"
import { baseUrl } from "../../constants/api"

export const useSearchTerms = () => {
  const currentUserId = atob(localStorage.getItem('CUID'));
    const handleSearchTerms = async (text) => {
        const resp = await axios.get(`${baseUrl}/api/search-terms`, {
            params: {
               q: text, 
               perPage: 100,
               user_id: currentUserId
            }
        })

        return resp?.data?.response?.data ?? []
    }

    return { searchTerms: handleSearchTerms }
}