import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ADMIN } from "../../constants/roles";
import { useUser } from "../../hooks/user/useUser";
import { BackgroundGradient } from "../BackgroundGradient/BackgroundGradient";
import { AddButton } from "../Header/AddButton";
import { Button } from "./Button";
import { LoginButton } from "./LoginButton";
import { Logo } from "./Logo";
import { Navigation } from "./Navigation";

export const Menu = ({ visible, onToggleSideBar, onAuthCheck }) => {
  const navigate = useNavigate();
  const { user } = useUser();

  const handleToggleCreatingTerm = () => navigate(user?.role_id === ADMIN ? '/create' : '/create-term');

  return (
    <StyledMenu visible={visible}>
      <StyledHeader>
        <Logo />
        <Button
          active={visible}
          onToggleSideBar={onToggleSideBar}
        />
      </StyledHeader>
      <Navigation />
      <StyledButtons>
        <AddButton onCreate={() => onAuthCheck(handleToggleCreatingTerm)} />
        <LoginButton
          onToggleSideBar={onToggleSideBar}
          onAuthCheck={onAuthCheck}
        />
      </StyledButtons>
      <BackgroundGradient />
    </StyledMenu>
  )
}

const StyledMenu = styled.div`
    background: #232323;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    transition: all .5s;
    opacity: ${props => props.visible ? 1 : 0};
    visibility:  ${props => props.visible ? 'visible' : 'hidden'};
    z-index: 1000;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    max-height: 100vh;
    overflow: scroll;
    width: 100%;
    overflow-x: hidden;
`;

const StyledHeader = styled.div`
    display: flex;
    margin:  54px 0 70px;
    align-items: center;
    justify-content: flex-end;
    padding-right: 22px;
    width: 100%;
    min-height: 43px;
`;

const StyledButtons = styled.div`
    margin-top: 40px;
`;
