import axios from "axios";
import { useQueryClient } from "react-query";
import { baseUrl, headers } from "../../constants/api";
import { ARTICLES } from "../../constants/types";

export const useDeleteArticle = () => {
    const client = useQueryClient();

    const handleDeleteArticle = async ({articleId}) => {
        const resp = await axios.delete(`${baseUrl}/api/articles/delete-article`, {
            headers: headers(),
            params: {article_id: articleId}
        })

        if (resp?.status === 200) {
            const articlesData = client.getQueryData(ARTICLES) ?? [];
            const updatedArticles = articlesData?.filter(article => article.id !== articleId);
            client.setQueryData(ARTICLES, updatedArticles)
        }

    }

    return { deleteArticle: handleDeleteArticle }
}