import styled from "styled-components";
import { CheckBox } from "../CheckBox/CheckBox";
import { Input } from "../Input/Input";
import { Divider } from "./Didider";
import { RegistrationButton } from "./RegistrationButton";
import { Title } from "./Title";
import googleIcon from '../../assets/icons/google.svg';
import telegramIcon from '../../assets/icons/telegram.svg';
import { ExistProfile } from "./ExistProfile";
import { SocMediaButton } from "../SocMediaButton/SocMediaButton";
import { useEffect, useState } from "react";
import { useRegistration } from "../../hooks/auth/useRegistration";
import { checkIsCyrillic, emailValidation } from "../../helpers/validation";
import { useLocation, useNavigate } from "react-router-dom";
import { useRef } from "react";
import jwtDecode from 'jwt-decode';
import { Message } from "./Message";
import { useRegistrationByGoogle } from "../../hooks/auth/useRegistrationByGoogle";
import { useRegistrationByTelegram } from "../../hooks/auth/useRegistrationByTelegram";
import { useLoginByTelegramData } from "../../hooks/auth/useLoginByTelegramData";

export const Registration = ({ onPopupMessage }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { registration } = useRegistration();
  const { registrationByGoogle } = useRegistrationByGoogle();
  const { registrationByTelegram } = useRegistrationByTelegram();
  const { loginByTelegram } = useLoginByTelegramData();
  const [userName, setUserName] = useState('');
  const [userNameInfo, setUserNameInfo] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberPassword, setRememberPassword] = useState(false);
  const [agree, setAgree] = useState(false);
  const [errors, setErrors] = useState({});
  // auth google btn
  const [isGoogleRegistration, setIsGoogleREgistration] = useState(false);
  const [isTelegramRegistration, setIsTelegramRegistration] = useState(false);
  // google private id
  const [sub, setSub] = useState(null);
  const telegramRegistrationData = useRef();

  // change username input
  const handleChangeUserName = (value) => {
    setUserName(value);
    errors?.login && setErrors({ ...errors, login: null });
    if (value.length > 30) {
      setErrors({ ...errors, login: 'Юзернейм – слишком длинный, не более 30 символов' });
    } else {
      setErrors({ ...errors, login: checkIsCyrillic(value, 'Юзернейм – кириллицу в логин? Серьезно?') });
    }
  };

  // change email input
  const handleChangeEmail = (value) => {
    setEmail(value);
    errors?.email && handleEmailValidation(value);
  };

  // change password input
  const handleChangePassword = (value) => {
    setPassword(value);
    setErrors({ ...errors, password: checkIsCyrillic(value, 'Пароль – кириллицу в пароль? Серьезно?') });
    handleCheckPasswordLength(value);
  };

  // check password length on blur
  const handleCheckPasswordLength = (value) => {
    value.length < 8 && setErrors({ ...errors, password: 'Пароль – вряд ли ваш аккаунт будет кому-то нужен, но 8 символов – минимум' });
  }
  // toggle remember password
  const handleToggleRememberPassword = () => setRememberPassword(!rememberPassword);

  // toggle agree 
  const handleToggleAgree = () => setAgree(!agree);

  const handleEmailValidation = () => setErrors({ ...errors, email: emailValidation(email) })

  // submit registration
  const handleSubmit = async () => {
    let resp =
      isGoogleRegistration
        ? await registrationByGoogle({ login: userName, email, sub })
        : isTelegramRegistration ?
          await registrationByTelegram({ ...telegramRegistrationData.current, username: userName })
          :
          await registration({
            login: userName,
            email,
            password: isGoogleRegistration ? '' : password,
            password_confirmation: isGoogleRegistration ? '' : password,
            sub
          })
    if (resp.status === 200) {
      telegramRegistrationData.current = null;
      if (isGoogleRegistration || isTelegramRegistration) {
        const prevUrl = localStorage.getItem('prevUrl');
        localStorage.setItem('token', resp?.data?.response?.access_token);
        navigate(prevUrl ?? '/');
        onPopupMessage('Успешная регистрация');
        localStorage.removeItem('prevUrl');
      }
      else {
        navigate('/auth/login');
        onPopupMessage('Подтвердите почту и введите повторно пароль, который использовался для регистрации. Там точно было 8 символов.');
      }
    } else {
      console.log(resp)
      setErrors(resp?.data?.errors)
    }
  }

  // response get by google auth
  const handleGoogleAuth = async (response) => {
    const data = jwtDecode(response?.credential);
    // checking has user already registrated
    let isRegistrated = await registration({ email: data?.email })

    if (
      isRegistrated?.data?.errors?.email?.length > 0 &&
      isRegistrated?.data?.errors?.email[0] === 'Пользователь с таким email уже зарегистрирован'
    ) {
      return onPopupMessage('Вы зарегистрировались с помощью Google. Воспользуйтесь кнопкой ниже для входа в личный кабинет');
    } else if (data && data?.email) {
      setErrors({})
      setEmail(data?.email?.trim());
      setSub(data?.sub);
      setUserName(data?.email?.trim()?.split('@')[0])
      setIsGoogleREgistration(true);
      setPassword('google')
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  // login telegram
  useEffect(() => {
    // login telegram button
    let script = document.createElement('script');
    script.async = true;
    script.src = 'https://telegram.org/js/telegram-widget.js?19';
    script.id = "telegramLoginBtn"
    script.setAttribute('data-telegram-login', 'Speach_ukBot');
    script.setAttribute('data-size', 'medium');
    script.setAttribute('data-onauth', `onTelegramAuth(user)`);
    script.setAttribute('data-request-access', 'write');
    document.getElementById("laravel3bot").appendChild(script)


    // login telegram callback
    let callback = document.createElement('script');
    callback.id = "telegramLoginCallback"
    callback.innerHTML = `
          function onTelegramAuth(user) {
            const data = {
              id: user?.id,
              first_name: user?.first_name ?? "",
              last_name: user?.last_name ?? "",
              username: user?.username ?? "",
              photo_url: user?.photo_url ?? "",
              auth_date: user?.auth_date ?? "",
              hash: user?.hash ?? "",
            };
            
            // refresh page and set data to localstorage
            localStorage.setItem('telegramAuthData', JSON.stringify(data));
            window.location.reload()
          }
          `;
    document.getElementById("laravel3bot").appendChild(callback)

    return () => {
      document.getElementById("telegramLoginBtn")?.remove();
      document.getElementById("telegramLoginCallback")?.remove();
    }

  }, [])

  // google init
  useEffect(() => {
    const telegramData = JSON.parse(localStorage.getItem("telegramAuthData"));
    if (!telegramData) {
      /*global google*/
      google?.accounts?.id.initialize({
        client_id: '922735540701-qhoubcnv5fpfehe129tbu322j8a3s3b2.apps.googleusercontent.com',
        callback: handleGoogleAuth
      })

      // adding google auth button
      const singUpButton = document.querySelector('#google-sing-up-btn');
      if (singUpButton) {
        google.accounts.id.renderButton(singUpButton, {
          theme: 'dark',
          size: 'large',
          width: '300px',
          height: '60px'
        })
      }
    }
    // eslint-disable-next-line
  }, [])


  // check is telegram login data
  useEffect(() => {
    const telegramData = JSON.parse(localStorage.getItem("telegramAuthData"));
    if (telegramData) {
      loginByTelegram(telegramData, true)
        .then(resp => {
          if (resp.data.code !== 429) {
            localStorage.removeItem('telegramAuthData')
            onPopupMessage("Вы зарегистрировались с помощью Telegram. Воспользуйтесь кнопкой ниже для входа в личный кабинет");
            // eslint-disable-next-line
            /*global google*/
            google?.accounts?.id.initialize({
              client_id: '922735540701-qhoubcnv5fpfehe129tbu322j8a3s3b2.apps.googleusercontent.com',
              callback: handleGoogleAuth
            })

            // adding google auth button
            const singUpButton = document.querySelector('#google-sing-up-btn');
            if (singUpButton) {
              google.accounts.id.renderButton(singUpButton, {
                theme: 'dark',
                size: 'large',
                width: '300px',
                height: '60px'
              })
            }
          } else {
            setIsTelegramRegistration(true);
            setUserName(telegramData?.username);
            setPassword('telegram');
            setEmail('telegram@gmail.com');
            telegramRegistrationData.current = telegramData;
            localStorage.removeItem('telegramAuthData')
          }
        })
    }
    // eslint-disable-next-line
  }, [location])

  return (
    <StyledRegistration>
      {
        (isGoogleRegistration || isTelegramRegistration) &&
        <Message
          text="Теперь вам не нужно проходить верификацию по email, однако придумайте юзернейм, который будут видеть другие пользователи"
          error={errors?.email}
        />
      }
      <Title title="Регистрация" />
      <Input
        label={`Юзернейм ${userNameInfo ? '- его будут видеть другие пользователи' : ''}`}
        value={userName}
        error={errors?.login}
        onValueChange={handleChangeUserName}
        classes="input"
        onFocus={() => setUserNameInfo(true)}
        onBlur={() => setUserNameInfo(false)}
      />
      {
        (!isGoogleRegistration && !isTelegramRegistration) &&
        <Input
          label="Электронная почта"
          value={email}
          onValueChange={handleChangeEmail}
          classes="input"
          error={errors?.email}
          onBlur={handleEmailValidation}
        />
      }
      {
        (!isGoogleRegistration && !isTelegramRegistration) &&
        <Input
          label="Пароль"
          value={password}
          onValueChange={handleChangePassword}
          password
          error={errors?.password}
          classes="input"
          onFocus={handleCheckPasswordLength}
        />
      }
      {
        (!isGoogleRegistration && !isTelegramRegistration) &&
        <CheckBox
          label={[{ type: 'text', text: 'Запомнить пароль' }]}
          checked={rememberPassword}
          onToggle={handleToggleRememberPassword}
          disabled={password.length === 0}
          classes="checkbox"
        />
      }
      <CheckBox label={[
        { type: 'text', text: 'Я согласен с' },
        { type: 'link', text: "Правилами использования", href: "/#/term-of-use" },
        { type: 'space' },
        { type: 'text', text: 'и' },
        { type: 'link', text: "Политикой Конфиденциальности", href: "/#/privacy-policy" },
      ]}
        checked={agree}
        onToggle={handleToggleAgree}
        disabled={userName.length === 0 || email.length === 0 || password.length === 0}
        classes="checkbox"
      />
      <RegistrationButton
        disabled={userName.length === 0 ||
          email.length === 0 ||
          password.length === 0 ||
          !agree ||
          errors?.email ||
          errors?.login ||
          errors?.password
        }
        onSubmit={handleSubmit}
      />
      {
        (!isGoogleRegistration && !isTelegramRegistration) &&
        <>
          <Divider />
          <SocMediaButton
            logo={googleIcon}
            label="Регистрация с Google"
            link="#"
            id="google-sing-up-btn"
            classes="socmedia"
          />
          <SocMediaButton
            logo={telegramIcon}
            label="Регистрация с  Telegram"
            link="#"
            classes="socmedia"
            id="laravel3bot"
          />
        </>
      }
      <ExistProfile />
    </StyledRegistration>
  )
}

const StyledRegistration = styled.div`
    width: 100%;
    .input {
        margin-bottom: 16px;
    }
    .checkbox {
        margin-bottom: 10px;
    }
    .socmedia {
        margin: 16px 0 ;
    }
    #laravel3bot {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    height: 100% !important;
    cursor: pointer;
  }
`;
