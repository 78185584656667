import styled from "styled-components";
import { ADMIN } from "../../constants/roles";
import { useUser } from "../../hooks/user/useUser";

export const AddButton = ({ onCreate }) => {
  const { user } = useUser();

  return (
    <StyledAddButton onClick={onCreate}>
      {user?.role_id === ADMIN ? "Добавить " : "Добавить термин "}
      +
    </StyledAddButton>
  )
}

const StyledAddButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding: 8px 16px;
    border: 1px solid #F4F7A1;
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    cursor: pointer;
    transition: all .5s;
    &:hover {
        background: #F4F7A1;
        color: #232323;
    }
`;