import styled from "styled-components";

export const Button = ({ onClick }) => (
    <StyledButton onClick={onClick}>
       Стереть мои данные
    </StyledButton>
)


const StyledButton = styled.div`
    display: block;
    padding: 17px 74px;
    width: 100%;
    min-height: 60px;
    background: #F28569;
    border-radius: 10px;
    margin: 24px 0 0;
    font-weight: 600;
    font-size: 17px;
    line-height: 130%;
    letter-spacing: 0.04em;
    color: #FFFFFF; 
    text-align: center;
    cursor: pointer; 
`;