import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Title = ({ title, termId, slug, notAproved, mainCard }) => (
  <>
    {
      notAproved
        ? <StyledTitle>
          {title}
        </StyledTitle>
        : <NavLink to={`/opredelenie-slova/${slug}`} rel="canonical">
          {
            mainCard
              ? <StyledTitleMain>
                {title}
              </StyledTitleMain>
              : <StyledTitle>
                {title}
              </StyledTitle>

          }
        </NavLink>
    }
  </>
)

const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 28px;
  line-height: 130%;
  color: #F4F7A1;
  word-wrap: break-all;
  @media(min-width: 660px) {
    font-size: 32px;
  }
`;


const StyledTitleMain = styled.h1`
  font-weight: 600;
  font-size: 28px;
  line-height: 130%;
  color: #F4F7A1;
  word-wrap: break-all;
  margin: 0;
  @media(min-width: 660px) {
    font-size: 32px;
  }
`;