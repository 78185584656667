import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Number } from "./Number";
import { Title } from "./Title";

export const CategoryCard = ({ categoryId, title, termsNumber }) => (
    <NavLink to={`/category/${categoryId}`}>
        <StyledCategoryCard>
            <Title title={title} />
            <Number number={termsNumber} />
        </StyledCategoryCard>
    </NavLink>
)

const StyledCategoryCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(153px);
    border-radius: 10px;
    height: 91px;
    padding: 30px 24px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: all .5s;
    &:hover {
        background: #F4F7A1;
        div {
            color: #333333 !important;
        }
        span {
            color: #4F4F4F !important;
        }
    }
    @media (min-width: 480px) {
        padding: 30px 32px;
    }
    @media (min-width: 768px) {
        padding: 40px 32px 38px;
    }
    @media (min-width: 1000px) {
        padding: 40px 40px 38px;
    }
`;