import styled from "styled-components";
import { AddButton } from "./AddButton";
import { Description } from "./Description";
import { Image } from "./Image";
import { Title } from "./Title";

export const NotFoundCard = ({ termTitle, notTerm, onPopupMessage }) => (
  <StyledNotFoundCard>
    <Image />
    <Title />
    {termTitle && <Description termTitle={termTitle} />}
    {
      !notTerm &&
      <AddButton
        termTitle={termTitle}
        onPopupMessage={onPopupMessage}
      />
    }
  </StyledNotFoundCard>
)

const StyledNotFoundCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  justify-content: center;
  @media(min-width: 400px) {
    width: auto;
  }
`;  