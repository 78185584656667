import styled from "styled-components";
import { getDate } from "../../helpers/getDate";

export const Date = ({ lastUpdateDate }) => (
    <StyledDate>{getDate(lastUpdateDate)}</StyledDate>
)

const StyledDate = styled.div`
    font-weight: 400;
    font-size: 15px;
    line-height: 130%;
    color: #D5D2D0;
`;