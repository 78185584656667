import { ADMIN, MODERATOR } from "../constants/roles"

export const getUserColor = (roleId, numberOfLikes) => {
    if (roleId === MODERATOR) {
        return '#7DD060';
    } else if (roleId === ADMIN) {
        return '#C80000';
    } else if (numberOfLikes < 20) {
        return '#FFFFFF';
    } else if (numberOfLikes >= 20 && numberOfLikes <= 99) {
        return '#69F2D1';
    } else if (numberOfLikes >= 100 && numberOfLikes <= 499) {
        return '#6987F2';
    } else if (numberOfLikes >= 500 && numberOfLikes <= 999) {
        return '#8D69F2';
    } else if (numberOfLikes >= 1000 && numberOfLikes <= 1999) {
        return '#F2E569';
    } else if (numberOfLikes >= 2000) {
        return '#F28569';
    } else {
        return '#FFFFFF';
    }
};
