import styled from "styled-components";
import { Title } from "./Title";
import { CloseIcon } from "./CloseIcon";
import { Input } from "../Input/Input";
import { Button } from "./Button";
import { useEffect, useState } from "react";
import { useRejectComment } from "../../hooks/admin/useRejectComment";
import { useGiveModaratorRole } from "../../hooks/admin/useGiveModaratorRole";

export const Comment = ({ visible, onClose, onPopupMessage }) => {
  const { rejectComment } = useRejectComment();
  const { giveModeratorRole } = useGiveModaratorRole();

  // value
  const [comment, setComment] = useState('');

  // change value
  const handleChangeComment = (value) => setComment(value);

  // reset value on close modal
  useEffect(() => {
    if (!visible) {
      setComment('');
    } else {
      setComment(visible?.reject_comment ?? '')
    }
  }, [visible])

  // on submit
  const handleSubmit = async () => {
    if (visible?.type === 'givingModerator') {
      const resp = await giveModeratorRole({ id: visible.id, status: visible?.status, comment });
      if (resp?.status === 200) {
        onClose(false, true);
      }
    } else if (visible?.type === 'admining') {
      const resp = await rejectComment({ comment, termId: visible?.data?.termId, status: visible?.data?.status });
      if (resp === 'success') {
        visible?.data?.onTermRemove(visible?.data?.termId);
        onPopupMessage(`Термин успешно ${visible?.data?.status === 'approve' ? 'одобрено' : 'не одобрено'}`);
        onClose(false, true);
      }
    }
  }

  // close modal on press enter button
  const handleCloseComment = (e) => {
    if (e.key === "Enter") {
      comment.length > 0 ? handleSubmit() : onClose(false);
    }
  }
  useEffect(() => {
    window.addEventListener('keydown', handleCloseComment, false);
    return () => window.removeEventListener('keydown', handleCloseComment, false);
  })

  return (
    <StyledCommentWrapper visible={visible}>
      <StyledModal>
        <CloseIcon onClose={() => onClose(false)} />
        <Title />
        <Input
          classes="input"
          onValueChange={handleChangeComment}
          value={comment}
          textarea
        />
        <Button
          disabled={comment?.length === 0}
          onClick={handleSubmit}
        />
      </StyledModal>
    </StyledCommentWrapper>
  )
}

const StyledCommentWrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(35, 35, 35, 0.94);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .5s;
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    opacity: ${props => props.visible ? '1' : '0'};
`;

const StyledModal = styled.div`
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid #4D4A48;
    backdrop-filter: blur(236px);
    border-radius: 24px;
    padding: 30px 40px;
    box-sizing: content-box;
    width: 70vw;
    position: relative;
    .input {
        textarea {
            height: 239px;
        }
    }
    @media(min-width: 660px) {
        padding: 69px 88px 68px;
        width: 340px;
    }
`;
