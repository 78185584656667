import styled from "styled-components";

export const Arrow = ({ active }) => (
    <StyledArrow
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="12"
        viewBox="0 0 20 12"
        fill="none"
        active={active}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.7648 11.0259C17.9512 11.8247 16.6321 11.8247 15.8185 11.0259L10 5.31319L4.18147 11.0259C3.36787 11.8247 2.04878 11.8247 1.23518 11.0259C0.421591 10.2271 0.421591 8.93202 1.23518 8.13322L8.52687 0.974101C9.34044 0.1753 10.6595 0.1753 11.4731 0.974101L18.7648 8.13322C19.5784 8.93202 19.5784 10.2271 18.7648 11.0259Z"
        />
    </StyledArrow>
)

const StyledArrow = styled.svg`
    transition: all .5s;
    fill: ${props => props.active ? '#F4F7A1' : 'rgba(244, 247, 161, 0.45)'};
    transform: rotate(${props => props.active ? '0deg' : '180deg'});
`;