import axios from "axios";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { baseUrl, headers } from "../../constants/api";
import { CATEGORY_TERMS, TERM, TERMS, USERT_TERMS } from "../../constants/types";
import { checkIsDataExist } from "../../helpers/checkIsDataExist";
import { useUpdateProfileStatistics } from "../profile/useUpdateProfileStatistics";

export const useRemoveTerm = () => {
  const client = useQueryClient();
  const navigate = useNavigate();
  const { updateProfileStatics } = useUpdateProfileStatistics();

  const handleRemoveTerm = async ({ termId }) => {
    const resp = await axios.delete(`${baseUrl}/api/term/delete-term`, {
      headers: headers(),
      params: { term_id: termId }
    })

    if (resp?.status === 200) {
      // removing with delay for animation
      setTimeout(() => {
        // update dashboard terms
        const allTermsData = client.getQueryData(TERMS);
        if (allTermsData) {
          const updatedAllTermsData = allTermsData;
          updatedAllTermsData.data = updatedAllTermsData.data.filter(term => term.id !== termId);
          client.setQueriesData(TERMS, updatedAllTermsData);
        }

        // update user terms
        let userTermsData = client.getQueriesData(USERT_TERMS);
        userTermsData = checkIsDataExist(userTermsData);
        if (userTermsData) {
          let updatedUserTermsData = userTermsData;
          updatedUserTermsData.data = updatedUserTermsData.data.filter(term => term.id !== termId);
          client.setQueriesData(USERT_TERMS, updatedUserTermsData);
        }

        // update category terms
        let categoryTermsData = client.getQueriesData(CATEGORY_TERMS);
        categoryTermsData = checkIsDataExist(categoryTermsData);
        if (categoryTermsData) {
          let updatedCategoryTermsData = categoryTermsData;
          updatedCategoryTermsData.data = updatedCategoryTermsData.data.filter(term => term.id !== termId);
          client.setQueriesData(CATEGORY_TERMS, updatedCategoryTermsData);
        }

        // update term  
        let termData = client.getQueriesData(TERM);
        termData = checkIsDataExist(termData);
        if (termData) {
          const updatedTermData = ({});
          navigate('/');
          client.setQueriesData(TERM, updatedTermData);
        }

        // update profile statistics 
        updateProfileStatics();

      }, 1050)
      return 'success';
    }
  }

  return { removeTerm: handleRemoveTerm }
}