import { useState } from "react";
import styled from "styled-components";
import downloadIcon from "../../assets/icons/download.svg";

export const DownloadFile = ({ imageValue, onChangeValue }) => {
  const [value, setValue] = useState();

  return (
    <>
      <StyledLabel className="label">Обложка</StyledLabel>
      <StyledInput>
        <input
          type="file"
          accept=".jpeg, .jpg, .png, .pdf"
          value={value}
          onChange={e => { setValue(e.target.value); onChangeValue(e.target) }}
        />
        <span>
          {
            imageValue?.files[0]
              ? `${imageValue?.files[0]?.name?.length > 10 ? `${imageValue?.files[0]?.name?.substring(0, 10)}...` : imageValue?.files[0]?.name}`
              : 'Загрузить фото...'
          }
        </span>
        <img src={downloadIcon} alt="" />
      </StyledInput>
    </>
  )
}

const StyledLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  color: #D5D2D0;
  margin-bottom: 8px;
`;

const StyledInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 14px 19px;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid #4D4A48;
  border-radius: 10px;
  font-weight: 600;
  font-size: 17px;
  line-height: 130%;
  color: #FFFFFF;
  margin-bottom: 16px;
  position: relative;
  input {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
  }
`;