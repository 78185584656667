import { useState } from "react";
import styled from "styled-components";
import likeIcon from '../../assets/icons/like-filled.svg'
import { Info } from "./Info";

export const StatusCard = ({ likeNumber, color, info }) => {
    const [showInfo, setShowInfo] = useState(false);
    return (
        <StyledStatusCard>
            <StyledLikeNumber>
                <div></div>
                <img src={likeIcon} alt="like icon" />
                <span>{likeNumber}</span>
                {
                    info &&
                    <>
                        <span
                            className="info"
                            onMouseEnter={() => setShowInfo(true)}
                            onMouseLeave={() => setShowInfo(false)}
                        >
                            (?)
                        </span>
                        <Info text={info} visible={showInfo} />
                    </>
                }
            </StyledLikeNumber>
            <StyledUserName className={color}>
                alexeybychkov
            </StyledUserName>
        </StyledStatusCard>
    )
}
const StyledStatusCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(153px);
    border-radius: 10px;
    padding: 21px 24px 20px 15px;
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
        flex-direction: column;
        align-items: flex-start;
        .moderator-color {
            margin: 8px 0 0 42px;
        }
    }
    @media(min-width: 480px) {
        padding: 18px 20px 20px;
        &:last-child {
        margin-bottom: 0;
        flex-direction: row;
        align-items: flex-start;
        .moderator-color {
            margin: 0;
        }
    }
    }
    @media(min-width: 768px) {
        padding: 18px 30px;
    }
`;

const StyledLikeNumber = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    span {
        font-weight: 400;
        font-size: 15px;
        line-height: 130%;
        color: #FFFFFF;
        margin-left: 13px;
    }
    .info {
        margin-left: 5px;
        color: #27A6E5;
        cursor: pointer;
    }
    @media(min-width: 360px) {
        span {
            font-size: 17px;
        }
    }
`;

const StyledUserName = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    text-align: right;
    color: #FFFFFF;
    @media(min-width: 360px) {
        font-size: 15px;
    }
`;