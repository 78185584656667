import styled from "styled-components";
import closeIcon from '../../assets/icons/close.svg';

export const InfoModal = ({ onCloseModal }) => (
  <StyledInfoModal>
    <img
      src={closeIcon}
      onClick={() => onCloseModal(false)}
      alt=""
    />
    <div>Краткий экскурс</div>
    <p className="center">
      Мы приветствуем любые буквосочетания, но вероятность того, что ваш пост будет одобрен модераторами
      намного выше, если:
    </p>
    <p>
      А) В описании термина нету локальных шуточек, о которых знаете только вы и пару друзей из вашего класса
    </p>
    <p>
      Б) Вы можете использовать маты, но не когда они блять затмевают каждое ебаное слово нахуй блять.Они могут дополнять предложение, но не нужно заставлять читателя думать, что вы записывали это растолкование под диктовку, одновременно придерживая гвоздь косоглазому
      строителю с молотком.
    </p>
    <p>
      В) Вы понятно излагаете мысль и можете донести своё сообщение до обширной аудитории.Тем не менее, не обязательно пытаться включить в эту аудиторию весь алфавит человеческих поколений от A до Z, это не толковый словарь.
    </p>
    <p className="center">Щепетильная для нас тема – политкорректность.  <br />
      Забейте на неё хуй.
    </p>
    <span> Удачи!</span>
  </StyledInfoModal >
)

const StyledInfoModal = styled.div`
    margin: 57px 0;
    max-width: 680px;
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid #4D4A48;
    backdrop-filter: blur(236px);
    border-radius: 24px;
    padding: 69px 55px 92px 54px;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 17px;
    line-height: 130%;
    position: relative;
    div {
        font-size: 32px;
        margin-bottom: 30px;
        text-align: center;
    }
    p {
        margin-bottom: 10px;
        line-height: 130%;
    }
    span {
        display: block;
        text-align: center;
        color: #F4F7A1;
        margin-top: 30px;
    }
    .center {
        text-align: center;
    }
    img {
        position: absolute;
        top: 43px;
        right: 43px;
        cursor: pointer;
    }
`;