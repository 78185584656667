import styled from "styled-components";
import closeIcon from '../../assets/icons/closeGray.svg';

export const Menu = ({ options, active, onSelectOption, selected }) => {
  return (
    <StyledMenu visible={active}>
      <StyledReset
        src={closeIcon}
        alt=""
        onClick={() => onSelectOption(null)}
      />
      {
        options?.length > 0 &&
        options.map((opt, i) => (
          <StyledOption
            key={i}
            selected={selected === opt?.value}
            onClick={() => onSelectOption(opt)}
          >
            {opt?.label}
          </StyledOption>
        ))
      }
    </StyledMenu>
  )
}

const StyledMenu = styled.div`
  position: absolute;
  right: 0;
  top: 57px;
  width: max-content;
  background: #2A2A2A;
  border: 1px solid #4D4A48;
  border-radius: 10px;
  padding: 34px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  transition: all .3s;
  visibility: ${props => props.visible ? 'visible' : 'hidden'};
  opacity: ${props => props.visible ? '1' : '0'} ;
  z-index: 5;
`;

const StyledOption = styled.span`
  font-weight: 400;
  font-size: 17px;
  line-height: 130%;
  text-align: left;
  color: ${props => props.selected ? '#F4F7A1' : '#FFFFFF'};
  transition: all .3s;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  &:hover {
    color: #F4F7A1;
  }
`;

const StyledReset = styled.img`
  margin-bottom: 16px;
  transform: rotate(0deg) !important;
  position: absolute;
  top: 15px;
  right: 15px;
`;