import styled from "styled-components";
import { Links } from "./Links";
import { SocMedia } from "./SocMedia";

export const Footer = () => (
    <StyledFooter className="container">
        <Links />
        <SocMedia />
    </StyledFooter>
)

const StyledFooter = styled.div`
    padding: 40.5px 0;
    @media (min-width: 480px) {
        padding: 70px 0;
    }
`;

