import styled from "styled-components";

export const Admining = ({
    termId,
    verificationStatus,
    onEdit,
    onComment,
    onTermRemove,
}) => {
    const handleAdmining = async (status) => {
        onComment({ type: "admining", data: { termId, status, prevStatus: verificationStatus, onTermRemove } });
    }
    
    return (
        <StyledAdmining>
            <span
                onClick={() => handleAdmining('approve')}
            >
                Approve
            </span>
            <span
                onClick={() => handleAdmining('reject')}
            >
                Reject
            </span>
            <span
                onClick={() => onEdit()}>
                Edit
            </span>
        </StyledAdmining>
    )
}
const StyledAdmining = styled.div`
    font-weight: 600;
    font-size: 10px;
    line-height: 130%;
    color: #FFFFFF;
    span {
        cursor: pointer;
        transition: all .5s;
        margin-right: 5px;
        &:last-child{
            margin-right: 0px;
        }
        &:hover {
            color: #F4F7A1;
        }
    }
    @media(min-width: 360px) {
        font-size: 12px;
        span {
            margin-right: 10px;
            &:last-child{
                margin-right: 0px;
            }
        }
    }
    @media(min-width: 468px) {
        font-size: 14px;
    }
`;