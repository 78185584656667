import axios from "axios"
import { baseUrl } from "../../constants/api"

export const useDeleteUser = () => {
  const handleDeleteUser = ({ hash }) => {
    axios.delete(`${baseUrl}/api/users/delete-user`, {
      params: { hash }
    })
  }

  return { deleteUser: handleDeleteUser }
}