export const USERT_TERMS = 'userTerms';
export const TERMS = 'terms';
export const TOP_TERMS = 'topTerms';
export const CATEGORY_TERMS = 'categoryTerms';
export const TERMS_TO_DETERMINE = 'termsToDetermine';
export const TERM = 'term';
export const USER = 'user';
export const PROFILE = 'profile';
export const CATEGORIES = 'categories';
export const FAVOTITE_TERMS = 'favoriteTerms';
export const LIKED_TERMS = 'likedTerms';
export const DISLIKED_TERMS = 'dislikedTerms';
export const MODERATORS = 'moderators';
export const ARTICLES = 'articles';
export const BAGS = 'bags';
export const RECOMENDATIONS = 'recomendations';
export const COMPLAINS = 'complains';