import styled from "styled-components";
import infoIcon from '../../assets/icons/info.svg';

export const Info = ({ onShowInfo }) => {
  return (
    <StyledInfo onClick={() => onShowInfo(true)}>
      <span>Краткий экскурс по созданию поста</span>
      <img src={infoIcon} alt="" />
    </StyledInfo>
  )
}

const StyledInfo = styled.div`
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-decoration-line: underline;
    color: #FFFFFF;
    cursor: pointer;
    span {
        margin-right: 15px;
    }
`;  