import styled from "styled-components";
import { Title } from "./Title";
import { CloseIcon } from "./CloseIcon";
import { Input } from "../Input/Input";
import { Button } from "./Button";
import { useEffect, useState } from "react";
import { Select } from "../Select/Select";
import { TextEditor } from "../TextEditor/TextEditor";
import useCategories from "../../hooks/categories/useCategories";
import { useEditTerm } from "../../hooks/term/useEditTerm";


export const EditTerm = ({ visible, onClose, onPopupMessage }) => {
  const { editTerm } = useEditTerm();
  const { categories } = useCategories();
  // values
  const [name, setName] = useState('');
  const [category, setCategory] = useState(null);
  const [description, setDescription] = useState({ htmlValue: '', textValue: '' });
  const [example, setExample] = useState({ htmlValue: '', textValue: '' });

  // changing values
  const handleChangeName = (value) => setName(value);
  const handleChangeCategory = (value) => setCategory(value);
  const handleChangeDescription = (value) => setDescription(value);
  const handleChangeExample = (value) => setExample(value);

  // reset all values
  const handleResetValues = () => {
    setName('');
    setCategory(null);
    setDescription('');
    setExample('');
  }

  useEffect(() => {
    if (visible) {
      setName(visible?.title);
      setExample(visible?.sample);
      if (visible?.category_id) {
        setCategory({ label: visible?.category, value: visible.category_id })
      }
    } else {
      handleResetValues();
    }
  }, [visible])

  // submit
  const handleSubmit = async () => {
    const resp = await editTerm({
      termId: visible?.id,
      title: name,
      category_id: category?.value,
      description: description?.htmlValue,
      sample: example?.htmlValue
    });
    if (resp === 'success') {
      onClose();
      onPopupMessage('Термин успешно отредактировано')
    }
  }

  return (
    <StyledEditTermWrapper visible={visible}>
      <StyledModal>
        <CloseIcon onClose={onClose} />
        <Title />
        <Input
          label="Название"
          value={name}
          onValueChange={handleChangeName}
        />
        <Select
          label="Категория"
          options={categories.map(c => ({ label: c?.title, value: c.id }))}
          value={category}
          onValueChange={handleChangeCategory}
          classes="select"
        />
        {
          visible &&
          <>
            <TextEditor
              label="Описание"
              classes="input-desription"
              placeholder="5000 символов макс."
              onChange={handleChangeDescription}
              defaultValue={visible?.description}
              linkEditor
            />
            <TextEditor
              label="Пример"
              classes="input-example"
              placeholder="Необязательно"
              onChange={handleChangeExample}
              defaultValue={visible?.sample}
              linkEditor
            />
          </>
        }
        <Button
          onSubmit={handleSubmit}
          disabled={name?.length === 0 || description?.textValue?.trim()?.length === 0}
        />
      </StyledModal>
    </StyledEditTermWrapper>
  )
}

const StyledEditTermWrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(35, 35, 35, 0.94);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .5s;
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    opacity: ${props => props.visible ? '1' : '0'};
    .select{
        margin: 16px 0;
    }
    .input-desription {
        margin-bottom: 16px;
        height: max-content;
    }
    .input-example {
        margin-bottom: 18px;
        height: max-content;
        font-style: italic;
    }
`;

const StyledModal = styled.div`
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid #4D4A48;
  backdrop-filter: blur(236px);
  border-radius: 24px;
  padding: 30px 40px;
  box-sizing: content-box;
  width: 80vw;
  overflow-y: auto;
  max-height: 80%;
  position: relative;
  .input {
    textarea {
      height: max-content;
    }
  }
  @media(min-width: 660px) {
    padding: 24px 100px 29px;
    width: 340px;
  }
`;
