import { CookiesModal } from "../components/CookiesModal/CookiesModal";
import { Footer } from "../components/Footer/Footer";
import { Header } from "../components/Header/Header";
import { Aside } from "../components/Aside/Aside";
import React from "react";

export const PagesWrapper = ({ stepInfo, onPopupMessage, children }) => {
  return (
    <>
      {!stepInfo && <Aside />}
      {!stepInfo && <CookiesModal />}
      <Header onPopupMessage={onPopupMessage} />
      {React.cloneElement(children)}
      <Footer />
    </>
  )
}