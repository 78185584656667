import styled from "styled-components";

export const Button = ({ onToggleSideBar, active }) => (
    <StyledButton
        onClick={onToggleSideBar}
        active={active}
    >
        <span />
        <span />
        <span />
    </StyledButton>
)

const StyledButton = styled.div`
    bottom: 30px;
    cursor: pointer;
    ${props => props.active && 'transorm: translateY(5px);'}
    span {
        display: block;
        width: 25px;
        height: 0px;
        background: #F4F7A1;
        border: 1px solid #F4F7A1;
        border-radius: 4px;
        &:nth-child(1) {
            margin-bottom:  ${props => props.active ? '0px' : '5px'};
            ${props => props.active && 'transform: rotate(45deg);'}
        }
        &:nth-child(2) {
            margin-bottom:  ${props => props.active ? '0px' : '5px'};
            ${props => props.active && 'display: none;'}
        }
        &:nth-child(3) {
            ${props => props.active && 'transform: rotate(-45deg) translate(0px, -2px);'}
        }
    }
`;