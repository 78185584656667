import styled from "styled-components";
import { AddTerm } from "./AddTerm";
import { Title } from "./Title";

export const Info = ({ onCreateTermToDeterm, isAddButton }) => (
  <StyledInfo>
    <StyledTop>
      <Title />
      {isAddButton && <AddTerm onCreateTermToDeterm={onCreateTermToDeterm} />}
    </StyledTop>
    <StyledDescription>
      Эти слова и выражения будут уместны на нашем ресурсе. Будьте первым, кто даст им определение
    </StyledDescription>
  </StyledInfo>
)

const StyledInfo = styled.div`
    padding: 24px 10px 20px;
    @media (min-width: 480px) {
        padding: 20px;
    }
    @media (min-width: 768px) {
        padding: 40px 23px 28px;
    }
`;

const StyledTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    @media (min-width: 768px) {
        margin-bottom: 16px;
    }
`;

const StyledDescription = styled.div`
    font-weight: 500;
    font-size: 17px;
    line-height: 130%;
    color: #D5D2D0;
    @media (min-width: 768px) {
        padding-right: 88px;
    }
    @media (min-width: 768px) {
        font-weight: 700;
    }
    @media (min-width: 1000px) {
        padding-right: 143px;
    }
`;