import styled from "styled-components";
import loadingIcon from "../../assets/icons/loading.svg";

export const Spinner = () => (
  <StyledSpinner>
    <img src={loadingIcon} alt="loading more spinner" />
  </StyledSpinner>
)

const StyledSpinner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px auto;
    text-align: center;
    opacity: .5;
    img {
        display: block;
        height: 60px;
        animation: spinner 2.5s linear infinite, display 1s;
        }
    @keyframes spinner {
        to {
            transform: rotate(360deg);
        }
    }
`;