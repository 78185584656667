import axios from "axios";
import { useQueryClient } from "react-query";
import { baseUrl, headers } from "../../constants/api";
import { RECOMENDATIONS } from "../../constants/types";

export const useRemoveRecomendation = () => {
    const client = useQueryClient();

    const handleRemoveRecomendation = async (id,successPopup) => {
        const resp = await axios.delete(`${baseUrl}/api/recommendation/delete-recommendation`, {
            headers: headers(),
            params: { recommendat_id: id }
        })

        if (resp?.status === 200) {
          const recommendationsData = client.getQueryData(RECOMENDATIONS);
          if (recommendationsData) {
              const updatedRecomendationsData = recommendationsData;
              updatedRecomendationsData.data = updatedRecomendationsData.data.filter(recommendation => recommendation.id !== id);
              client.setQueriesData(RECOMENDATIONS, updatedRecomendationsData);
              successPopup("Рекомендация успешно удалена")
          }
            return resp;
        }
    }

    return { removeRecomendation: handleRemoveRecomendation}
}