import styled from "styled-components";

export const Button = ({ onSubmit, disabled }) => (
    <StyledButton
        disabled={disabled}
        onClick={() => !disabled && onSubmit()}
    >
        Изменить
    </StyledButton>
)


const StyledButton = styled.div`
    padding: 17px 74px;
    width: 100%;
    height: 60px;
    background: ${props => props.disabled ? 'rgba(242, 133, 105, 0.5)' : '#F28569'};
    border-radius: 10px;
    margin: 24px 0 0;
    font-weight: 600;
    font-size: 17px;
    line-height: 130%;
    letter-spacing: 0.04em;
    color: ${props => props.disabled ? 'rgba(255, 255, 255, 0.5);' : '#FFFFFF'}; 
    text-align: center;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'}; 
`;