import styled from "styled-components";
import { SocMediaItem } from "./SocMediaItem";
import telegramIcon from "../../assets/icons/socmedia/telegram.svg";
import instagramIcon from "../../assets/icons/socmedia/instagram.svg";
import twitterIcon from "../../assets/icons/socmedia/twitter.svg";
import pinterestIcon from "../../assets/icons/socmedia/pinterest.svg";

export const SocMedia = () => (
    <StyledSocMedia>
        <SocMediaItem
            logo={telegramIcon}
            link="https://t.me/speach_uk"
            type="telegram"
        />
        <SocMediaItem
            logo={instagramIcon}
            link="https://www.instagram.com/speach.uk/"
            type="instagram"
        />
        <SocMediaItem
            logo={twitterIcon}
            link="https://twitter.com/SpeachCommunity"
            type="twitter"
        />
        <SocMediaItem
            logo={pinterestIcon}
            link="https://www.pinterest.co.uk/speachcommunity/_created"
            type="pinteres"
        />
    </StyledSocMedia>
)

const StyledSocMedia = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    a {
        margin-right: 10px;
        &:last-child {
            margin: 0;
        }
    }
    @media (min-width: 480px) {
        a {
            margin-right: 20px;
        }
    }
`;