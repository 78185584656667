import axios from "axios"
import { baseUrl } from "../../constants/api"

export const useRegistration = () => {
  const registration = (data) => {
    return axios.post(`${baseUrl}/api/auth/register`, data)
      .then(resp => resp)
      .catch(error => error.response)
  }

  return { registration }
}