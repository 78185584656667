import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import styled from "styled-components";

export const Description = ({ active, text }) => {
    const containerRef = useRef();
    const contentRef = useRef();
    const [height, setHeight] = useState('0px');

    const handleToggle = (status) => {
        const containerHeight = containerRef?.current?.offsetHeight;
        const contentHeight = contentRef?.current?.offsetHeight + 28;
        if (status) {
            setHeight(`${contentHeight}px`);
            setTimeout(() => setHeight(null), 300);
        } else {
            setHeight(`${containerHeight === 0 ? 0 : contentHeight}px`);
            setTimeout(() => setHeight('0px'), 50)

        }
    }
    useEffect(() => {
        handleToggle(active)
    }, [active])

    return (
        <StyledDescription active={active} ref={containerRef} height={height}>
            <div ref={contentRef}>
                {React.cloneElement(text)}
            </div>
        </StyledDescription>
    )
}

const StyledDescription = styled.div`
    padding: ${props => props.active ? '24px  0 4px' : '0px'};
    font-family: 'Mont';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 160%;
    color: #FFFFFF;
    transition: all .3s;
    height: ${props => props.height};
    overflow: hidden;
`;