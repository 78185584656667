import styled from "styled-components";

export const CancelButton = ({onCancel}) => (
    <StyledCancelButton onClick={() => onCancel(false)}>
        Отклонить
    </StyledCancelButton>
)

const StyledCancelButton = styled.div`
    font-weight: 600;
    font-size: 10px;
    line-height: 130%;
    color: #FFFFFF;
    margin: 0 0 0 10px;
    cursor: pointer;
    transition: all .5s;
    &:hover {
        color: #F4F7A1;
    }
    @media (min-width: 350px) {
        margin: 0 0 0 10px;
        font-size: 11px;
    }
    @media (min-width: 600px) {
        margin: 0 0 0 18px;
        font-size: 17px;
    }
`;