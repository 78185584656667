import styled from "styled-components";

export const Title = ({ title }) => (
  <StyledTitle>
    {title}
  </StyledTitle>
)

const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 17px;
  line-height: 130%;
  text-align: center;
  color: #FFFFFF;
  padding:  0 25px;
  @media(min-width: 480px) {
    font-size: 24px;
    padding: 0;
  }
`;