import styled from "styled-components";

export const Title = () => (
    <StyledTitle>
        Нужно определить
        <span>💭</span>
    </StyledTitle>
)

const StyledTitle = styled.div`
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: #F4F7A1;
    width: 50%;
    word-break: break-word;
    span {
        display: inline;
        margin-left: 7px;
    }
    @media (min-width: 350px) {
        width: 60%;
    }
    @media (min-width: 768px) {
        font-size: 32px;
    }

`;