import { useEffect } from "react";
import styled from "styled-components";
import { CategoryCard } from "../components/CategoryCard/CategoryCard";
import { Spinner } from "../components/Spinner/Spinner";
import { TermsToDetermined } from "../components/TermsToDetermined/TermsToDetermined";
import useCategories from "../hooks/categories/useCategories";
import { useTermsToDetermine } from "../hooks/termsToDetermine/useTermsToDetermine";
import SEO from "../helpers/SEO";
// import React from "@types/react";

export const Categories = ({ moderator, admin, onPopupMessage }) => {
  const { categories } = useCategories();
  const { refetch } = useTermsToDetermine();

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <SEO
        title="Все категории"
        description="Kатегории"
        name="Kатегории"
        type="website"
        url={window.location.href}
        twitterUrl={window.location.href}
        ogUrl={window.location.href}
      />
      <div>
        <StyledContent className="container">
          {
            categories?.length === 0
              ? <Spinner />
              : <>
                {
                  categories.map((category, i) => (
                    <CategoryCard
                      key={i}
                      categoryId={category?.slug}
                      title={category?.title}
                      termsNumber={category?.terms_count}
                    />
                  ))
                }</>
          }
          <TermsToDetermined onPopupMessage={onPopupMessage} />
        </StyledContent>
      </div>
    </>
  )
}

const StyledContent = styled.div`
  padding: 0 20px;
  margin-top: 25px;
  margin-top: 25px;
  @media(min-width: 480px) {
    margin-top: 42px;
    padding: 0;
  }
  @media(min-width: 768px) {
    font-size: 26px;
  }
  @media (min-width: 1000px) {
    margin-top: 32px;
  }
`;