import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { RandomTerm } from "./RandomTerm";

export const Navigation = () => (
  <StyledNavigation>
    <NavLink to="/category/english">English</NavLink>
    <NavLink to="/category/gen-z">Gen Z</NavLink>
    <NavLink to="/category/drugs">Drugs</NavLink>
    <NavLink to="/category/18">18+</NavLink>
    <NavLink to="/categories">Все категории</NavLink>
    <RandomTerm />
  </StyledNavigation>
)

const StyledNavigation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    font-weight: 500;
    font-size: 17px;
    line-height: 130%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #F28569;
    &:not(:last-child) {
        margin-bottom: 20px;
    }
  }
`;