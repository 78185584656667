import styled from "styled-components";

export const Label = ({label, error}) => (
    <StyledLabel error={error}>
        {error ?? label}
    </StyledLabel>
)

const StyledLabel = styled.label`
    display: block;
    margin-bottom: 8px;
    transition: all .5s;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: ${props => props.error ? '#F28569': '#D5D2D0'};
`;