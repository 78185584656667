import styled from "styled-components";

export const BackgroundGradient = ({ mainCard }) => (
  <>
    <StyledBackground1 />
    {!mainCard && <StyledBackground2 />}
  </>
)

const StyledBackground1 = styled.div`
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  position: absolute;
  left: -20px;
  top: 20px;
  border-radius: 100%;
  background: #573A34;
  opacity: 0.44;
  filter: blur(20px);
`;

const StyledBackground2 = styled.div`
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  background: #F4F7A1;
  opacity: 0.08;
  filter: blur(20px);
`;