import styled from "styled-components";

export const Option = ({ label, active, onSelectOption }) => (
    <StyledOption active={active} onClick={onSelectOption}>
        {label}
    </StyledOption>
)

const StyledOption = styled.div`
    font-weight: 700;
    font-size: 15px;
    line-height: 130%;
    transition: all .5s;
    color: ${props => props.active ? '#F4F7A1' : '#FFFFFF'};
    margin-bottom: 10px;
    cursor: pointer;
    &:last-child {
        margin-bottom: 0;
    }
    &:hover {
        color: #F4F7A1;
    }
`;