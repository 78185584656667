import styled from "styled-components";
import closeIcon from '../../assets/icons/close.svg';

export const CloseIcon = ({ onClose }) => (
    <StyledCloseIcon
        src={closeIcon}
        alt="close icon"
        onClick={onClose}
    />
)

const StyledCloseIcon = styled.img`
    position: absolute;
    top: 15px;
    right: 15px;
    height: 14px;
    cursor: pointer;
`;