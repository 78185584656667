import axios from "axios";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { baseUrl, headers } from "../../constants/api";
import {  RECOMENDATIONS } from "../../constants/types";

export const useRecomendation = () => {
  const client = useQueryClient();
  const [loadingMore, setLoadingMore] = useState(false);

    const { data: recomendation = [] } = useQuery(
        [RECOMENDATIONS],
        async () => (await axios.get(`${baseUrl}/api/recommendation/get-recommendations`, {
          headers: headers(),
          params: {
            page: 1,
            perPage: 15
          }
        })
        )?.data?.response
    );

    const handleLoadMoreRecomendation = async () => {
      setLoadingMore(true);
      let termsData = client.getQueryData(RECOMENDATIONS);
      termsData = termsData ?? {};
      const resp = await axios.get(`${baseUrl}/api/recommendation/get-recommendations`, {
          headers: headers(),
          params: {
              page: termsData?.current_page + 1,
              perPage: 15
          }
      })
      const nextPage = resp?.data?.response;
      let updatedTerms = nextPage;
      updatedTerms.data = [...termsData.data, ...nextPage.data];
      client.setQueriesData(RECOMENDATIONS, updatedTerms);
      setLoadingMore(false);
  }
    
  return {
    recomendation,
    onLoadMoreRecomendation: handleLoadMoreRecomendation,
    loadingMoreRecomendation: loadingMore
}
}

