import axios from "axios"
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom"
import { baseUrl, headers } from "../../constants/api"
import { USER } from "../../constants/types";

export const useLogout = () => {
    const client = useQueryClient();
    const navigate = useNavigate();

    const logout = () => {
        // delete token 
        localStorage.removeItem('token');
        localStorage.removeItem('CUID');
        // delete user data
        client.setQueriesData(USER, null);
        // navigate to dashboard
        navigate('/');
        // logout request
        axios.post(`${baseUrl}/api/auth/logout`, null, {
            headers: headers()
        });
    }

    return { logout }
}