import styled from "styled-components";
import successIcon from '../../assets/icons/success.svg';

export const Popup = ({ visible, message, noIcon }) => (
  <StyledPopup visible={visible}>
    {!noIcon && <img src={successIcon} alt="" />}
    {message}
  </StyledPopup>
)

const StyledPopup = styled.div`
    position: fixed;
    bottom: 5%;
    right: 10%;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px 20px;
    background: rgba(244, 247, 161, 0.112);
    border: 1px solid #4D4A48;
    backdrop-filter: blur(10px);
    z-index: 1000000; 
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    color: #D5D2D0;
    -webkit-box-shadow: -8px 4px 51px 20px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: -8px 4px 51px 20px rgba(0, 0, 0, 0.3);
    box-shadow: -8px 4px 51px 20px rgba(0, 0, 0, 0.3);
    transition: all .5s;
    transform: translateY(${props => props.visible ? ' 0px ' : '30px'});
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    opacity:  ${props => props.visible ? '1' : '0'};
    max-width: 80%;
    img {
        height: 30px;
        margin-right: 5px;
    }
    @-moz-document url-prefix() {
        background: rgba(244, 247, 161, 0.264);
    }
`;