import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useUser } from "../../hooks/user/useUser";

export const AddButton = ({ termTitle, onPopupMessage }) => {
  const navigate = useNavigate();
  const { user } = useUser();

  const handleToggleCreatingTerm = () => {
    if (user) {
      navigate(`/create-term/${termTitle}`)
    } else {
      const currentPathName = window.location.href.split('#')[1];
      localStorage.setItem('prevUrl', currentPathName);
      onPopupMessage('Чтобы добавить пост, нужно зарегистрироваться', true);
      navigate(`/auth/registration`)
    }
  }

  return (
    <StyledAddButton onClick={() => handleToggleCreatingTerm()}>
      Добавить этот термин +
    </StyledAddButton>
  )
}

const StyledAddButton = styled.div`
  padding: 8px 16px;
  max-width: 363px;
  height: 50px;
  border: 1px solid #F4F7A1;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  cursor: pointer;
  transition: all .5s;
  &:hover {
    background: #F4F7A1;
    color: #333333;
  }
`;