import React from "react";
import styled from "styled-components";

export const Message = ({ children }) => (
    <StyledMessage>
        {React.cloneElement(children)}
    </StyledMessage>
)

const StyledMessage = styled.div`
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid #4D4A48;
    backdrop-filter: blur(236px);
    border-radius: 24px;
    padding: 28px 27px;
    font-weight: 700;
    font-size: 12px;
    line-height: 130%;
    color: #FFFFFF;
    @media(min-width: 360px){
        font-size: 17px;
    }
`;