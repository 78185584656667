import axios from "axios";
import { useQueryClient } from "react-query";
import { baseUrl, headers } from "../../constants/api";
import { ARTICLES } from "../../constants/types";

export const useEditArticle = () => {
    const client = useQueryClient();

    const handleEditArticle = async ({ article_id, description, link, image }) => {
        const resp = await axios.post(`${baseUrl}/api/articles/update-article`, {image}, {
            headers: headers(),
            params: {article_id, description, link}
        })

        if (resp?.status === 200) {
            const updatedArticle = resp?.data?.response;
            const articlesData = client.getQueryData(ARTICLES) ?? [];
            const updatedArticles = articlesData?.map(article => article?.id === article_id ? ({...updatedArticle}) : article)
            client.setQueryData(ARTICLES, updatedArticles)
            return 'success';
        }

    }

    return { editArticle: handleEditArticle }
}