import styled from "styled-components";

export const SubmitButton = ({
    text,
    onChangePassword,
    changed,
    disabled
}) => (
    <StyledSubmitButton
        disabled={disabled}
        onClick={() => !disabled && onChangePassword()}
        changed={changed}
        className="not-select"
    >
        <span> {text}</span>
    </StyledSubmitButton>
)

const StyledSubmitButton = styled.div`
    width: 100%;
    height: 60px;
    background: ${props => props.disabled ? 'rgba(242, 133, 105, 0.5)' : '#F28569'};
    border-radius: 10px;
    margin-top: ${props => props.changed ? '60px' : '24px'};
    font-weight: 600;
    font-size: 17px;
    line-height: 130%;
    letter-spacing: 0.04em;
    color: ${props => props.disabled ? 'rgba(255, 255, 255, 0.5);' : '#FFFFFF'}; 
    text-align: center;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'}; 
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        width: max-content;
    }
`;  