import axios from "axios";
import { useQueryClient } from "react-query";
import { baseUrl, headers } from "../../constants/api";
import { ARTICLES } from "../../constants/types";

export const useCreateArticle = () => {
    const client = useQueryClient();

    const handleCreateArticle = async ({ description, link, image }) => {
        const resp = await axios.post(`${baseUrl}/api/articles/add-article`, {image}, {
            headers: headers(),
            params: {link, description}
        })

        if (resp?.status === 200) {
            const createdArticle = resp?.data?.response;
            const articlesData = client.getQueryData(ARTICLES) ?? [];
            client.setQueryData(ARTICLES, [createdArticle, ...articlesData]);
            return resp?.status === 200;
        }

    }

    return { createArticle: handleCreateArticle }
}