import styled from "styled-components";
import {useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import { useGetRandomTerm } from "../../hooks/term/useGetRandomTerm"
import getRandomTermIcon from "../../assets/images/random-min.png";
import getRandomTermVideo from "../../assets/videos/random-4.2.MP4";
import getRandomTermVideo1 from "../../assets/videos/random-gif.gif";

export const RandomTerm = ({ isDashboard }) => {
  const [getingTerm, setGettingTerm] = useState(false);
  const gifRef = useRef(null);
  const navigate = useNavigate();
  const { getRandomTerm } = useGetRandomTerm();

  const handleGetRandomTerm = async () => {
    setGettingTerm(true);
    const term = await getRandomTerm();
    if (term && term?.status === 200) {
      const termSlug = term?.data?.response?.slug;
      setTimeout(() => navigate(`/opredelenie-slova/${termSlug}`), 2000)
    }
  }

  // if (isDashboard) {
  //   setTimeout(() => {
  //     gifRef.current.src = getRandomTermVideo1
  //   }, 2400)
  // }

  return (
    <StyledRandomTerm>
      <StylesRandomTermImg>
        <div className="random-term-wrapper">
          {
            getingTerm
              ? <video
                src={getRandomTermVideo}
                autoPlay
                playsinline
                muted
                onEnded={() => setGettingTerm(false)}
              />
              : <div
                className="random-term-wrapper"
              >
                {
                  isDashboard && !window.matchMedia("(max-width: 1000px)").matches
                    ? <img
                      src={getRandomTermVideo1}
                      ref={gifRef}
                      onClick={handleGetRandomTerm}
                      alt="random"
                    />
                    : <img
                      src={getRandomTermIcon}
                      alt=""
                      onClick={handleGetRandomTerm}
                    />
                }
              </div>
            // <>
            //   {
            //     hover
            //       ? <video
            //         src={getRandomTermVideoHover}
            //         autoPlay
            //         playsinline
            //         muted
            //         onClick={handleGetRandomTerm}
            //         onEnded={() => setHover(false)}
            //       />
            //       : <img
            //         src={getRandomTermIcon}
            //         alt=""
            //         onClick={handleGetRandomTerm}
            //       />
            //   }
            // </>
          }
        </div>
      </StylesRandomTermImg>
      {/* {
        getingTerm
          ? <StylesRandomTermVideo
            src={getRandomTermVideo}
            autoPlay
            playsinline
            muted
            onEnded={() => setGettingTerm(false)}
            onClick={handleGetRandomTerm}
          />
          : <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            className="random-img-wrapper"
          >
            {
              (hover && isDashboard)
                ? <StylesRandomTermVideo
                  src={getRandomTermVideoHover}
                  autoPlay
                  playsinline
                  muted
                  onClick={handleGetRandomTerm}
                />
                : <img
                  src={getRandomTermIcon}
                  alt=""
                  onClick={handleGetRandomTerm}
                />
            }
          </div>
      } */}
    </StyledRandomTerm>
  )
}

const StyledRandomTerm = styled.div`
  display: none;
  img, video {
    /* height: 56px; */
    cursor: pointer;
    display: none;
  }
  .random-img-wrapper {
    min-width: 115px;
  }
  @media(min-width: 1000px) {
    display: block;
    margin-right: 12px;
    img, video {
      display: block;
    }
  }
`;

const StylesRandomTermImg = styled.div`
  display: flex;
  align-items: end;
  height: 25px;
  img, video {
    height: 50px;
    width: 89px;
  }
  .random-term-wrapper {
    height: 46px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 84px;
  }
`;

