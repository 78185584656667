import styled from "styled-components";

export const BackgroundGradient = () => (
    <>
        <StyledBackground1 />
        <StyledBackground2 />
    </>
)

const StyledBackground1 = styled.div`
    width: 430px;
    height: 430px;
    flex-shrink: 0;
    position: absolute;
    left: 20vw;
    top: 10vw;
    border-radius: 100%;
    background: #573A34;
    opacity: 0.44;
    filter: blur(121px);
    z-index: -1;
`;

const StyledBackground2 = styled.div`
    width: 430px;
    height: 430px;
    flex-shrink: 0;
    position: absolute;
    right:20vw;
    top: 30vw;
    border-radius: 100%;
    background: #F4F7A1;
    opacity: 0.08;
    filter: blur(171px);
    z-index: -1;
`;