import styled from "styled-components";

export const Message = ({ text }) => (
    <StyledMessage>
        {text}
    </StyledMessage>
)

const StyledMessage = styled.div`
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px 20px;
    background: rgba(244, 247, 161, 0.112);
    border: 1px solid #4D4A48;
    backdrop-filter: blur(10px);
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 15px;
    line-height: 140%;
    text-align: center;
    color: #D5D2D0;
    -webkit-box-shadow: -8px 4px 51px 20px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: -8px 4px 51px 20px rgba(0, 0, 0, 0.3);
    box-shadow: -8px 4px 51px 20px rgba(0, 0, 0, 0.3);
    transition: all .5s;
    @-moz-document url-prefix() {
        background: rgba(244, 247, 161, 0.264);
    }
`;