import styled from "styled-components";
import logo2 from "../../assets/videos/logo-2.MP4"

export const Loader = () => {
  return (
    <StyledLoader>
      <video
        src={logo2}
        loop
        autoPlay
        playsinline
        muted
      />
    </StyledLoader>
  )
}

const StyledLoader = styled.div`
  background: #000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20000;
  video {
    height: 200px;
    transition: all .4s;
  }
`;
