import styled from "styled-components";

export const Title = ({ title, sended }) => (
    <StyledTitle sended={sended}>
        {title}
    </StyledTitle>
)

const StyledTitle = styled.div`
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: #FFFFFF;
    margin-bottom: 8px;
    text-align: center;
    @media (min-width: 360px) {
        font-size: 32px;
    }
`;