import axios from "axios";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { baseUrl, headers } from "../../constants/api";
import { USER } from "../../constants/types";


export const useUser = () => {
  const [loading, setLoading] = useState(false);
  const client = useQueryClient();

  const { data: user = null, refetch } = useQuery(
    [USER],
    async () => localStorage.getItem('token') && (await axios.get(`${baseUrl}/api/user/get-user`, {
      headers: headers()
    })).data?.response,
    {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchInterval: false,
    }
  );

  const handleLoadMoreFavorites = async () => {
    setLoading(true);
    let userData = client.getQueryData(USER);
    userData = userData ?? {};
    const resp = await axios.get(`${baseUrl}/api/user/get-user`, {
      headers: headers(),
      params: { page: userData?.favorites?.current_page + 1 }
    })
    const nextPage = resp?.data?.response?.favorites;
    let updatedUserData = userData;
    updatedUserData.favorites = ({ ...nextPage, data: [...userData?.favorites?.data, ...nextPage.data] })
    client.setQueryData(USER, updatedUserData);
    setLoading(false);
  }

  const handleLoadMoreSuggestion = async () => {
    setLoading(true);
    let userData = client.getQueryData(USER);
    userData = userData ?? {};
    const resp = await axios.get(`${baseUrl}/api/user/get-user`, {
      headers: headers(),
      params: { page: userData?.suggestion?.current_page + 1 }
    })
    const nextPage = resp?.data?.response?.suggestion;
    let updatedUserData = userData;
    updatedUserData.suggestion = ({ ...nextPage, data: [...userData?.suggestion?.data, ...nextPage.data] })
    client.setQueryData(USER, updatedUserData);
    setLoading(false);
  }

  const handleLoadMoreApproveSuggestion = async () => {
    setLoading(true);
    let userData = client.getQueryData(USER);
    userData = userData ?? {};
    const resp = await axios.get(`${baseUrl}/api/user/get-user`, {
      headers: headers(),
      params: { page: userData?.suggestion?.approve?.current_page + 1 }
    })
    const nextPage = resp?.data?.response?.suggestion?.approve;
    let updatedUserData = userData;
    updatedUserData.suggestion.approve = ({ ...nextPage, data: [...userData?.suggestion?.approve?.data, ...nextPage.data] })
    client.setQueryData(USER, updatedUserData);
    setLoading(false);
  }

  const handleLoadMoreRejectedSuggestion = async () => {
    setLoading(true);
    let userData = client.getQueryData(USER);
    userData = userData ?? {};
    const resp = await axios.get(`${baseUrl}/api/user/get-user`, {
      headers: headers(),
      params: { page: userData?.suggestion?.rejected?.current_page + 1 }
    })
    const nextPage = resp?.data?.response?.suggestion?.rejected;
    let updatedUserData = userData;
    updatedUserData.suggestion.rejected = ({ ...nextPage, data: [...userData?.suggestion?.rejected?.data, ...nextPage.data] })
    client.setQueryData(USER, updatedUserData);
    setLoading(false);
  }

  const handleLoadMoreExpectedSuggestion = async () => {
    setLoading(true);
    let userData = client.getQueryData(USER);
    userData = userData ?? {};
    const resp = await axios.get(`${baseUrl}/api/user/get-user`, {
      headers: headers(),
      params: { page: userData?.suggestion?.expected?.current_page + 1 }
    })
    const nextPage = resp?.data?.response?.suggestion?.expected;
    let updatedUserData = userData;
    updatedUserData.suggestion.expected = ({ ...nextPage, data: [...userData?.suggestion?.expected?.data, ...nextPage.data] })
    client.setQueryData(USER, updatedUserData);
    setLoading(false);
  }

  return {
    user,
    refetchUser: refetch,
    onLoadMoreFavorites: handleLoadMoreFavorites,
    onLoadMoreSuggestion: handleLoadMoreSuggestion,
    onLoadMoreApproveSuggestion: handleLoadMoreApproveSuggestion,
    onLoadMoreRejectedSuggestion: handleLoadMoreRejectedSuggestion,
    onLoadMoreExpectedSuggestion: handleLoadMoreExpectedSuggestion,
    loadingMore: loading
  }
}