import styled from "styled-components";

export const MaxLength = ({maxLength, currentLength}) => (
    <StyledMaxLength className="not-select">
        {maxLength - currentLength}/{maxLength}
    </StyledMaxLength>
)

const StyledMaxLength = styled.div`
    position: absolute;
    right: 4px;
    bottom: 11px;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    color: #615956;
`;