import axios from "axios"
import { useQueryClient } from "react-query";
import { baseUrl } from "../../constants/api"
import { USER } from "../../constants/types";

export const useRegistrationByTelegram = () => {
    const client = useQueryClient();
    const registrationByTelegram = (data) => {
        return axios.post(`${baseUrl}/api/auth/telegram-authorization`, data)
            .then(resp => {
                const userData = resp?.data?.response?.user;
                const token = resp?.data?.response?.access_token;
                const userId = btoa(userData?.id);

                localStorage.setItem('CUID', userId);
                localStorage.setItem('token', token)
                client.setQueriesData(USER, userData);
                return resp;
            })
            .catch(error => ({data: {errors: {login: "Пользователь с таким логином уже зарегистрирован"}}}))
    }

    return { registrationByTelegram }
}