import { useRef } from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { AboutStatus } from "../components/AboutStatus/AboutStatus";
import { ArticleCard } from "../components/ArticleCard/ArticleCard";
import { Comment } from "../components/Comment/Comment";
import { EditTerm } from "../components/EditTerm/EditTerm";
import { FeedBackCard } from "../components/FeedbackCard/FeedBackCard";
import { FilterSuggestedTermsBar } from "../components/FilterSuggestedTermsBar/FilterSuggestedTermsBar";
import { LoadMore } from "../components/LoadMore/LoadMore";
import { ModeratorsTable } from "../components/ModeratorsTable/ModeratorsTable";
import { NoTermins } from "../components/NoTermins/NoTermins";
import { ProfileCard } from "../components/ProfileCard/ProfileCard";
import { ProfileStatisticCard } from "../components/ProfileStatisticCard/ProfileStatisticCard";
import { Spinner } from "../components/Spinner/Spinner";
import { TermCard } from "../components/TermCard/TermCard";
import { ARTICLES, BAGS, COMPLAINS, FAVORITES, RECOMENDATIONS, MODERATORS, MY_TERMS, SUGGESTIONS } from "../constants/profileTermsSection";
import { ADMIN, MODERATOR } from "../constants/roles";
import { APPROVE, EXPECTED, REJECTED } from "../constants/suggestionStatus";
import { useGiveModaratorRole } from "../hooks/admin/useGiveModaratorRole";
import { useRejectComment } from "../hooks/admin/useRejectComment";
import { useArticles } from "../hooks/article/useArticles";
import { useBags } from "../hooks/feedback/useBags";
import { useComplaints } from "../hooks/feedback/useComplaints";
import { useRecomendation } from "../hooks/feedback/useRecomendation";
import { useRemoveBag } from "../hooks/feedback/useRemoveBag";
import { useRemoveComplaint } from "../hooks/feedback/useRemoveComplaint";
import { useRemoveRecomendation } from "../hooks/feedback/useRemoveRecomendation";
import useProfile from "../hooks/profile/useProfile";
import useGetUserTerms from "../hooks/term/useGetUserTerms";
import { useUser } from "../hooks/user/useUser";
import SEO from "../helpers/SEO";

export const Profile = ({ onPopupMessage }) => {
  const {
    user,
    refetchUser,
    onLoadMoreFavorites,
    onLoadMoreSuggestion,
    onLoadMoreApproveSuggestion,
    onLoadMoreRejectedSuggestion,
    onLoadMoreExpectedSuggestion,
    loadingMore
  } = useUser();
  const { profile } = useProfile();
  const { giveModeratorRole } = useGiveModaratorRole();
  const { userId: activeUser } = useParams();
  const { rejectComment } = useRejectComment();
  const { articles } = useArticles();
  const {
    bags,
    onLoadMoreBags,
    loadingMoreBags
  } = useBags();
  const {
    complains,
    onLoadMoreComplaints,
    loadingMoreComplaints
  } = useComplaints();
  const {
    recomendation,
    onLoadMoreRecomendation,
    loadingMoreRecomendation
  } = useRecomendation();
  const { removeBag } = useRemoveBag();
  const { removeComplaint } = useRemoveComplaint();
  const { removeRecomendation } = useRemoveRecomendation();
  // terms opened profile
  const { userTerms, onLoadMoreUserTerms, loadingMoreUserTerms } = useGetUserTerms();
  // toggle info modal about status
  const [statusModal, setStatusModal] = useState(false);
  // comment after admin reject term
  const [commentModal, setCommentModal] = useState(false);
  // edit term by admin modal toggle
  const [adminTermEdit, setAdminTermEdit] = useState(false);
  // profile terms section (my terms, saved) for moderators/and admins (suggested)
  const [section, setSection] = useState(MY_TERMS);
  // admin filter suggestion status
  const [adminSuggestionActive, setAdminuggestionActive] = useState(EXPECTED);
  // is loaded
  const isLoaded = useRef(false);

  // change admin suggestion filter by term status
  const handleChangeAdminActiveSuggestionStatus = (option) => setAdminuggestionActive(option);

  // on toggle comment modal
  const toggleCommentModal = async (value, isSuccess) => {
    if (!value && commentModal?.type === 'givingModerator' && !isSuccess) {
      giveModeratorRole({ id: commentModal.id, status: commentModal?.status });
    } else if (!value && commentModal?.type === 'admining' && !isSuccess) {
      const resp = await rejectComment({ termId: commentModal?.data?.termId, status: commentModal?.data?.status });
      if (resp === 'success') {
        commentModal?.data?.onTermRemove(commentModal?.data?.termId);
        onPopupMessage(`Термин успешно ${commentModal?.data?.status === 'approve' ? 'одобрено' : 'не одобрено'}`);
      }
    }
    setCommentModal(value);
  };

  // change terms section
  const handleChangeSection = (value) => {
    setSection(value);
    refetchUser();
  }

  // set toggle info modal about status
  const toggleStatusModal = (value) => setStatusModal(value);

  // toggle edit modal term by admin 
  const toggleAdminTermEdit = (value) => setAdminTermEdit(value);

  useEffect(() => {
    refetchUser();
    // eslint-disable-next-line
  }, [])

  // checking is it admin
  useEffect(() => {
    if (user && (user?.role_id === ADMIN) && (activeUser === user?.slug) && !isLoaded.current) {
      setSection(SUGGESTIONS);
      setAdminuggestionActive(EXPECTED);
      isLoaded.current = true;
    } else {
      setSection(MY_TERMS)
    }
  }, [activeUser, user]);

  return (
    <>
      <SEO title={`Speach – ${profile?.login}`} />
      <div>
        <AboutStatus
          visible={statusModal}
          onClose={() => toggleStatusModal(false)}
          profileLikes={profile?.statistic?.likes}
          profileRole={profile?.role_id}
        />
        <EditTerm
          visible={adminTermEdit}
          onClose={() => toggleAdminTermEdit(false)}
          onPopupMessage={onPopupMessage}
        />
        <Comment
          visible={commentModal}
          onClose={toggleCommentModal}
          onPopupMessage={onPopupMessage}
        />
        <StyledContent className="container container--profile">
          {
            profile
              ? <>
                <StyledHeader>
                  <ProfileCard
                    classes="profile-card"
                    editBio
                    footer
                    profileName={profile?.login}
                    profileRole={profile?.role_id}
                    profileBio={profile?.bio}
                    profileId={profile?.id}
                    profileLikes={profile?.statistic?.likes}
                    isProfileBlocked={profile?.status === 0}
                    registrationDate={profile?.created_at}
                    userRole={user?.role_id}
                    userId={user?.id}
                    userBio={user?.bio}
                    isCurrentUser={activeUser === user?.slug}
                    activeSection={section}
                    onSectionChange={handleChangeSection}
                    onComment={toggleCommentModal}
                  />
                  <ProfileStatisticCard
                    likes={profile?.statistic?.likes}
                    dislikes={profile?.statistic?.dislikes}
                    favorites={profile?.statistic?.favorites}
                    onOpenAboutStatusModal={() => toggleStatusModal(true)}
                    statusInfo
                    isCurrentUser={activeUser === user?.slug}
                  />
                </StyledHeader>
                {
                  user?.role_id === ADMIN && section === SUGGESTIONS &&
                  <FilterSuggestedTermsBar
                    activeOption={adminSuggestionActive}
                    onChangeOption={handleChangeAdminActiveSuggestionStatus}
                  />
                }
                {
                  section === MY_TERMS &&
                  <>
                    {
                      profile?.my_approve_terms?.data?.length > 0 &&
                      ((activeUser === user?.slug) || (user?.role_id === ADMIN)) &&
                      profile.my_approve_terms?.data?.map((term, i) => (
                        <TermCard
                          key={i}
                          title={term.title}
                          termId={term.id}
                          slug={term.slug}
                          description={term.description}
                          sample={term?.sample}
                          owner={term.user}
                          ownerId={term?.user_id}
                          ownerLikesCount={term?.user?.likes ?? 0}
                          currentUser={user}
                          isTermOfDay={term?.term_of_day}
                          isFavorite={term?.is_favorite}
                          likeStatus={term?.like_status}
                          lastUpdateDate={term?.created_at}
                          activeSection={section}
                          fullText
                          notAproved
                        />
                      ))
                    }
                    {
                      userTerms
                        ? <>
                          {
                            userTerms?.data?.length > 0
                              ? <>
                                {
                                  userTerms?.data
                                    .map((term, i) => (
                                      <TermCard
                                        key={i}
                                        title={term.title}
                                        termId={term.id}
                                        slug={term.slug}
                                        description={term.description}
                                        sample={term?.sample}
                                        owner={term.user}
                                        ownerId={term?.user_id}
                                        ownerLikesCount={term?.user?.likes ?? 0}
                                        currentUser={user}
                                        isTermOfDay={term?.term_of_day}
                                        isFavorite={term?.is_favorite}
                                        likeStatus={term?.like_status}
                                        lastUpdateDate={term?.created_at}
                                        onEdit={() => toggleAdminTermEdit(term)}
                                        adminView={user?.role_id === ADMIN && section !== SUGGESTIONS} // if it's admin and it isn't suggested terms section
                                        activeSection={section}
                                        onPopupMessage={onPopupMessage}
                                        fullText
                                      />
                                    ))
                                }
                              </>
                              : <>
                                {
                                  (profile?.my_approve_terms?.length === 0 || (activeUser !== user?.login && user?.role_id !== ADMIN))
                                  && <NoTermins />
                                }
                              </>
                          }
                        </>
                        : <Spinner />
                    }
                    {
                      userTerms?.current_page < userTerms?.last_page &&
                      <LoadMore
                        onClick={onLoadMoreUserTerms}
                        loading={loadingMoreUserTerms}
                      />
                    }
                  </>
                }
                {
                  section === ARTICLES &&
                  <>
                    {
                      articles?.length > 0
                        ? <div className="articles-wrapper">
                          {
                            articles?.map((article, i) => (
                              <ArticleCard
                                key={i}
                                id={article?.id}
                                image={article?.image}
                                description={article?.description}
                                link={article?.link}
                                onPopupMessage={onPopupMessage}
                              />
                            ))
                          }
                        </div>
                        : <NoTermins text="Нет статьей" />
                    }
                  </>
                }
                {
                  section === FAVORITES &&
                  <>
                    {
                      user?.favorites?.data?.length > 0
                        ? <>
                          {
                            user?.favorites?.data
                              .map((term, i) => (
                                <TermCard
                                  key={i}
                                  title={term.title}
                                  termId={term.id}
                                  slug={term.slug}
                                  description={term.description}
                                  sample={term?.sample}
                                  owner={term.user}
                                  ownerId={term?.user_id}
                                  ownerLikesCount={term?.user?.likes ?? 0}
                                  currentUser={user}
                                  isTermOfDay={term?.term_of_day}
                                  isFavorite={true}
                                  likeStatus={term?.like_status}
                                  lastUpdateDate={term?.created_at}
                                  onEdit={() => toggleAdminTermEdit(term)}
                                  adminView={user?.role_id === ADMIN && section !== SUGGESTIONS} // if it's admin and it isn't suggested terms section
                                  activeSection={section}
                                  onPopupMessage={onPopupMessage}
                                  fullText
                                />
                              ))
                          }
                        </>
                        : <NoTermins />
                    }
                    {
                      user?.favorites?.current_page < user?.favorites?.last_page &&
                      <LoadMore
                        onClick={onLoadMoreFavorites}
                        loading={loadingMore}
                      />
                    }
                  </>
                }
                {
                  section === SUGGESTIONS &&
                  user?.role_id === MODERATOR &&
                  <>
                    {
                      user?.suggestion?.data?.length > 0
                        ? <>
                          {
                            user?.suggestion?.data
                              .map((term, i) => (
                                <TermCard
                                  key={i}
                                  title={term.title}
                                  termId={term.id}
                                  slug={term.slug}
                                  description={term.description}
                                  sample={term?.sample}
                                  owner={term.user}
                                  ownerId={term?.user_id}
                                  ownerLikesCount={term?.user?.likes ?? 0}
                                  currentUser={user}
                                  isTermOfDay={term?.term_of_day}
                                  isFavorite={true}
                                  likeStatus={term?.like_status}
                                  lastUpdateDate={term?.created_at}
                                  onEdit={() => toggleAdminTermEdit(term)}
                                  adminView={user?.role_id === ADMIN && section !== SUGGESTIONS} // if it's admin and it isn't suggested terms section
                                  activeSection={section}
                                  onPopupMessage={onPopupMessage}
                                  fullText
                                />
                              ))
                          }
                        </>
                        : <NoTermins />
                    }
                    {
                      user?.suggestion?.current_page < user?.suggestion?.last_page &&
                      <LoadMore
                        onClick={onLoadMoreSuggestion}
                        loading={loadingMore}
                      />
                    }
                  </>
                }
                {
                  section === SUGGESTIONS &&
                  user?.role_id === ADMIN &&
                  adminSuggestionActive === APPROVE &&
                  <>
                    {
                      user?.suggestion?.approve?.data?.length > 0
                        ? <>
                          {
                            user?.suggestion?.approve?.data
                              .map((term, i) => (
                                <TermCard
                                  key={i}
                                  title={term.title}
                                  termId={term.id}
                                  slug={term.slug}
                                  description={term.description}
                                  sample={term?.sample}
                                  owner={term.user}
                                  ownerId={term?.user_id}
                                  ownerLikesCount={term?.user?.likes ?? 0}
                                  currentUser={user}
                                  isTermOfDay={term?.term_of_day}
                                  isFavorite={true}
                                  likeStatus={term?.like_status}
                                  lastUpdateDate={term?.created_at}
                                  moderatorsRating={term?.moderators_rating}
                                  verificationStatus={term?.verification_status}
                                  onEdit={() => toggleAdminTermEdit(term)}
                                  adminView={user?.role_id === ADMIN && section !== SUGGESTIONS} // if it's admin and it isn't suggested terms section
                                  activeSection={section}
                                  onPopupMessage={onPopupMessage}
                                  onComment={(data) => toggleCommentModal({ ...data, verification_status: term?.reject_comment })}
                                  fullText
                                />
                              ))
                          }
                        </>
                        : <NoTermins />
                    }
                    {
                      user?.suggestion?.approve?.current_page < user?.suggestion?.approve?.last_page &&
                      <LoadMore
                        onClick={onLoadMoreApproveSuggestion}
                        loading={loadingMore}
                      />
                    }
                  </>
                }
                {
                  section === SUGGESTIONS &&
                  user?.role_id === ADMIN &&
                  adminSuggestionActive === REJECTED &&
                  <>
                    {
                      user?.suggestion?.rejected?.data?.length > 0
                        ? <>
                          {
                            user?.suggestion?.rejected?.data
                              .map((term, i) => (
                                <TermCard
                                  key={i}
                                  title={term.title}
                                  termId={term.id}
                                  slug={term.slug}
                                  description={term.description}
                                  sample={term?.sample}
                                  owner={term.user}
                                  ownerId={term?.user_id}
                                  ownerLikesCount={term?.user?.likes ?? 0}
                                  currentUser={user}
                                  isTermOfDay={term?.term_of_day}
                                  isFavorite={true}
                                  likeStatus={term?.like_status}
                                  lastUpdateDate={term?.created_at}
                                  moderatorsRating={term?.moderators_rating}
                                  verificationStatus={term?.verification_status}
                                  onEdit={() => toggleAdminTermEdit(term)}
                                  adminView={user?.role_id === ADMIN && section !== SUGGESTIONS} // if it's admin and it isn't suggested terms section
                                  activeSection={section}
                                  onPopupMessage={onPopupMessage}
                                  onComment={(data) => toggleCommentModal({ ...data, verification_status: term?.reject_comment })}
                                  fullText
                                />
                              ))
                          }
                        </>
                        : <NoTermins />
                    }
                    {
                      user?.suggestion?.rejected?.current_page < user?.suggestion?.rejected?.last_page &&
                      <LoadMore
                        onClick={onLoadMoreRejectedSuggestion}
                        loading={loadingMore}
                      />
                    }
                  </>
                }
                {
                  section === SUGGESTIONS &&
                  user?.role_id === ADMIN &&
                  adminSuggestionActive === EXPECTED &&
                  <>
                    {
                      user?.suggestion?.expected?.data?.length > 0
                        ? <>
                          {
                            user?.suggestion?.expected?.data
                              .map((term, i) => (
                                <TermCard
                                  key={i}
                                  title={term.title}
                                  termId={term.id}
                                  slug={term.slug}
                                  description={term.description}
                                  sample={term?.sample}
                                  owner={term.user}
                                  ownerId={term?.user_id}
                                  ownerLikesCount={term?.user?.likes ?? 0}
                                  currentUser={user}
                                  isTermOfDay={term?.term_of_day}
                                  isFavorite={true}
                                  likeStatus={term?.like_status}
                                  lastUpdateDate={term?.created_at}
                                  moderatorsRating={term?.moderators_rating}
                                  verificationStatus={term?.verification_status}
                                  onEdit={() => toggleAdminTermEdit(term)}
                                  adminView={user?.role_id === ADMIN && section !== SUGGESTIONS} // if it's admin and it isn't suggested terms section
                                  activeSection={section}
                                  onPopupMessage={onPopupMessage}
                                  onComment={(data) => toggleCommentModal({ ...data, verification_status: term?.reject_comment })}
                                  fullText
                                />
                              ))
                          }
                        </>
                        : <NoTermins />
                    }
                    {
                      user?.suggestion?.expected?.current_page < user?.suggestion?.expected?.last_page &&
                      <LoadMore
                        onClick={onLoadMoreExpectedSuggestion}
                        loading={loadingMore}
                      />
                    }
                  </>
                }
                {
                  section === BAGS &&
                  <>
                    {
                      bags.data?.length > 0
                        ? <>
                          {
                            bags.data
                              .map((bag, i) => (
                                <FeedBackCard
                                  key={i}
                                  login={bag?.login}
                                  userId={bag?.user_id}
                                  text={bag?.bag}
                                  onRemove={() => removeBag(bag.id, onPopupMessage)}
                                />
                              ))
                          }
                        </>
                        : <NoTermins text="Нет багов" />
                    }
                    {
                      bags.current_page < bags.last_page &&
                      <LoadMore
                        onClick={onLoadMoreBags}
                        loading={loadingMoreBags}
                      />
                    }
                  </>
                }
                {
                  section === COMPLAINS &&
                  <>
                    {
                      complains.data?.length > 0
                        ? <>
                          {
                            complains.data
                              .map((complain, i) => (
                                <FeedBackCard
                                  key={i}
                                  login={complain?.login}
                                  userId={complain?.user_id}
                                  text={complain?.complaint}
                                  termUrl={complain?.term_url}
                                  onRemove={() => removeComplaint(complain.id, onPopupMessage)}
                                />
                              ))
                          }
                        </>
                        : <NoTermins text="Нет жалоб" />
                    }
                    {
                      complains.current_page < complains.last_page &&
                      <LoadMore
                        onClick={onLoadMoreComplaints}
                        loading={loadingMoreComplaints}
                      />
                    }
                  </>
                }
                {
                  section === RECOMENDATIONS &&
                  <>
                    {
                      recomendation.data?.length > 0
                        ? <>
                          {
                            recomendation.data
                              .map((recom, i) => (
                                <FeedBackCard
                                  key={i}
                                  login={recom?.login}
                                  userId={recom?.user_id}
                                  text={recom?.recommendation}
                                  onRemove={() => removeRecomendation(recom.id, onPopupMessage)}
                                />
                              ))
                          }
                        </>
                        : <NoTermins text="Нет рекомендаций" />
                    }
                    {
                      recomendation.current_page < recomendation.last_page &&
                      <LoadMore
                        onClick={onLoadMoreRecomendation}
                        loading={loadingMoreRecomendation}
                      />
                    }
                  </>
                }
              </>
              : <Spinner />

          }
          {
            section === MODERATORS &&
            user?.role_id === ADMIN &&
            <ModeratorsTable />
          }
        </StyledContent>
      </div>
    </>
  )
}


const StyledContent = styled.div`
  margin-top: 13px;
  .articles-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 360px;
    gap: 30px;
    width: 90%;
    margin: 0 auto;
  }
  @media (min-width: 480px) {
    margin-top: 23px;
  }
  @media (min-width: 768px) {
    margin-top: 26px;
    .articles-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 0;
      width: 100%;
    }
  }
  @media (min-width: 1000px) {
    margin-top: 32px;
  }
`;

const StyledHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 90%;
  gap: 20px 0px;
  margin: 0 auto 16px;
  .profile-card {
    grid-row: 2;
  }
  @media(min-width: 480px) {
    width: 100%;
  }
  @media(min-width:768px) {
    gap:  0px 20px;
    grid-template-columns: 2fr 1fr; 
    .profile-card {
      grid-row: 1 ;
    }
  }
  @media(min-width: 1000px) {
    grid-template-columns: 578px 266px; 
  }
`;