import styled from "styled-components";
import { Logo } from "../components/Logo/Logo";
import SEO from "../helpers/SEO";

export const TermsOfUse = () => {
  return (
    <>
      <SEO title="Speach – Правила Использования" />
      <StyledTermsOfUse>
        <Logo />
        <StyledTitle>Правила Использования</StyledTitle>
        <p>
          Веб-сайт подходит не для любой аудитории. Его содержание часто представлено грубо и прямо, что некоторым может показаться оскорбительным. Если вы не считаете себя подходящим пользователем или вас обидели, пожалуйста, не заходите на Веб-сайт.
          Веб-сайт не предназначен для детей младше 13 лет. Используя веб-сайт, Вы подтверждаете, что вам исполнилось 18 лет или что вам исполнилось 13 лет и у Вас есть разрешение родителей на использование веб-сайта. Вы не можете использовать веб-сайт для какой-либо незаконной деятельности или для нарушения законов в вашей юрисдикции.<br />
          Вы не можете использовать веб-сайт для доступа к неавторизованной информации. Разработчики оставляют за собой право изменять, приостанавливать или прекращать работу веб-сайта по любой причине, с уведомлением или без него.<br />
          Веб-сайт предоставляется на условиях «как есть» и «как доступно». Вы принимаете на себя полную ответственность и риск за использование веб-сайта. Разработчики не гарантируют, что <br />
          (i) веб-сайт будет соответствовать вашим требованиям,<br />
          (ii) Вы останетесь довольны веб-сайтом,<br />
          (iii) Вы всегда сможете использовать веб-сайт,<br />
          (iv) веб-сайт будет без ошибки,<br />
          (v) или что любые ошибки будут исправлены.<br />
          Разработчики не несут ответственности за любой ущерб или убытки, возникшие в результате использования Вами веб-сайта. Компания не несет ответственности за любые убытки или убытки, возникшие в результате передачи веб-сайтом информации, такой как личные сообщения, по незашифрованным сетям, таким как электронная почта.<br />
          Любая неспособность разработчиков обеспечить соблюдение или реализацию права, предусмотренного настоящими условиями, не является отказом от этого права. Если какое-либо положение настоящих условий обслуживания будет признано недействительным или не имеющим исковой силы, остальные условия будут по-прежнему применяться. Настоящие условия обслуживания представляют собой полное соглашение между вами и разработчиками и заменяют собой все предыдущие письменные или устные соглашения между вами и разработчиками, включая предыдущие версии условий обслуживания. <br />
        </p>
        <span>Поведение пользователей</span>
        <p>
          Пользователи не могут публиковать на веб-сайте какие-либо слова, определения или другую информацию (в совокупности «Контент»), которая: является незаконной, угрожающей, клеветнической или дискредитирующей; нарушает интеллектуальную собственность любой стороны; или же наносит ущерб качеству или заданному духу веб-сайта.<br />
          Примеры неприемлемого контента или поведения на сайте - это те, которые мы, по нашему собственному усмотрению, определяем как:<br />
          оскорбления, домогательства, угрозы насилием, запугивание любого человека или организации или любое другое угрожающее поведение;<br />
          участие или содействие любой незаконной деятельности или деятельности, нарушающей права других; предоставление ложной, вводящей в заблуждение или неточной информации;<br />
          взлом или изменение веб-сайта с целью ложного предположения о связи с разработчиками;<br />
          подразумевая или притворяясь аффилированным лицом с компанией или организацией, с которой вы не связаны, или искажая степень вашей аффилированности или роли в аффилированной компании или организации;<br />
          или же раскрытие личной или служебной информации другого пользователя, лица или организации. Разработчики имеют право, но не обязательство, ограничивать или отзывать права на использование любого, кто публикует неприемлемый контент.<br />
          В любое время разработчики могут по своему усмотрению отказать в разрешении публикации контента или удалить опубликованный контент. Однако разработчики не обязаны каким-либо образом ограничивать или контролировать отправку материалов, а также блокировать пользователей, отправляющих несоответствующий контент.<br />
          Публикуя контент, Вы заявляете и гарантируете, что такой контент:<br />
          не нарушает права третьих лиц, включая, помимо прочего, интеллектуальную собственность и права собственности;<br />
          не является мошенничеством и не связано с поддельной или украденной информацией или предметами;<br />
          не нарушает никаких законов, статутов, постановлений или постановлений;<br />
          а также не создает никакой ответственности для Speach или любого из его соответствующих родителей, дочерних компаний, аффилированных лиц, правопреемников, правопреемников, сотрудников, агентов, директоров, должностных лиц и акционеров.<br />
          Вы не можете отправлять контент с использованием неавторизованных автоматических методов («ботов»). Компания не контролирует и не может контролировать весь контент, публикуемый третьими сторонами на сайте, и не гарантирует точность, целостность или качество такого контента. Вы понимаете, что, используя веб-сайт, вы можете столкнуться с контентом, который вы можете посчитать оскорбительным, неприличным, неправильным или нежелательным, и Вы соглашаетесь с тем, что ни при каких обстоятельствах разработчики не будут нести никакой ответственности за любой контент, включая любые ошибки или упущения в любой контент, а также любые убытки или ущерб любого рода, понесенные в результате использования Вами любого контента.<br />
          Вы несете единоличную ответственность за любой контент, который Вы публикуете на сайте, а также за последствия публикации такого контента. Вы соглашаетесь возмещать, защищать и оградить разработчиков от любых претензий, убытков, затрат, обязательств, убытков, судебных решений, штрафов, процентов и расходов (включая разумные расходы на адвокатов) и оградить их от ответственности. сборов), возникающие или связанные с<br />
          (i) любым фактическим или предполагаемым нарушением ваших заявлений, гарантий или обязательств, изложенных в настоящих Условиях обслуживания, и<br />
          (ii) любым фактическим или предполагаемым нарушением любой интеллектуальной собственности или прав собственности на любой Контент или другую информацию, которую вы публикуете на Сайте.<br />
          Использование веб-сайта также регулируется нашей Политикой конфиденциальности, копия которой в настоящее время находится по адресу http://speachdictionary.com/privacy.<br />
        </p>
        <span>Авторское право и право собственности</span>
        <p>
          Компания владеет правами интеллектуальной собственности на веб-сайт и все его элементы, включая, помимо прочего, дизайн, изображения, функциональные возможности и документацию. Вы не можете копировать, изменять или реконструировать любую часть веб-сайта, принадлежащую разработчикам. В той мере, в какой Вы владеете правами на любой контент, который Вы публикуете на сайте, такие права остаются за вами исключительно и исключительно.<br />
          Когда Вы публикуете контент на веб-сайте, вы предоставляете разработчикам безотзывную, бессрочную, всемирную, бесплатную, полностью сублицензируемую, неисключительную лицензию на копирование, распространение, продажу, публичное отображение, публичное исполнение и создание производных работ из вашего контента на веб-сайт и услуги, связанные с веб-сайтом и в других местах (включая, помимо прочего, печатные, видео, аудио или компьютерные носители), независимо от формы используемых носителей или от того, существуют ли такие носители или услуги в настоящее время или разрабатываются в будущем. Публикуя контент на веб-сайте, Вы тем самым заявляете и гарантируете, что у вас есть все права, необходимые для публикации этого контента и предоставления вышеуказанной лицензии Компании. Компания уважает интеллектуальную собственность других лиц. Он может, при соответствующих обстоятельствах и по своему усмотрению, отключить и / или закрыть учетные записи пользователей, которые неоднократно нарушают права других.<br />
          Если Вы считаете, что ваша работа была скопирована способом, который представляет собой нарушение авторских прав, или Ваши права интеллектуальной собственности были нарушены иным образом, предоставьте следующую информацию агенту компании по авторским правам:<br />
          электронная или физическая подпись лица, уполномоченного действовать от имени владельца авторских прав или другой интеллектуальной собственности;<br />
          описание защищенной авторским правом работы или другой интеллектуальной собственности, права на которую, по Вашему мнению, были нарушены;<br />
          описание того, где на сайте находится материал, который, по Вашему мнению, нарушает авторские права; Ваш адрес, номер телефона и адрес электронной почты;<br />
          Ваше заявление о том, что вы добросовестно полагаете, что спорное использование не разрешено владельцем авторских прав, его агентом или законом; а также Ваше заявление, сделанное под страхом наказания за лжесвидетельство, о том, что приведенная выше информация в Вашем уведомлении является точной и что Вы являетесь владельцем авторских прав или интеллектуальной собственности или уполномочены действовать от имени владельца авторских прав или интеллектуальной собственности.<br />
          Разработчики не просматривают и не могут просматривать весь кконтент, опубликованный на сайте или созданный пользователями, получающими доступ к сайту, и не несут никакой ответственности за содержание любого контента или действия любых таких пользователей.<br />
          Вы признаете, что, предоставляя Вам возможность просматривать и распространять контент, созданный пользователями, через веб-сайт, сайт просто действует как пассивный канал для распространения такой информации и не берет на себя никаких обязательств или обязательств, связанных с контентом или действиями пользователей сайта.<br />
          Веб-сайт и его соответствующие учредители, дочерние компании, аффилированные лица, правопреемники, сотрудники, агенты, директора, должностные лица и акционеры не берут на себя и не принимают на себя никаких обязательств по отслеживанию веб-сайта на предмет ненадлежащего содержания. Компания и ее соответствующие материнские компании, дочерние компании, аффилированные лица, правопреемники, правопреемники, сотрудники, агенты, директора, должностные лица и акционеры не несут ответственности или обязательств, которые могут возникнуть из ее содержимого, включая, помимо прочего, иски о клевете, нарушение, посягательство на частную жизнь и права на гласность, непристойность, порнография, ненормативная лексика, мошенничество или введение в заблуждение. Доступ к Speach API предоставляется только с явного разрешения Speach и регулируется условиями обслуживания Speach API.<br />
        </p>
        <span>Политика в отношении незапрошенных идей</span>
        <p>
          Вы соглашаетесь с тем, что:<br />
          (1) Ваши материалы и их содержание автоматически становятся собственностью Speach без какой-либо компенсации Вам;<br />
          (2) Speach может использовать или распространять материалы и их содержимое для любых целей и любым способом;
          (3) Speach не обязан просматривать заявку;<br />
          (4) нет никаких обязательств по сохранению конфиденциальности представленных материалов.<br />
          Обратная связь и информация: Любые отзывы, которые Вы оставляете на этом сайте, не считаются конфиденциальными. Speach может использовать такую информацию без ограничений.<br />
        </p>
      </StyledTermsOfUse>
    </>
  )
}

const StyledTermsOfUse = styled.div`
    display: flex;
    flex-direction: column;
    padding: 60px 0;
    width: 90%;
    margin: 0 auto;
    p {
        font-weight: 400;
        font-size: 17px;
        line-height: 160%;
        color: #FFFFFF;
        margin-bottom: 60px;
        &:first-child {
            margin-bottom: 50px;
        }
    }
    span {
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 24px;
        line-height: 130%;
        color: #FFFFFF;
        text-align: left;
    }
    @media (min-width: 1400px) {
        width: 1300px;
    }
    div {
        margin: 0 auto;
    }
`;

const StyledTitle = styled.h5`
    margin: 113px 0 35px;
    font-weight: 600;
    font-size: 32px;
    line-height: 130%;
    text-align: center;
    color: #FFFFFF;
`;