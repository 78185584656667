import styled from "styled-components";

export const FilterOption = ({ text, onSelect, active }) => (
    <StyledFilterOption active={active} onClick={onSelect}>
        {text}
    </StyledFilterOption>
)

const StyledFilterOption = styled.div`
    font-weight: 600;
    font-size: 10px;
    line-height: 130%;
    color: #FFFFFF;
    cursor: pointer;
    padding: 10px 14px;
    transition: all .5s;
    border-radius: 36px;
    text-align: center;
    ${props => props.active && `
        background: #F4F7A1;
        border: 1px solid #4D4A48;
        color: #2A2A2A;
    `}
    @media(min-width: 480px) {
        padding: 10px 24px;
        font-size: 12px;
    }
    @media(min-width: 768px) {
        font-size: 14px;
    }
`;