import axios from "axios"
import { baseUrl, headers } from "../../constants/api"
import { useQueryClient } from "react-query";
import { TERMS_TO_DETERMINE } from "../../constants/types";

export const useAddTermToDetermine = () => {
  const client = useQueryClient();

  const addTermToDetermine = ({ title }) => {
    return axios.post(`${baseUrl}/api/moder/term-determine`, null, {
      headers: headers(),
      params: { title }
    })
      .then(resp => {
        if (resp?.status === 200) {
          const createdTerm = resp?.data?.response?.term;
          // update terms to determine
          if (createdTerm) {
            let termsToDetermineData = client.getQueryData(TERMS_TO_DETERMINE);
            termsToDetermineData = [...termsToDetermineData, createdTerm]
            client.setQueryData(TERMS_TO_DETERMINE, termsToDetermineData);
          }
        }
        return resp;
      })
      .catch(error => ({ error: error?.response?.data?.errors?.title[0] }))
  }

  return { addTermToDetermine }
}