import axios from "axios"
import { baseUrl } from "../../constants/api"

export const useForgotPassword = () => {
    const forgotPassword = ({ email }) => {
        return axios.post(`${baseUrl}/api/auth/forgot-password`, null, {
            params: { email }
        })
            .then(resp => resp)
            .catch(error => error.response)
    }

    return { forgotPassword }
}