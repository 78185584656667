import styled from "styled-components";

export const Title = ({ title }) => (
    <StyledTitle>{title}</StyledTitle>
)

const StyledTitle = styled.div`
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
    color: #FFFFFF;
    margin: 20vh  0 24px;
    text-align: center;
    @media (min-width: 360px) {
        font-size: 32px;
    }
`;