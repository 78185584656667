import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { AboutStatus } from "../components/AboutStatus/AboutStatus";
import { CategoryCard } from "../components/CategoryCard/CategoryCard";
import { InfoSteps } from "../components/InfoSteps/InfoSteps";
import { Step5 } from "../components/InfoSteps/Step5";
import { ProfileCard } from "../components/ProfileCard/ProfileCard";
import { ProfileStatisticCard } from "../components/ProfileStatisticCard/ProfileStatisticCard";
import { StatisicsCards } from "../components/StatisicsCards/StatisicsCards";
import { TermCard } from "../components/TermCard/TermCard";
import { TermsToDetermined } from "../components/TermsToDetermined/TermsToDetermined";
import { SUGGESTIONS } from "../constants/profileTermsSection";
import { MODERATOR } from "../constants/roles";
import useProfile from "../hooks/profile/useProfile";
import { useUser } from "../hooks/user/useUser";
import { PagesWrapper } from "./PagesWrapper";
import SEO from "../helpers/SEO";


export const ProfileModeratorSteps = () => {
  const { user } = useUser();
  const { profile } = useProfile();
  const { userId: activeUser } = useParams();
  const navigate = useNavigate();
  const [statusModal, setStatusModal] = useState(false);
  const [infoStep, setInfoStep] = useState(1);

  const toggleStatusModal = (value) => setStatusModal(value);
  const handleCloseInfoSteps = () => navigate(`/profile/${activeUser}`);

  const handleNextStep = () => {
    if (infoStep >= 5) {
      handleCloseInfoSteps();
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      const currentStepElement = document.querySelector(`.info-step-${infoStep}`);
      currentStepElement?.scrollIntoView({ behavior: 'smooth', block: "center" });
      setInfoStep(1 + infoStep);
      currentStepElement?.scrollIntoView({ behavior: 'smooth', block: "center" });
    }
  };

  return (
    <>
      <SEO />
      <PagesWrapper stepInfo>
        <div>
          <InfoSteps onNextInfoStep={handleNextStep} />
          <AboutStatus visible={statusModal} onClose={() => toggleStatusModal(false)} />
          <StatisicsCards />
          <StyledContent className={`container ${infoStep === 4 && 'mobile-container'}`}>
            {
              infoStep === 4
                ? <>
                  <CategoryCard title="English" termsNumber={155} />
                  <CategoryCard title="Sport" termsNumber={101} />
                  <CategoryCard title="English" termsNumber={0} />
                  <CategoryCard title="Music" termsNumber={155} />
                  <CategoryCard title="English" termsNumber={50} />
                  <TermsToDetermined
                    moderator
                    infoStep={infoStep}
                    onNextInfoStep={handleNextStep}
                  />
                </>
                : <>
                  <StyledHeader>
                    <ProfileCard
                      classes="profile-card no-backdrop-filter"
                      editBio
                      footer
                      infoStep={infoStep}
                      onNextInfoStep={handleNextStep}
                      profileName={profile?.login}
                      profileRole={MODERATOR}
                      profileBio={profile?.bio}
                      profileId={profile?.id}
                      profileLikes={profile?.statistic?.likes}
                      isProfileBlocked={profile?.status === 0}
                      registrationDate={profile?.created_at}
                      userRole={user?.role_id}
                      userId={user?.id}
                      userBio={user?.bio}
                      isCurrentUser={true}
                      activeSection={SUGGESTIONS}
                    />
                    <ProfileStatisticCard
                      likes={profile?.statistic?.likes}
                      dislikes={profile?.statistic?.dislikes}
                      favorites={profile?.statistic?.favorites}
                      statusInfo
                      isCurrentUser={true}
                    />
                  </StyledHeader>
                  <TermCard
                    title="Speach"
                    termId="1"
                    description="Словарь, который создаете вы. Русскоязычный интернет кишит всевозможным сленгом и англицизмами, но узнать значение большинства из них можно только лишь получив годовую дозу облучения кринжом на slang.su, либо задав вопрос на мэил ру пока через 3 месяца какая-нибудь *арМатУРа1307•.♥.ツ не даст плоский ответ в надежде повысить свой ранг до уровня Оракул. Целью Speach является объединение людей, которые хотят поделиться своими лингвистическими знаниями с миром и при этом не прибегать к крайним мерам, которые я упомянул выше. Если вы хотите придать ясности плохо известным словам, по-новому объяснить хорошо известные либо просто выдавить пару ха-ха с читателя – желтая кнопочка справа вверху 😇"
                    currentUser={{ id: '23232', role_id: MODERATOR }}
                    fullText
                    infoStep={infoStep}
                    lastUpdateDate="2022-06-20T15:05:10.000000Z"
                    activeSection={SUGGESTIONS}
                  />
                  <TermCard
                    title="Speach"
                    termId="1"
                    description="Словарь, который создаете вы. Русскоязычный интернет кишит всевозможным сленгом и англицизмами, но узнать значение большинства из них можно только лишь получив годовую дозу облучения кринжом на slang.su, либо задав вопрос на мэил ру пока через 3 месяца какая-нибудь *арМатУРа1307•.♥.ツ не даст плоский ответ в надежде повысить свой ранг до уровня Оракул. Целью Speach является объединение людей, которые хотят поделиться своими лингвистическими знаниями с миром и при этом не прибегать к крайним мерам, которые я упомянул выше. Если вы хотите придать ясности плохо известным словам, по-новому объяснить хорошо известные либо просто выдавить пару ха-ха с читателя – желтая кнопочка справа вверху 😇"
                    currentUser={{ id: '23232', role_id: MODERATOR }}
                    fullText
                    lastUpdateDate="2022-06-20T15:05:10.000000Z"
                    activeSection={SUGGESTIONS}
                  />
                  <TermCard
                    title="Speach"
                    termId="1"
                    description="Словарь, который создаете вы. Русскоязычный интернет кишит всевозможным сленгом и англицизмами, но узнать значение большинства из них можно только лишь получив годовую дозу облучения кринжом на slang.su, либо задав вопрос на мэил ру пока через 3 месяца какая-нибудь *арМатУРа1307•.♥.ツ не даст плоский ответ в надежде повысить свой ранг до уровня Оракул. Целью Speach является объединение людей, которые хотят поделиться своими лингвистическими знаниями с миром и при этом не прибегать к крайним мерам, которые я упомянул выше. Если вы хотите придать ясности плохо известным словам, по-новому объяснить хорошо известные либо просто выдавить пару ха-ха с читателя – желтая кнопочка справа вверху 😇"
                    currentUser={{ id: '23232', role_id: MODERATOR }}
                    fullText
                    lastUpdateDate="2022-06-20T15:05:10.000000Z"
                    activeSection={SUGGESTIONS}
                  />
                  <TermCard
                    title="Speach"
                    termId="1"
                    description="Словарь, который создаете вы. Русскоязычный интернет кишит всевозможным сленгом и англицизмами, но узнать значение большинства из них можно только лишь получив годовую дозу облучения кринжом на slang.su, либо задав вопрос на мэил ру пока через 3 месяца какая-нибудь *арМатУРа1307•.♥.ツ не даст плоский ответ в надежде повысить свой ранг до уровня Оракул. Целью Speach является объединение людей, которые хотят поделиться своими лингвистическими знаниями с миром и при этом не прибегать к крайним мерам, которые я упомянул выше. Если вы хотите придать ясности плохо известным словам, по-новому объяснить хорошо известные либо просто выдавить пару ха-ха с читателя – желтая кнопочка справа вверху 😇"
                    currentUser={{ id: '23232', role_id: MODERATOR }}
                    fullText
                    lastUpdateDate="2022-06-20T15:05:10.000000Z"
                    activeSection={SUGGESTIONS}
                  />
                </>

            }
            <Step5 visible={infoStep === 5} onClose={handleCloseInfoSteps} />
          </StyledContent>
        </div>
      </PagesWrapper>
    </>
  )
}


const StyledContent = styled.div`
    margin-top: 13px;
    @media (min-width: 480px) {
        margin-top: 23px;
    }
    @media (min-width: 768px) {
       margin-top: 26px;
    }
    @media (min-width: 1000px) {
       margin-top: 32px;
    }
`;

const StyledHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    width: 90%;
    gap: 20px 0px;
    margin: 0 auto 16px;
    position: relative;
    .profile-card {
        grid-row: 2;
    }
    @media(min-width: 480px) {
        width: 100%;
    }
    @media(min-width:768px) {
        gap:  0px 20px;
        grid-template-columns: 2fr 1fr; 
        .profile-card {
            grid-row: 1 ;
        }
    }
    @media(min-width: 1000px) {
        grid-template-columns: 578px 266px; 
    }
`;