import { NavLink } from "react-router-dom";
import styled from "styled-components";
import loginIcon from '../../assets/icons/logo-hq.png';


export const Logo = ({ classes, noLink }) => {

  return (
    <StyledLogo className={classes}>
      {
        noLink
          ? <>
            <img src={loginIcon} alt="" />
            <span>peach</span>
          </>
          : <NavLink to={"/"}>
            <img src={loginIcon} alt="" />
            <span>peach</span>
          </NavLink>
      }
    </StyledLogo >
  )
}

const StyledLogo = styled.div`
    display: flex;
    align-items: center;
    a {
        display: flex;
        align-items: center;
    }
    img {
        height: 57px !important;
        image-rendering: -webkit-optimize-contrast;
    }
    span {
        margin-left: 5px !important;
        font-family: 'Montserrat' !important;
        font-weight: 700 !important;
        font-size: 40px !important;
        line-height: 37px !important;
        color: #F4F7A1 !important;
        transition: all .3s;
    }
`;
