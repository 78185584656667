import styled from "styled-components";
import { getUserColor } from "../../helpers/user";
import { RegistrationDate } from "./RegistrationDate";

export const Info = ({ profileName, profileLikes, registrationDate, profileRole }) => (
    <div>
        {
            registrationDate && profileName &&
            <>
                <StyledName color={getUserColor(profileRole, Number(profileLikes))}>
                    {profileName}
                </StyledName>
                <RegistrationDate registrationDate={registrationDate} />
            </>
        }
    </div>
)

const StyledName = styled.div`
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: ${props => props.color};
    margin-bottom: 2px;
    @media(min-width: 480px) {
        font-size: 32px;
    }
`;