import { useEffect } from "react";
import styled from "styled-components";
import { Modal } from "./Modal";

export const DownloadTermCard = ({
  onClose,
  termId,
  title,
  description,
  sample,
  owner,
  ownerLikesCount,
  onPopupMessage,
  termSlug
}) => {

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = "hidden";
  }, [])

  return (
    <StyledDownloadTermCard>
      <Modal
        onClose={onClose}
        termId={termId}
        termSlug={termSlug}
        title={title}
        description={description}
        sample={sample}
        owner={owner}
        ownerLikesCount={ownerLikesCount}
        onPopupMessage={onPopupMessage}
      />
    </StyledDownloadTermCard>
  )
}

const StyledDownloadTermCard = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(35, 35, 35, 0.94);
  z-index: 10;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
`;