import styled from "styled-components";


export const Description = () => (
    <StyledDescription>
        Цвет никнейма aka статус возрастает с одобрением других пользователей. Лайки суммируются со всех постов и окрашивают ник следющим образом:
    </StyledDescription>
)

const StyledDescription = styled.div`
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 130%;
    color: #D5D2D0;
    margin-bottom: 10px;
    @media(min-width: 480) {
        margin-bottom: 13px;
    }
    @media(min-width: 768px ) {
        margin-bottom: 18px;
    }
`;