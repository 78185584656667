import styled from "styled-components";

export const SuccessMessage = () => (
    <StyledSuccessMessage>
        Вы успешно прошли процесс смены пароля своего аккаунта ✅
    </StyledSuccessMessage>
)

const StyledSuccessMessage = styled.div`
    font-weight: 400;
    font-size: 17px;
    line-height: 140%;
    text-align: center;
    color: #818181;
`;