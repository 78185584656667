import styled from "styled-components";
import { Input } from "../Input/Input";
import { Button } from "./Button";

export const LinkInput = ({
    value,
    visible,
    onChange,
    disabled,
    onCreateLink
}) => (
    <StyledLinkInput visible={visible}>
        <Input
            label="Гиперссылка"
            classes={!disabled && "input"}
            value={value}
            onValueChange={onChange}
        />
        <Button
            disabled={disabled}
            onCreateLink={onCreateLink}
        />
    </StyledLinkInput>
)

const StyledLinkInput = styled.div`
    display: flex;
    align-items: flex-end;
    margin-bottom: 16px;
    transition: all .5s;
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    opacity: ${props => props.visible ? '1' : '0'};
    height:  ${props => props.visible ? '77px' : '0px'};
    overflow: hidden;
    .input {
        input {
            transition: all .5s;
            border-radius:  10px 0 0 10px !important; 
        }
    }
`;