import styled from "styled-components";

export const DailyTerm = () => (
    <StyledDailyTerm>
        Термин дня
    </StyledDailyTerm>
)

const StyledDailyTerm = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 15px;
    min-height: 29px;
    border: 1px solid #F28569;
    border-radius: 60px;
    margin: 0 5px 0 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #FFFFFF;
    text-align: center;
    @media(min-width: 468px) {
        font-size: 15px;
    }
`;