import styled from "styled-components";

export const Title = () => (
    <StyledTitle>
       Если ваш электронный адрес был случайно использован для создания аккаунта на Speach, вы можете стереть свои данные, нажав на кнопку внизу. Ну либо же завершить регистрацию, сменить пароль и присоединиться к сотням людей, ежедневно проливающих свет на малоизвестные термины и понятия 😊
    </StyledTitle>
)

const StyledTitle = styled.div`
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 21px;
    text-align: center;
    word-break: break-word;
`;