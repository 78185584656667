import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { LoadMore } from "../components/LoadMore/LoadMore";
import { Spinner } from "../components/Spinner/Spinner";
import { TermCard } from "../components/TermCard/TermCard";
import useCategories from "../hooks/categories/useCategories";
import useCategoryTerms from "../hooks/term/useCategoryTerms";
import useGetTermById from "../hooks/term/useGetTermById";
import { useUser } from "../hooks/user/useUser";
import { ADMIN } from "../constants/roles";
import { EditTerm } from "../components/EditTerm/EditTerm";
import { useSearchTerms } from "../hooks/search/useSearchTerms";
import { useArticles } from "../hooks/article/useArticles";
import { ArticleCard } from "../components/ArticleCard/ArticleCard";
import SEO from "../helpers/SEO";

export const Termin = ({ onPopupMessage }) => {
  const { user } = useUser();
  const { term } = useGetTermById();
  const { searchTerms } = useSearchTerms();
  const { categories } = useCategories();
  const { articles } = useArticles();
  const [viewWidth, setViewWidth] = useState(null);
  const {
    categoryTerms,
    getCategoriesTerms,
    onLoadMoreCategoryTerms,
    loadingMoreCategoryTerms,
  } = useCategoryTerms();
  // similar terms
  const [similarTerms, setSimilarTerms] = useState([]);
  // edit term by admin modal toggle
  const [editTerm, setEditTerm] = useState(false);
  const [scrollOffSet, setScrollOffSet] = useState(0);
  const articlesRef = useRef();
  const handleEditTerm = (value) => setEditTerm(value);

  // geting term categoty title
  const handleGetActiveCategory = (id) => {
    const activeCategoty = categories.find(c => c.id === id);
    return activeCategoty?.title ?? ''
  }

  // get similar terms
  const handleGetSimilarTerms = async (title) => {
    const resp = await searchTerms(title);
    if (resp?.length > 0) {
      setSimilarTerms(resp)
    } else {
      setSimilarTerms([])
    }
  }

  useEffect(() => {
    term?.category_id && getCategoriesTerms(categories.find(c => c.id === term?.category_id)?.slug);
    term?.title && handleGetSimilarTerms(term?.title)
    // eslint-disable-next-line
  }, [term])

  useEffect(() => {
    window.addEventListener('resize', () => setViewWidth(window.innerWidth), false);
    return () => window.addEventListener('resize', () => setViewWidth(window.innerWidth), false)
  }, [])

  const handleCheckScrollOffSet = () => {
    const topOffset = window.pageYOffset || document.documentElement.scrollTop;
    setScrollOffSet(topOffset);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleCheckScrollOffSet, false);
    return () => window.addEventListener('scroll', handleCheckScrollOffSet, false);
  }, [])

  return (
    <>
      <SEO
        title={`${term?.title} — Speach `}
        description={`Определение слова “${term?.title}”. Что это значит на сленге “${term?.title}”. Объяснение терминов, англицизмов и сленга. Словарь сленга - Speach.uk`}
        name={term?.user?.name}
        type="website"
        url={window.location.href}
        twitterUrl={window.location.href}
        ogUrl={window.location.href}
      />
      <div>
        <EditTerm
          visible={editTerm}
          onClose={() => handleEditTerm(false)}
          onPopupMessage={onPopupMessage}
        />
        {
          !term
            ? <Spinner />
            : <StyledContent className="container" >
              <StyledTermCards>
                <TermCard
                  title={term.title}
                  termId={term.id}
                  slug={term.slug}
                  description={term.description}
                  sample={term?.sample}
                  owner={term.user}
                  ownerId={term?.user_id}
                  ownerLikesCount={term?.user?.likes ?? 0}
                  currentUser={user}
                  onEdit={() => handleEditTerm(term)}
                  isTermOfDay={term?.term_of_day}
                  isFavorite={term?.is_favorite}
                  likeStatus={term?.like_status}
                  lastUpdateDate={term?.created_at}
                  adminView={user?.role_id === ADMIN}
                  fullText
                  onPopupMessage={onPopupMessage}
                  mainCard
                />
                <StyledArticlesInTerms>
                  {
                    articles.length > 0 &&
                    articles
                      ?.slice(0, viewWidth > 768 ? 2 : 1)
                      ?.map((article, i) => (
                        <ArticleCard
                          key={i}
                          id={article?.id}
                          image={article?.image_thumbnails?.x400 || article?.image}
                          description={article?.description}
                          link={article?.link}
                          onPopupMessage={onPopupMessage}
                          noClose
                        />
                      ))
                  }
                </StyledArticlesInTerms>
                {
                  similarTerms?.length > 0 &&
                  similarTerms
                    .filter(t => t.id !== term?.id)
                    .map((t, i) => (
                      <TermCard
                        key={i}
                        title={t.title}
                        termId={t.id}
                        slug={term.slug}
                        description={t.description}
                        sample={t?.sample}
                        owner={t.user}
                        ownerId={t?.user_id}
                        ownerLikesCount={t?.user?.likes ?? 0}
                        currentUser={user}
                        isTermOfDay={t?.term_of_day}
                        likeStatus={t?.like_status}
                        isFavorite={t?.is_favorite}
                        onEdit={() => handleEditTerm(t)}
                        lastUpdateDate={t?.created_at}
                        fullText
                        adminView={user?.role_id === ADMIN}
                        onPopupMessage={onPopupMessage}
                        onRefreshData={() => handleGetSimilarTerms(term?.title)}
                      />
                    ))
                }
                {
                  term?.category_id && categories?.length > 0 &&
                  <>
                    <StyledDivider>
                      Также посты из категории
                      <span> {handleGetActiveCategory(term?.category_id)}</span> :
                    </StyledDivider>
                    {
                      categoryTerms?.data?.length > 0 &&
                      categoryTerms.data.map((t, i) => {
                        if (term.id === t.id) {
                          return null
                        }
                        return (
                          <TermCard
                            key={i}
                            title={t.title}
                            termId={t.id}
                            slug={t.slug}
                            description={t.description}
                            sample={t?.sample}
                            owner={t.user}
                            ownerId={t?.user_id}
                            ownerLikesCount={t?.user?.likes ?? 0}
                            currentUser={user}
                            isTermOfDay={t?.term_of_day}
                            likeStatus={t?.like_status}
                            isFavorite={t?.is_favorite}
                            onEdit={() => handleEditTerm(t)}
                            lastUpdateDate={t?.created_at}
                            fullText
                            adminView={user?.role_id === ADMIN}
                            onPopupMessage={onPopupMessage}
                          />
                        )
                      })
                    }
                    {
                      categoryTerms?.current_page < categoryTerms?.last_page &&
                      <LoadMore
                        onClick={() => onLoadMoreCategoryTerms(categories.find(c => c.id === term?.category_id)?.slug)}
                        loading={loadingMoreCategoryTerms}
                      />
                    }
                  </>
                }
              </StyledTermCards>
              <StyledArticles
                scrollOffSet={scrollOffSet}
                ref={articlesRef}
                wrapperWidth={articlesRef.current?.clientWidth}
              >
                {
                  articles.length > 0 ?
                    articles
                      ?.slice(0, 2)
                      ?.map((article, i) => (
                        <ArticleCard
                          key={i}
                          id={article?.id}
                          image={article?.image_thumbnails?.x400 || article?.image}
                          description={article?.description}
                          link={article?.link}
                          onPopupMessage={onPopupMessage}
                          noClose
                          styles={i === 1 && "second-article"}
                        />
                      ))
                    : null
                }
              </StyledArticles>
            </StyledContent>
        }
      </div>
    </>
  )
}


const StyledContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  @media(min-width: 1200px) {
    grid-template-columns: 1fr 30%;
  }
`;

const StyledTermCards = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 13px;
    @media (min-width: 480px) {
        margin-top: 23px;
    }
    @media (min-width: 768px) {
       margin-top: 26px;
    }
    @media (min-width: 1000px) {
       margin-top: 32px;
    }
`;

const StyledDivider = styled.div`
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: #FFFFFF;
    margin: 40px 20px 24px;
    text-align: center;
    span {
        color: #F4F7A1;
    }
    @media(min-width: 480px) {
        font-size: 32px;
        margin: 40px auto 24px 0;
    }
`;


const StyledArticles = styled.div`
  display: none;
  grid-auto-rows: 250px;
  margin-top: 32px;
  gap: 16px;
  min-height: 800px;
  overflow: hidden;
  @media(max-width: 1300px) {
    ${props => props.scrollOffSet > 350 && `
      .second-article {
        transition: all .3s;
        position: fixed;
        top: 160px;
        height: 250px;
        width: ${props.wrapperWidth}px;
      }
    `}
  }
  @media(min-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr;
  }
  @media(min-width: 1300px) {
    grid-auto-rows: 360px;
    ${props => props.scrollOffSet > 470 && `
    .second-article {
      position: fixed;
      top: 160px;
      height: 360px;
      width: ${props.wrapperWidth}px;
    }
  `}
  }
`;

const StyledArticlesInTerms = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 280px;
  gap: 8px;
  width: 100%;
  @media(min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media(min-width: 1200px) {
    display: none;
  }
`;
