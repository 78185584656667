import styled from "styled-components";

export const SelectedOption = ({ value, isActive }) => (
    <>
        {
            value
                ? <StyledSelected>
                    {value}
                </StyledSelected>
                : <StyledDefault>
                    {!isActive && 'Необязательно'}
                </StyledDefault>
        }
    </>
)

const StyledDefault = styled.div`
    font-weight: 400;
    font-size: 17px;
    line-height: 130%;
    color: rgba(255, 255, 255, 0.16);
`;

const StyledSelected = styled.div`
    font-weight: 400;
    font-size: 17px;
    line-height: 130%;
    color: #FFFFFF;
`;