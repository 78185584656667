import styled from "styled-components";
import closeIcon from '../../assets/icons/closeGold.svg';

export const FeedBackCard = ({ login, userId, username, text, termUrl, onRemove }) => (
  <StyledFeedBackCard>
    <span>
      {text}
    </span>
    <div>
      <a href={` https://t.me/${login}`} target="_blank" rel="noreferrer"> Телеграм </a>
      <a href={`/#/profile/${userId}`} target="_blank" rel="noreferrer"> Профиль </a>
      {
        termUrl &&
        <a href={termUrl} target="_blank" rel="noreferrer"> Термин </a>
      }
      <img src={closeIcon} alt="" onClick={onRemove} />
    </div>
  </StyledFeedBackCard>
)

const StyledFeedBackCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(153px);
  border-radius: 10px;
  min-height: 40px;
  padding: 30px 24px;
  margin-bottom: 20px;
  transition: all .5s;
  span {
    color: #F4F7A1;
    width: 60%;
    word-break: break-word;
  }
  a {
    color: #D5D2D0;
    padding: 0 10px;
    transition: all .3s;
    &:hover {
      color: #F4F7A1;
    }
  }
  img {
    cursor: pointer;
  }
`;