import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Button } from "./Button";
import { Menu } from "./Menu";

export const SideBar = ({ onAuthCheck }) => {
  const location = useLocation();
  const [toggle, setToggle] = useState(false);
  const handleToggleSideBar = () => setToggle(!toggle);

  // close sidebar on change page
  useEffect(() => {
    setToggle(false);
  }, [location])

  return (
    <StyledSideBar>
      <Button onToggleSideBar={handleToggleSideBar} />
      <Menu
        visible={toggle}
        onToggleSideBar={handleToggleSideBar}
        onAuthCheck={onAuthCheck}
      />
    </StyledSideBar>
  )
}

const StyledSideBar = styled.div`
    display: block;
    margin-left: auto;
    position: relative;
    @media (min-width: 870px) {
        display: none;
    }
`;